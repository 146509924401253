import { Button, Card, Loading, useResizeObserver } from 'axil-web-ui';
import { Widget } from 'daydash-data-structures';
import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react';

import { Link } from '@tanstack/react-router';
import { EventEmitterProvider } from '@visx/xychart';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { ChevronLeftIcon } from 'lucide-react';
import { isStandaloneAtom, widgetAtoms } from 'src/atoms';
import { Dashboard } from 'src/types/entities';
import DashboardContext from '../Dashboard/context';
import NotFoundPage from '../pages/NotFoundPage';
import typeToWidgetMap from './WidgetTypes/config';
import { ViewWidgetProps } from './types';

export interface WidgetDetailsProps {
  dashboard: Dashboard;
  widget: Widget;
}

/**
 * This should probably just be created in Jotai when on a deal page
 * or a widget details page and derived there. However, its complicated because you
 * often need to interact with the actual widget UI to do this. It also can make it easier
 * for developers to only have to worry about making edits in once spot and having everything
 * just work.
 *
 * I need to think this through a bit more but for now, this should work fine
 */
function useCustomMenuItems() {
  const [customMenuItems, setCustomMenuItems] = useAtom(widgetAtoms.widgetDetailsCustomMenuItems);
  useEffect(() => {
    // Clean up when leaving
    return () => {
      setCustomMenuItems(null);
    };
  }, []);
  return [customMenuItems, setCustomMenuItems] as const;
}
/** 
 TODO:
 * -  Move WidgetActions to its own component and use it here
 */
export default function WidgetDetails({ dashboard, widget }: WidgetDetailsProps) {
  const upsert = useSetAtom(widgetAtoms.upsert);
  const deleteWidget = useSetAtom(widgetAtoms.delete);
  const widgetViewContainerRef = useRef<HTMLDivElement>(null);
  const containerRect = useResizeObserver(widgetViewContainerRef);
  const editWidget = useSetAtom(widgetAtoms.startEditingWidget);
  const [customMenuItems, setCustomMenuItems] = useCustomMenuItems();
  const isStandalone = useAtomValue(isStandaloneAtom);

  const handleSave = useCallback(
    (newWidget: Widget) => {
      return upsert(newWidget, dashboard.id);
    },
    [dashboard.id]
  );
  if (!widget) return <NotFoundPage />;
  const WidgetViewComponent = typeToWidgetMap[widget.type]?.View as React.ComponentType<
    ViewWidgetProps<Widget>
  >;
  const title = widget.type !== 'Note' && widget.type !== 'SingleValue' ? widget.title : null;

  return (
    <div className="bg-base-300 flex h-full w-full flex-col gap-4 p-4">
      <div className="flex flex-col items-start justify-between gap-4 sm:flex-row sm:items-center">
        <div className="flex min-w-0 max-w-full items-center justify-between gap-4 self-stretch sm:grow">
          <Link
            to={isStandalone ? '/dash/$id/full' : '/dash/$id'}
            params={{ id: dashboard.id }}
            className="flex shrink-0 items-center gap-2">
            <ChevronLeftIcon />
            <div>Go to Dashboard</div>
          </Link>
          {title ? (
            <h2 className="min-w-0 shrink grow overflow-hidden text-ellipsis whitespace-nowrap text-center text-xl sm:text-3xl">
              {title}
            </h2>
          ) : null}
        </div>

        <div className="flex shrink-0 flex-wrap justify-center gap-2">
          <Button
            color="primary"
            className="w-16"
            onClick={() => editWidget(widget.id, dashboard.id)}>
            Edit
          </Button>
          <Button className="w-16" onClick={() => deleteWidget(widget.id, dashboard.id)} outline>
            Delete
          </Button>
          <>
            {customMenuItems?.map(item => {
              return (
                <Button onClick={item.onClick} disabled={item.disabled} outline key={item.label}>
                  {item.icon}
                  {item.label}
                </Button>
              );
            })}
          </>
        </div>
      </div>
      <Card
        className="bg-base-200 h-full min-h-0 w-full"
        body={
          <div ref={widgetViewContainerRef} className="h-full max-h-full min-h-0 shrink grow">
            <DashboardContext.Provider
              value={{
                containerRect,
                inspectingWidget: true,
                isEditing: true,
                rowHeight: containerRect?.height ?? 320,
                gridGap: 0,
                containerPadding: 0,
                colCount: 1
              }}>
              <EventEmitterProvider>
                <Suspense
                  fallback={
                    <div className="h-full w-full items-centers flex flex-row justify-center p-4">
                      <Loading size={48} color="primary"></Loading>
                    </div>
                  }>
                  {containerRect ? (
                    <WidgetViewComponent
                      widget={widget}
                      onSetCustomMenuItems={setCustomMenuItems}
                      onSave={handleSave}
                      containerRect={containerRect}
                    />
                  ) : null}
                </Suspense>
              </EventEmitterProvider>
            </DashboardContext.Provider>
          </div>
        }
      />
    </div>
  );
}
