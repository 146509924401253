import { QueryOptions, UseQueryOptions, UseSuspenseQueryOptions } from '@tanstack/react-query';
import {
  AbstractDataField,
  AbstractDataResponse,
  BaseLayerConfig,
  LayerConfig,
  UnitPreferences
} from 'daydash-data-structures';
import { z } from 'zod';
import { Dashboard, DataSource, DataSourceSection, UserFile } from '../types/entities';
import { DataFetchOrder } from './dataFetching';

type DBFetchCalls = {
  getDashboard: (dashboardId: string) => Promise<Dashboard | null>;
  getAllDashboards: () => Promise<Dashboard[]>;
  getDataSource: (dataSourceId: string) => Promise<DataSource | null>;
  getAllDataSources: () => Promise<DataSource[]>;
  getUserFile: (id: string) => Promise<UserFile | null>;
  getDataSourceSection: (sectionId: string) => Promise<DataSourceSection | null>;
  getDataSourceSectionsForDataSource: (dataSourceId: string) => Promise<DataSourceSection[]>;
  getInitialSectionFields: (sectionId: string) => Promise<AbstractDataField[]>;
  getCategoryValues: (sectionId: string, fieldName: string) => Promise<string[]>;
  queryDataPoints: (params: {
    layerConfig: [BaseLayerConfig, ...LayerConfig[]];
    unitPreferences: UnitPreferences;
    pageIndex?: number;
    pageSize?: number;
    defaultDir?: 'ASC' | 'DESC';
    selected: string[] | 'all'; // Final selection of data points
    order?: DataFetchOrder[] | null;
  }) => Promise<AbstractDataResponse & { total: number }>;
};

type DBFetchCall = keyof DBFetchCalls;

export type DBQueries = {
  [C in DBFetchCall]: (...params: Parameters<DBFetchCalls[C]>) => ReturnType<DBFetchCalls[C]>;
};

export type CoreRPCCalls = {
  query: <C extends DBFetchCall>(
    dbCall: C,
    params: Parameters<DBFetchCalls[C]>
  ) => Promise<ReturnType<DBFetchCalls[C]>>;
  getCapabilities: () => Capability[];
  openStandaloneDashboard: (id: string) => void;
  destroy: () => Promise<void>;
};

export type CoreClientRPCCalls = {
  getAuthToken: () => Promise<string | null>;
};

export type Capability = 'open-standalone-dashboard';

export const syncStateSchema = z.object({
  connected: z.boolean(),
  dataSourceSectionShapesInitialized: z.boolean(),
  dataPointShapesInitialized: z.boolean(),
  shapes: z.array(
    z.object({
      name: z.string(),
      syncing: z.boolean(),
      initialized: z.boolean(),
      shapeHandle: z.string().nullable(),
      lastOffset: z.string().nullable(),
      isUpToDate: z.boolean()
    })
  )
});

export type SyncState = z.infer<typeof syncStateSchema>;

export const getLocalDBName = (userId: string) => `local-db-${userId}`;

export const SYNC_STATE_BROADCAST_CHANNEL = 'sync-state';
export const SYNC_STATE_REQUEST = 'sync-state-request';
