import { Link } from '@tanstack/react-router';
import { useAtomValue } from 'jotai';
import { XIcon } from 'lucide-react';
import React, { useEffect } from 'react';
import { onboardingAtoms } from 'src/atoms';
import { useStorageBoolean } from 'src/hooks';

export default function OnboardingIndicator() {
  const isFullyOnboarded = useAtomValue(onboardingAtoms.isFullyOnboarded);
  const percentComplete = useAtomValue(onboardingAtoms.percentComplete);
  const [showOnboardingIndicator, setShowOnboardingIndicator] = useStorageBoolean(
    'sidebar:showOnboardingIndicator',
    false
  );
  // If you discover that the user is not fully onboarded, go ahead and make sure we the show onboarding indicator
  useEffect(() => {
    if (isFullyOnboarded === false) setShowOnboardingIndicator(true);
  }, [isFullyOnboarded]);

  if (!showOnboardingIndicator || percentComplete == null) return null;
  return (
    <div className="relative px-8 py-4">
      <Link to="/onboarding" className="mb-2 block w-full text-center">
        Account Setup Progress
      </Link>
      <div className="h-4 w-full overflow-hidden rounded border">
        <div
          style={{ transform: `scaleX(${percentComplete})` }}
          className="bg-primary h-full w-full origin-left transition-transform"></div>
      </div>
      {isFullyOnboarded ? (
        <button
          className="text-base-content absolute right-1 top-1"
          onClick={() => setShowOnboardingIndicator(false)}>
          <XIcon size="14px" />
        </button>
      ) : null}
    </div>
  );
}
