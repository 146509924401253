import axios, { CanceledError } from 'axios';
import pino from 'pino';
// See https://github.com/pinojs/pino/blob/master/docs/browser.md for more options

const log = pino({ browser: {} });

axios.interceptors.request.use(
  req => {
    log.debug(
      {
        response: {
          url: req.url,
          method: req.method
        }
      },
      'Axios Request'
    );
    return Promise.resolve(req);
  },
  err => {
    if (err instanceof CanceledError) {
      log.warn({ err }, 'Axios request cancelled');
      return;
    }
    log.error({ err }, 'Axios Request Error');
    return Promise.reject(err);
  }
);
axios.interceptors.response.use(
  res => {
    log.debug(
      {
        response: {
          url: res.config.url,
          method: res.config.method,
          status: res.status,
          statusText: res.statusText
        }
      },
      'Axios Response'
    );
    return Promise.resolve(res);
  },
  err => {
    log.error(
      {
        response: {
          status: err.response?.status,
          statusText: err.response?.statusText,
          data: err.response?.data
        },
        err
      },
      'Axios Response Error'
    );
    return Promise.reject(err);
  }
);

export default log;
