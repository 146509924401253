import { RegisteredRouter, RouteIds } from '@tanstack/react-router';
import { isNotEmpty } from 'axil-utils';
import { atom } from 'jotai';
import { CustomMenuItem } from 'src/components/Widget/types';
import { _storeAtom, promptAtom, routeMatchesAtom, routingAtom, toastAtom } from '.';
import type { CommandGroup } from './commands.atoms';
import type { DashboardAtoms } from './dashboards.atoms';
import { Widget } from 'daydash-data-structures';

export class WidgetAtoms {
  constructor(private dashboardAtoms: DashboardAtoms) {}

  upsert = atom(null, async (get, set, newWidget: Widget, dashboardId: string) => {
    const current = get(this.dashboardAtoms.dashboardFamily(dashboardId));
    if (!current) throw new Error('Dashboard not found');
    const newWidgets = current?.widgets.find(w => w.id === newWidget.id)
      ? current.widgets.map(w => (w.id === newWidget.id ? newWidget : w))
      : [...(current.widgets ?? []), newWidget];
    await set(this.dashboardAtoms.updateDashboard, {
      ...current,
      widgets: newWidgets
    });
  });

  private expandedWidgetIdRoute: RouteIds<RegisteredRouter['routeTree']> =
    '/authed/dash/$dashboardId/$widgetId';

  private expandedWidgetMatch = atom(get => {
    const matches = get(routeMatchesAtom);
    return matches?.find(match => match.routeId === this.expandedWidgetIdRoute) ?? null;
  });
  expandedWidgetDashboardId = atom<string | null>(get => {
    return get(this.expandedWidgetMatch)?.params.dashboardId ?? null;
  });

  expandedWidgetId = atom<string | null>(get => {
    return get(this.expandedWidgetMatch)?.params.widgetId ?? null;
  });

  expandedWidget = atom(get => {
    const dashboardList = get(this.dashboardAtoms.list);
    const expandedWidgetDashboardId = get(this.expandedWidgetDashboardId);
    const expandedWidgetId = get(this.expandedWidgetId);
    const currentDashboard = expandedWidgetDashboardId
      ? dashboardList?.data?.find(d => d.id === expandedWidgetDashboardId)
      : null;
    if (!currentDashboard) return null;
    return currentDashboard.widgets?.find(w => w.id === expandedWidgetId) ?? null;
  });

  delete = atom(
    null,
    async (
      get,
      set,
      widgetId: string,
      dashboardId: string = get(this.dashboardAtoms.currentDashboardId) ?? ''
    ) => {
      if (!dashboardId) throw new Error('Dashboard id required');
      const confirmed = await set(promptAtom, 'Are you sure you want to delete this widget?');
      if (!confirmed) return;
      const current = get(this.dashboardAtoms.dashboardFamily(dashboardId));
      if (!current) throw new Error('Dashboard not found');
      await set(this.dashboardAtoms.updateDashboard, {
        ...current,
        widgets: current.widgets.filter(w => w.id !== widgetId)
      });
      if (
        get(this.expandedWidgetId) === widgetId &&
        get(this.expandedWidgetDashboardId) === dashboardId
      ) {
        await set(this.dashboardAtoms.returnToDefaultDashboardView);
      }
      set(toastAtom, { title: 'Widget deleted' });
    }
  );

  moveWidget = atom(
    null,
    async (
      get,
      set,
      {
        dashboardFromId,
        dashboardToId,
        widgetId
      }: { dashboardFromId: string; dashboardToId: string; widgetId: string }
    ) => {
      const dashboardFrom = get(this.dashboardAtoms.dashboardFamily(dashboardFromId));
      const dashboardTo = get(this.dashboardAtoms.dashboardFamily(dashboardToId));
      if (!dashboardFrom || !dashboardTo) {
        throw new Error('Dashboard not found!');
      }
      const widget = dashboardFrom.widgets?.find(w => w.id === widgetId);
      if (!widget) {
        throw new Error('Widget not found!');
      }
      const newWidget = {
        ...widget,
        layout: undefined
      };
      await set(this.upsert, newWidget, dashboardToId);
      await set(this.delete, widgetId, dashboardFromId);

      set(toastAtom, { title: 'Widget moved' });
    }
  );

  widgetDetailsCustomMenuItems = atom<CustomMenuItem[] | null>(null);

  commands = atom<CommandGroup | null>(get => {
    const store = get(_storeAtom);
    if (!store) return null;
    const router = get(routingAtom);
    const currentDashboard = get(this.dashboardAtoms.currentDashboard);
    const expandedWidget = get(this.expandedWidget);
    const customWidgetMenuItems = get(this.widgetDetailsCustomMenuItems) ?? [];
    return {
      label: 'Widget',
      id: 'widget',
      commands: [
        currentDashboard
          ? {
              label: 'Add Widget',
              id: 'add-widget',
              handler: () => store.set(this.startAddingWidget, currentDashboard.id)
            }
          : null,
        expandedWidget && currentDashboard
          ? {
              label: 'Return to Dashboard',
              keywords: ['back', 'return', 'dashboard'],
              id: 'return-to-dashboard',
              handler: () =>
                router?.navigate({ to: '/dash/$id', params: { id: currentDashboard.id } })
            }
          : null,
        expandedWidget
          ? {
              label: 'Edit Widget',
              id: 'edit-widget',
              handler: () => store.set(this.startAddingWidget, expandedWidget.id)
            }
          : null,

        ...(currentDashboard && !expandedWidget
          ? currentDashboard.widgets
              .map(widget => {
                if (widget.type === 'Note') return [];
                return [
                  {
                    label: `Expand ${widget.title}`,
                    id: `expand-widget-${widget.id}`,
                    handler: () => store.set(this.inspectWidget, widget.id)
                  },
                  {
                    label: `Edit ${widget.title}`,
                    id: `edit-widget-${widget.id}`,
                    handler: () =>
                      store.set(this.startEditingWidget, widget.id, currentDashboard.id)
                  }
                  // TODO: Add delete command
                ];
              })
              .flat()
          : []),
        ...customWidgetMenuItems.map((item, idx) => ({
          label: item.label,
          id: `${item.label}-${idx}`,
          handler: item.onClick
        }))
        // TODO: Add delete command (need to migrate over the dashboard upsert first)
      ].filter(isNotEmpty)
    };
  });

  finishEditingWidget = atom(null, async (get, set, widgetId: string | null) => {
    await set(this.dashboardAtoms.returnToDefaultDashboardView);
    if (!widgetId) return;
    setTimeout(() => {
      const widgetWrapper = document.body.querySelector(`[data-widget-id="${widgetId}"]`);
      if (!widgetWrapper) return;
      widgetWrapper.scrollIntoView({ block: 'start' });
    }, 300);
  });

  startAddingWidget = atom(
    null,
    (get, set, dashboardId: string = get(this.dashboardAtoms.currentDashboardId) ?? '') => {
      if (!dashboardId) throw new Error('Dashboard id required');
      const router = get(routingAtom);
      router?.navigate({ to: '/dash/$dashboardId/add', params: { dashboardId } });
    }
  );

  startEditingWidget = atom(
    null,
    (
      get,
      set,
      widgetId: string,
      dashboardId: string = get(this.dashboardAtoms.currentDashboardId) ?? ''
    ) => {
      if (!dashboardId) throw new Error('Dashboard id required');
      const router = get(routingAtom);
      router?.navigate({
        to: '/dash/$dashboardId/$widgetId/edit',
        params: { widgetId, dashboardId }
      });
    }
  );

  inspectWidget = atom(
    null,
    (
      get,
      set,
      widgetId: string,
      dashboardId: string = get(this.dashboardAtoms.currentDashboardId) ?? ''
    ) => {
      if (!dashboardId) throw new Error('Dashboard id required');
      const router = get(routingAtom);
      if (!router) throw new Error('Router required');
      if (!dashboardId) throw new Error('Dashboard id required');
      router.navigate({
        to: '/dash/$dashboardId/$widgetId',
        params: { dashboardId, widgetId }
      });
    }
  );
}
