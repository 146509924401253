import { PaginationState, SortingState } from '@tanstack/react-table';
import { Button, Card, DataTable, Dialog, RadioButtonGroup, Select } from 'axil-web-ui';
import { useAtomValue, useSetAtom } from 'jotai';
import { PenIcon } from 'lucide-react';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useConnectorById, useDataSource, useDataSourceSections, useDataFetch } from 'src/hooks';
import { useAbstractDataFieldTableColumns } from 'src/hooks/tables';
import { SyncType } from 'src/types/service';
import { getLogoSrc } from 'src/utils';
import EditDataSourceName from '../DataSource/EditDataSourceName';
import TestDataSourceNotification from '../DataSource/TestDataSourceNotification';
import PageLoader from '../common/loading/PageLoader';
import { dataSourceRoute } from '../routes';
import { dataSourceAtoms, isSmallScreenSizeAtom } from 'src/atoms';

const defaultPaginationState = {
  pageIndex: 0,
  pageSize: 200
};
export default function DataSourcePage() {
  const params = dataSourceRoute.useParams();
  const dataSourceId = params.id;
  if (!dataSourceId) throw new Error('DataSource Id not found!');
  const { isLoading: connectorsLoading, connectors } = useConnectorById();
  const { data: dataSource } = useDataSource(dataSourceId);
  const [updatingDataSourceName, setUpdatingDataSourceName] = useState(false);
  const testConnectionMutation = useAtomValue(dataSourceAtoms.testConnectionMutation);
  const syncDataSource = useSetAtom(dataSourceAtoms.syncDataSource);
  const syncingDataSource = useAtomValue(dataSourceAtoms.syncingDataSource);
  const deleteDataSource = useSetAtom(dataSourceAtoms.deleteDataSource);
  // const reconnectMutation = useReconnectDataSource();
  const patchDataSource = useSetAtom(dataSourceAtoms.patchDataSource);
  const isLoading = connectorsLoading;
  const { data: sections } = useDataSourceSections(dataSourceId);
  const [sortingState, setSortingState] = useState<SortingState | null>(null);
  const [paginationState, setPaginationState] = useState<PaginationState>(defaultPaginationState);
  const [selectedSection, setSelectedSection] = useState<string | null>(null);
  useLayoutEffect(() => {
    if (selectedSection) {
      setSelectedSection(null);
    }
  }, [dataSourceId]);
  useEffect(() => {
    if ((!selectedSection || !sections?.find(s => s.id === selectedSection)) && sections) {
      setSelectedSection(sections[0]?.id);
    }
  }, [sections, selectedSection]);
  useLayoutEffect(() => {
    setSortingState(null);
    setPaginationState(defaultPaginationState);
  }, [selectedSection]);
  // Reset pagination when sorting changes
  useLayoutEffect(() => {
    setPaginationState(defaultPaginationState);
  }, [sortingState]);
  const { data: records } = useDataFetch(
    selectedSection ? [{ sectionId: selectedSection }] : null,
    {
      pageIndex: paginationState.pageIndex,
      pageSize: paginationState.pageSize,
      defaultDir: 'DESC',
      selected: 'all',
      order: sortingState?.map(s => ({ name: s.id, dir: s.desc ? 'DESC' : 'ASC' }))
    }
  );
  const columns = useAbstractDataFieldTableColumns(records?.fields ?? null);
  const mobileMode = useAtomValue(isSmallScreenSizeAtom);
  if (isLoading || dataSource === undefined) return <PageLoader />;
  if (dataSource == null) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <h3>DataSource not found. It may have been deleted</h3>
      </div>
    );
  }
  const connectorInfo = connectors?.[dataSource.connectorId];
  const sectionOptions = sections
    ?.map(s => ({ label: s.label, value: s.id }))
    .sort((a, b) => a.label.localeCompare(b.label));
  return (
    <div className="h-full w-full p-4">
      <Card
        className="bg-base-200 mx-auto flex h-full max-h-full w-full max-w-5xl flex-col gap-4 md:p-4"
        style={{ '--padding-card': mobileMode ? '1rem' : undefined } as any}
        title={
          <div className="mb-4 flex w-full flex-row flex-wrap gap-4">
            <div className="flex flex-row gap-4">
              {connectorInfo?.logo ? (
                <div className="size-12 shrink-0 overflow-hidden rounded-full bg-white p-2 md:size-20">
                  <img
                    src={getLogoSrc(connectorInfo.logo)}
                    className="h-full w-full object-contain"
                  />
                </div>
              ) : null}
              <div>
                <h2 className="group mb-1 flex flex-row text-xl">
                  <span>{dataSource.name}</span>
                  <Button
                    ghost
                    square
                    size="sm"
                    className="ml-1 opacity-0 transition-opacity duration-200 ease-in-out group-hover:opacity-100"
                    onClick={() => setUpdatingDataSourceName(true)}>
                    <PenIcon size={'1rem'} />
                  </Button>
                </h2>
                <h5 className="text-base">{`Connected on: ${new Date(
                  dataSource.createdAt
                ).toLocaleDateString()}`}</h5>
              </div>
            </div>
            <div className="flex grow flex-row flex-wrap gap-4 sm:justify-end">
              {dataSource.syncType !== SyncType.CLIENT_LINKED ? (
                <Button
                  color="secondary"
                  onClick={() => syncDataSource(dataSource.id)}
                  disabled={syncingDataSource}
                  loading={syncingDataSource}>
                  Sync
                </Button>
              ) : null}
              {/* <Button primary onClick={() => reconnectMutation.mutate(dataSource)}>
                Reconnect
              </Button> */}
              <Button color="error" ghost onClick={() => deleteDataSource(dataSource.id)}>
                Delete
              </Button>
            </div>
          </div>
        }
        body={
          <div className="flex max-h-full min-h-0 grow flex-col">
            {mobileMode && sections && sectionOptions ? (
              <Select
                label="Data Sections"
                options={sectionOptions}
                className="z-20"
                name="section"
                onChange={opt => setSelectedSection(opt?.value ?? null)}
                value={sectionOptions.find(opt => selectedSection === opt.value)}
              />
            ) : null}
            <div className="flex min-h-0 shrink grow">
              <div className="min-w-0 shrink grow">
                <div className="border-base-300 flex h-full flex-col overflow-hidden rounded-xl border">
                  {columns && records ? (
                    <>
                      <DataTable
                        columns={columns}
                        data={records.data ?? null}
                        type="pagination"
                        size={mobileMode ? 'xs' : 'md'}
                        paginatedClassName="p-4"
                        sortingState={sortingState}
                        onHeaderCellClick={header => {
                          setSortingState([
                            {
                              id: header.id,
                              desc: sortingState?.[0].id === header.id && !sortingState?.[0].desc
                            }
                          ]);
                        }}
                        paginationState={paginationState}
                        onPaginationChange={setPaginationState}
                        pageCount={Math.ceil(records.total / paginationState.pageSize)}
                      />
                      {!mobileMode ? (
                        <p className="shrink-0 grow-0 px-4 py-2 text-lg md:p-4">{`Total Records: ${Intl.NumberFormat().format(records.total)}`}</p>
                      ) : null}
                    </>
                  ) : null}
                </div>
              </div>
              {!mobileMode && sections && sectionOptions ? (
                <div className="flex max-w-56 shrink-0 flex-col gap-2 text-balance px-4">
                  <h2>Data Sections</h2>
                  <RadioButtonGroup
                    label=""
                    options={sectionOptions}
                    name="section"
                    onChange={evt => setSelectedSection(evt.target.value)}
                    value={selectedSection}
                  />
                </div>
              ) : null}
            </div>
          </div>
        }
      />
      <TestDataSourceNotification
        isSuccess={testConnectionMutation.isSuccess}
        isLoading={testConnectionMutation.isPending}
        isError={testConnectionMutation.isError}
      />
      <Dialog
        isOpen={Boolean(updatingDataSourceName)}
        onClose={() => setUpdatingDataSourceName(false)}>
        <EditDataSourceName
          initialName={dataSource.name}
          dataSourceId={dataSource.id}
          onSave={async values => {
            await patchDataSource({
              id: dataSource.id,
              ...values
            });
            setUpdatingDataSourceName(false);
          }}
          onCancel={() => setUpdatingDataSourceName(false)}
        />
      </Dialog>
    </div>
  );
}
