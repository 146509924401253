import { addSeconds, format as dateFormat } from 'date-fns';
import { startOfDay } from 'date-fns';
import { BaseFieldTypeDesc } from './common';
export default class TimeOfDayDesc extends BaseFieldTypeDesc {
    typeLabel = 'Time of Day';
    dataLabel = 'Time of Day';
    unitLabels = {
        elapsedSeconds: 'Elapsed Seconds'
    };
    static getDateToFormat = (elapsedSeconds) => {
        return addSeconds(startOfDay(new Date()), elapsedSeconds);
    };
    dataPointFormatter = (field, dataPoint) => {
        const date = TimeOfDayDesc.getDateToFormat(dataPoint);
        // Sometimes, animations throw invalid dates. Just render nothing in this case
        if (isNaN(date))
            return '';
        return dateFormat(date, 'p');
    };
    tickFormatter = (field, dataPoint) => {
        const date = TimeOfDayDesc.getDateToFormat(dataPoint);
        // Sometimes, animations throw invalid dates. Just render nothing in this case
        if (isNaN(date))
            return '';
        return dateFormat(date, 'p');
    };
    getAxisLabel = (field, combined) => {
        return combined ? this.dataLabel : field.label;
    };
}
