import { Widget, widgetTypes } from 'daydash-data-structures';
import React from 'react';

import { cn } from 'axil-web-ui';
import widgetConfig from '../WidgetTypes/config';

export interface InitialWidgetTypePickerProps {
  onSelect: (type: Widget['type']) => void;
  selectedType: Widget['type'] | null;
}

/**
 * TODO:
 * - Add a search box once you get enough widgets
 */
export default function InitialWidgetTypePicker({
  onSelect,
  selectedType
}: InitialWidgetTypePickerProps) {
  return (
    <div className="align-start flex h-full w-full max-w-full flex-row justify-center">
      <div className="flex w-full flex-col items-center gap-8">
        <h2 className="text-center text-2xl">Select a Widget Type</h2>
        <div
          className="grid w-full max-w-full gap-8 overflow-y-auto overflow-x-hidden py-2 md:p-8"
          style={{
            gridTemplateColumns: 'repeat(auto-fit, minmax(112px, 1fr))',
            gridAutoRows: 'auto',
            columnGap: '1rem',
            alignItems: 'center',
            justifyItems: 'center',
            gridAutoFlow: 'columns dense'
          }}>
          {widgetTypes.map(({ type, label }) => {
            const Icon = widgetConfig[type].Icon;
            return (
              <button
                className={cn(
                  'flex size-32 max-w-full cursor-pointer flex-col rounded-md bg-white p-4 text-neutral-600 shadow-md transition-transform hover:scale-110 dark:bg-neutral-500 dark:text-white',
                  selectedType === type ? 'outline-accent outline outline-4' : null
                )}
                onClick={() => onSelect(type)}
                type="button"
                key={type}>
                <Icon className="w-full grow" width="96px" height="96px" />
                <label className="w-full cursor-pointer text-center">{label}</label>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}
