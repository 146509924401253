import { Dashboard } from './entities';

export interface Connector {
  id: string;
  type: 'client-linked' | 'static' | 'backend-linked' | 'backend-linked-with-auth';
  name: string;
  description: string;
  logo: string;
  categories: [string, ...string[]];
  sortPriority: number;
  stage: 'development' | 'alpha' | 'beta' | 'stable';
  supportedClients: string | null;
}

export type AccessCredential = {
  id: string;
  /* Date iSO string */
  createdAt: string;
  /* Date iSO string */
  updatedAt: string;
  ownerId: string;
  type: string;
  connectorId: string;
};

export enum SyncType {
  BACKEND_LINKED = 'BACKEND_LINKED',
  CLIENT_LINKED = 'CLIENT_LINKED',
  STATIC = 'STATIC'
}

export type SortableEntity = { id: string; sortWeight?: string | null; createdAt: string | Date };

export interface UserToken {
  id: string;
  type: 'reset-password';
  ownerId: string;
  /* Date iSO string */
  expires: string;
}

export type ENCRYPTION_TYPE = 'none';

export interface DataSourceDataPointBatch {
  id: string;
  data: string;
  /* Date iSO string */
  createdAt: string;
  /* Date iSO string */
  updatedAt: string;
  type: string | null;
  dataSourceSectionId: string;
  key: string;
  hash: string;
  encryptionType: 'none';
}

export type DashboardCreatePayload = Pick<Dashboard, 'id' | 'name' | 'widgets' | 'sortWeight'>;
export type DashboardUpdatePayload = DashboardCreatePayload;
