import { useStore } from '@tanstack/react-store';
import { assertNever } from 'axil-utils';
import {
  Card,
  Checkbox,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Select,
  TextInput
} from 'axil-web-ui';
import {
  AbstractDataField,
  AggregateField,
  BaseLayerConfig,
  DateRangeDataSourceGrouping,
  LayerConfig,
  dateTypes,
  isCategoricalDataType,
  isDateDataType,
  isNumericAbstractDataField
} from 'daydash-data-structures';
import get from 'lodash/get';
import isString from 'lodash/isString';
import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import { WidgetFormPath, useWidgetForm } from 'src/components/Widget/WidgetForm/context';
import { useDataFetch, useSuspenseDataFetch } from 'src/hooks';
import { getFieldDisplayErrors } from 'src/utils/form';
import DataSourceFiltersField from './DataSourceFiltersField';

type DataSourcePath = Extract<WidgetFormPath, `${string}dataSource`>;

const dateTimeTypeOptions: {
  label: string;
  value: DateRangeDataSourceGrouping['dateType'];
  dataTypes: (typeof dateTypes)[number][];
}[] = [
  { label: 'Hour', value: 'hour', dataTypes: ['dateTime'] },
  { label: 'Day', value: 'day', dataTypes: ['dateTime', 'hour'] },
  { label: 'Week', value: 'week', dataTypes: ['dateTime', 'hour', 'date'] },
  { label: 'Month', value: 'month', dataTypes: ['dateTime', 'hour', 'date', 'week'] },
  { label: 'Year', value: 'year', dataTypes: ['dateTime', 'hour', 'date', 'week', 'month'] },
  { label: 'Time of Day', value: 'timeOfDay', dataTypes: ['dateTime'] },
  { label: 'Hour of Day', value: 'hourOfDay', dataTypes: ['dateTime'] },
  { label: 'Day of Week', value: 'dayOfWeek', dataTypes: ['dateTime', 'hour', 'date'] },
  { label: 'Month of Year', value: 'monthOfYear', dataTypes: ['dateTime', 'hour', 'date', 'week'] }
];

const getDateTimeOptionsForDataType = (dataType: (typeof dateTypes)[number]) => {
  return dateTimeTypeOptions.filter(o => o.dataTypes.includes(dataType));
};

const getAggTypeLabel = (aggField: AggregateField) => {
  switch (aggField.operation) {
    case 'sum':
      return 'Total';
    case 'avg':
      return 'Average';
    case 'count':
      return 'Count';
    case 'min':
      return 'Min';
    case 'max':
      return 'Max';
    case 'group_concat':
      return 'Group Concat';
    default:
      assertNever(aggField.operation);
  }
};

const getDefaultAggFields = (dataFields: AbstractDataField[], current: string) => {
  // Now set at all of the agg fields, but in the future we will want to change this
  return dataFields.flatMap<AggregateField>(field => {
    if (field.name === current) return [];
    if (field.type === 'category')
      return [{ fieldName: field.name, enabled: true, operation: 'group_concat' }];
    if (isDateDataType(field.type) || isCategoricalDataType(field.type)) {
      return [{ fieldName: field.name, enabled: true, operation: 'count' }];
    }
    if (isNumericAbstractDataField(field)) {
      const potentialOps = ['sum', 'avg', 'count', 'min', 'max'] as const;
      // Use the one set on the abstract data field by default
      if (field.meta?.defaultAggFieldConfig) {
        const defaultedOps = new Set<string>();
        return (
          field.meta.defaultAggFieldConfig
            .map(aggField => {
              const op = isString(aggField) ? aggField : aggField.operation;
              defaultedOps.add(op);
              return {
                fieldName: field.name,
                enabled: true,
                operation: op,
                label: isString(aggField) ? undefined : aggField.label
              };
            })
            // Add the ones that should be disabled so the user can enable them if they want
            .concat(
              potentialOps
                .filter(op => !defaultedOps.has(op))
                .map(op => ({
                  fieldName: field.name,
                  enabled: false,
                  operation: op,
                  label: undefined
                }))
            )
        );
      }
      return potentialOps.map(op => {
        return {
          fieldName: field.name,
          enabled: true,
          operation: op
        };
      });
    }
    return [];
  });
};
function AggFieldsField({
  fieldPath,
  dataFields
}: {
  fieldPath: Extract<WidgetFormPath, `${string}aggregateFields`>;
  dataFields: AbstractDataField[];
}) {
  const form = useWidgetForm();
  const current = useStore(form.store, state => get(state.values, fieldPath));
  const dataFieldsByName = useMemo(() => {
    return dataFields.reduce(
      (acc, field) => {
        acc[field.name] = field;
        return acc;
      },
      {} as Record<string, AbstractDataField>
    );
  }, [dataFields]);
  if (!current) return null;

  return (
    <div>
      {current.map((aggField, i) => {
        const dataField = dataFieldsByName[aggField.fieldName];
        if (!dataField) return null;
        return (
          <div key={i}>
            <div className="flex flex-wrap justify-between">
              <h4>{`${getAggTypeLabel(aggField)}: ${dataField.label}`}</h4>
              <form.Field name={`${fieldPath}[${i}].enabled`}>
                {field => (
                  <Checkbox
                    label="Enabled"
                    name={field.name}
                    showHelperText={false}
                    checked={!!field.state.value}
                    onChange={e => field.handleChange(e.target.checked)}
                  />
                )}
              </form.Field>
            </div>
            <form.Field name={`${fieldPath}[${i}].label`}>
              {field => (
                <TextInput
                  className="grow"
                  name={field.name}
                  label="Custom Label"
                  variant="outlined"
                  required
                  error={getFieldDisplayErrors(field)}
                  value={(field.state.value as any) ?? ''}
                  onChange={e => field.handleChange(e.target.value)}
                  onBlur={field.handleBlur}
                />
              )}
            </form.Field>
          </div>
        );
      })}
    </div>
  );
}

/**
 * The fields for the grouping layers. This is where we pick the grouping field and type
 */
export function GroupingLayerField({
  dsFieldPath,
  layerIdx
}: {
  dsFieldPath: DataSourcePath;
  layerIdx: number;
}) {
  const form = useWidgetForm();
  const filtersPath = `${dsFieldPath}.layers[${layerIdx}].filters` as const;
  const groupingPath = `${dsFieldPath}.layers[${layerIdx}].grouping` as const;
  const fieldNamePath = `${groupingPath}.fieldName` as const;
  const dateTypePath = `${groupingPath}.dateType` as const;
  const aggFieldPath = `${groupingPath}.aggregateFields` as const;
  const groupingTypePath = `${groupingPath}.type` as const;
  const previousLayersForIndex = useStore(
    form.store,
    s =>
      get(s.values, `${dsFieldPath}.layers`)?.slice(0, layerIdx) as [
        BaseLayerConfig,
        ...LayerConfig[]
      ]
  );
  const currentLayersForIndex = useStore(
    form.store,
    s =>
      get(s.values, `${dsFieldPath}.layers`)?.slice(0, layerIdx + 1) as [
        BaseLayerConfig,
        ...LayerConfig[]
      ]
  );
  const { data: previousRecords } = useDataFetch(
    previousLayersForIndex ? previousLayersForIndex : null,
    {
      defaultDir: 'DESC',
      selected: 'all'
    }
  );
  const dataFields = previousRecords?.fields;

  const { data: currentRecords } = useDataFetch(
    currentLayersForIndex ? currentLayersForIndex : null,
    {
      defaultDir: 'DESC',
      selected: 'all'
    }
  );
  const fieldNameOptions = useMemo(() => {
    return dataFields?.filter(f => isDateDataType(f.type) || isCategoricalDataType(f.type));
  }, [dataFields]);
  const currentDataField = useStore(
    form.store,
    state => dataFields?.find(f => f.name === get(state.values, fieldNamePath)) ?? null
  );
  // Once a current data field is set, we want to set defaults everywhere
  useLayoutEffect(() => {
    if (!currentDataField) return;
    if (!dataFields) return;
    if (form.getFieldValue(aggFieldPath)) return; // Only set it if it hasn't been set
    if (isDateDataType(currentDataField.type)) {
      form.setFieldValue(groupingTypePath, 'dateInterval', { dontUpdateMeta: true });
      // Get the first potential date type
      const defaultDateType = getDateTimeOptionsForDataType(currentDataField.type)[0].value;
      if (!defaultDateType) throw new Error('No default date type');
      form.setFieldValue(`${groupingPath}.dateType`, defaultDateType, { dontUpdateMeta: true });
    } else if (isCategoricalDataType(currentDataField.type)) {
      form.setFieldValue(groupingTypePath, 'category', { dontUpdateMeta: true });
    }
    form.setFieldValue(aggFieldPath, getDefaultAggFields(dataFields, currentDataField.name), {
      dontUpdateMeta: true
    });
  }, [currentDataField]);
  return (
    <div>
      <h3 className="mb-4 text-xl">{`Grouping Layer ${layerIdx}`}</h3>
      <form.Field name={fieldNamePath}>
        {field => (
          <Select
            label={'Group By'}
            name="fieldName"
            options={fieldNameOptions}
            formatOptionLabel={o => o.label}
            value={
              field.state.value
                ? (fieldNameOptions?.find(f => f.name === field.state.value) ?? null)
                : null
            }
            onChange={f => field.handleChange(f?.name ?? '')}
          />
        )}
      </form.Field>
      <form.Field name={dateTypePath}>
        {field => {
          const dataType = currentDataField?.type;
          if (!dataType || !isDateDataType(dataType)) return null;
          const options = getDateTimeOptionsForDataType(dataType);
          return (
            <Select
              label={'Grouping Type'}
              name="groupingType"
              options={options}
              value={options.find(o => o.value === field.state.value) ?? null}
              onChange={o => field.handleChange(o?.value ?? '')}
            />
          );
        }}
      </form.Field>
      {currentRecords?.fields ? (
        <DataSourceFiltersField
          fieldPath={filtersPath}
          dataFields={currentRecords?.fields}
          sectionId={null}
        />
      ) : null}
      {dataFields && currentRecords ? (
        <Collapsible>
          <div className="my-4 flex gap-2">
            <h3 className="text-lg font-medium">Aggregate Field Config</h3>
            <CollapsibleTrigger />
          </div>
          <CollapsibleContent>
            <Card className="bg-base-200 w-full p-8">
              <AggFieldsField fieldPath={aggFieldPath} dataFields={dataFields} />
            </Card>
          </CollapsibleContent>
        </Collapsible>
      ) : null}
    </div>
  );
}
