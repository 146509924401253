import React, { Suspense, useMemo } from 'react';
import { useDashboard } from 'src/hooks';
import WidgetDetails from '../Widget/WidgetDetails';
import PageLoader from '../common/loading/PageLoader';
import { widgetDetailsRoute } from '../routes';

export default function WidgetDetailsPage() {
  const params = widgetDetailsRoute.useParams();
  const { dashboardId, widgetId } = params;
  if (!dashboardId) throw new Error('Dashboard Id not found!');
  const { data: dashboard } = useDashboard(dashboardId);
  const widget = useMemo(() => {
    return dashboard?.widgets?.find(w => w.id === widgetId);
  }, [dashboard?.widgets]);
  if (!widget || !dashboard) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <h3>Widget not found. It may have been deleted</h3>
      </div>
    );
  }
  return (
    <Suspense fallback={<PageLoader />}>
      <WidgetDetails dashboard={dashboard} widget={widget} />
    </Suspense>
  );
}
