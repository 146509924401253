import { Outlet } from '@tanstack/react-router';
import { Toaster } from 'axil-web-ui';
import { useAtomValue } from 'jotai';
import React, { Suspense } from 'react';
import { syncInitializedAtom } from 'src/atoms';
import CommandPalette from '../CommandPalette';
import DevTools from '../DevTools';
import GlobalPrompt from '../GlobalPrompt';
import GlobalUserDialogs from '../GlobalUserDialogs';
import PageLoadingIndicator from '../PageLoadingIndicator';
import PageLoader from '../common/loading/PageLoader';

export default function RootLayout() {
  return (
    <div className="h-dvh w-full overflow-y-auto bg-gradient-to-b from-base-300 to-base-100 dark:from-neutral-700 dark:to-black">
      <Suspense fallback={<PageLoader />}>
        <Outlet />
      </Suspense>
      <DevTools />
      <Toaster />
      <GlobalPrompt />
    </div>
  );
}

// Like the RootLayout, but for once you are actually logged in
export const AuthRootLayout = () => {
  const syncInitialized = useAtomValue(syncInitializedAtom);
  return (
    <>
      <PageLoadingIndicator />

      {syncInitialized ? (
        <Outlet />
      ) : (
        <PageLoader delay={2000} title="Synchronizing your Data. Hol up a minute" />
      )}
      <GlobalUserDialogs />
      <CommandPalette />
    </>
  );
};
