import { useForm } from '@tanstack/react-form';
import { assertNever } from 'axil-utils';
import {
  Badge,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Select,
  TextInput
} from 'axil-web-ui';
import {
  AbstractDataField,
  CategoricalAbstractDataField,
  DataSourceConfigFilter,
  EmptyDataSourceFilter,
  Units,
  dateFilterSchema,
  filterSchema
} from 'daydash-data-structures';
import { formatDataPoint } from 'daydash-unit-descs';
import { get } from 'lodash';
import { PenIcon } from 'lucide-react';
import React, { useEffect, useMemo, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import CurrencyInput from 'src/components/common/forms/CurrencyInput';
import DurationInput from 'src/components/common/forms/DurationInput';
import { useCategoryValues } from 'src/hooks';
import { z } from 'zod';
import { WidgetFormPath, useWidgetForm } from '../context';
import DateFilterInput from './DateFilterInput/DateFilterInput';
import {
  categoryOperations,
  dateRangeOperations,
  emptyOperations,
  numericOperations,
  stringOperations
} from './operationsConfig';
import { useStore } from '@tanstack/react-store';

type FilterForm = ReturnType<typeof useFilterForm>;

function useFilterForm(
  initial: DataSourceConfigFilter | undefined,
  onSubmit: (values: DataSourceConfigFilter) => void
) {
  const form = useForm({
    defaultValues: initial,
    validators: { onChange: filterSchema },
    onSubmit({ value, formApi }) {
      onSubmit(filterSchema.parse(value));
      if (!initial) formApi.reset();
    }
  });
  return form;
}

function useFilterTypeSync(form: FilterForm, type: DataSourceConfigFilter['type']) {
  const operation = useStore(form.store, s => s.values.operation);
  useEffect(() => {
    if (operation)
      form.setFieldValue('type', isEmptyOperation(operation) ? 'empty' : type, {
        dontUpdateMeta: true
      });
  }, [operation]);
}

const isEmptyOperation = (operation: string): operation is EmptyDataSourceFilter['operation'] =>
  operation === 'empty' || operation === 'not-empty';

function DateFilterField({ form }: { form: FilterForm }) {
  const type = useStore(form.store, s => s.values.type);
  useFilterTypeSync(form, 'dateRange');
  const options = useMemo(() => [...dateRangeOperations, ...emptyOperations], []);

  return (
    <>
      <form.Field name="operation">
        {field => (
          <Select
            name={field.name}
            className="min-w-36"
            variant="outlined"
            options={options}
            label={null}
            value={options.find(o => o.value === field.state.value) ?? null}
            onChange={o => (o?.value ? field.handleChange(o.value) : null)}
          />
        )}
      </form.Field>
      {type === 'dateRange' ? (
        <form.Field name="value">
          {field => {
            const parsedVal =
              type === 'dateRange' ? dateFilterSchema.safeParse(field.state.value) : null;
            return (
              <DateFilterInput
                label={null}
                required
                name={field.name}
                value={parsedVal?.success ? parsedVal.data : null}
                onChange={val => field.handleChange(val)}
              />
            );
          }}
        </form.Field>
      ) : null}
    </>
  );
}

function NumericFilterField({
  form,
  field: dataField
}: {
  form: FilterForm;
  field: AbstractDataField;
}) {
  const type = useStore(form.store, s => s.values.type);
  useFilterTypeSync(form, 'numeric');
  const options = useMemo(() => [...numericOperations, ...emptyOperations], []);
  return (
    <>
      <form.Field name="operation">
        {field => (
          <Select
            name={field.name}
            className="min-w-36"
            variant="outlined"
            options={options}
            label={null}
            value={options.find(o => o.value === field.state.value) ?? null}
            onChange={o => (o?.value ? field.handleChange(o.value) : null)}
          />
        )}
      </form.Field>
      {type === 'numeric' ? (
        <form.Field name="value">
          {field =>
            dataField.type === 'currency' ? (
              <CurrencyInput
                name="value"
                currencyCode={dataField.unit}
                label={null}
                inputProps={{ className: 'w-48 min-w-auto' }}
                variant="outlined"
                value={(field.state.value ?? '') as string}
                onChange={val => field.handleChange(val)}
              />
            ) : dataField.type === 'duration' ? (
              <DurationInput
                name="value"
                value={(field.state.value ?? 0) as number}
                unit={dataField.unit}
                variant="outlined"
                onChange={val => field.handleChange(val)}
              />
            ) : (
              <NumericFormat
                name="value"
                customInput={TextInput}
                thousandSeparator
                decimalScale={
                  dataField.type === 'number' && dataField.unit === Units.Number.int ? 0 : 2
                }
                label={null}
                inputProps={{ className: 'w-48 min-w-auto' }}
                variant="outlined"
                value={(field.state.value ?? '') as string}
                onValueChange={values => field.handleChange(values.floatValue)}
              />
            )
          }
        </form.Field>
      ) : null}
    </>
  );
}

function StringFilterField({ form }: { form: FilterForm }) {
  const type = useStore(form.store, s => s.values.type);
  useFilterTypeSync(form, 'string');
  const options = useMemo(() => [...stringOperations, ...emptyOperations], []);
  return (
    <>
      <form.Field name="operation">
        {field => (
          <Select
            name={field.name}
            className="min-w-36"
            variant="outlined"
            options={options}
            label={null}
            value={options.find(o => o.value === field.state.value) ?? null}
            onChange={o => (o?.value ? field.handleChange(o.value) : null)}
          />
        )}
      </form.Field>
      {type === 'string' ? (
        <form.Field name="value">
          {field => (
            <TextInput
              name={field.name}
              label={null}
              variant="outlined"
              value={(field.state.value ?? '') as string}
              onChange={e => field.handleChange(e.target.value)}
            />
          )}
        </form.Field>
      ) : null}
    </>
  );
}

function CategoryFilterField({
  form,
  field,
  sectionId
}: {
  form: FilterForm;
  field: CategoricalAbstractDataField;
  sectionId: string;
}) {
  const { data: categories } = useCategoryValues(sectionId, field.name);
  const type = useStore(form.store, s => s.values.type);
  useFilterTypeSync(form, 'category');
  const operationsOptions = useMemo(() => {
    return [...categoryOperations, ...emptyOperations];
  }, []);

  const categoryOptions = useMemo(() => {
    return categories?.map(c => ({ label: formatDataPoint(field, c), value: c })) ?? null;
  }, [categories]);

  return (
    <>
      <form.Field name="operation">
        {field => (
          <Select
            name={field.name}
            className="min-w-36"
            variant="outlined"
            options={operationsOptions}
            label={null}
            value={operationsOptions.find(o => o.value === field.state.value) ?? null}
            onChange={o => (o?.value ? field.handleChange(o.value) : null)}
          />
        )}
      </form.Field>
      {type === 'category' && categoryOptions ? (
        <form.Field name="value">
          {field => (
            <Select
              isMulti
              name={field.name}
              className="w-48"
              variant="outlined"
              label={null}
              options={categoryOptions}
              value={categoryOptions.filter(o =>
                (field.state.value as string[] | undefined)?.includes(o.value)
              )}
              onChange={selected => field.handleChange(selected.map(o => o.value))}
            />
          )}
        </form.Field>
      ) : null}
    </>
  );
}

function FilterTypeField({
  form,
  field,
  sectionId
}: {
  field: AbstractDataField;
  form: FilterForm;
  sectionId: string | null;
}) {
  switch (field.type) {
    case 'dateTime':
    case 'date':
    case 'hour':
    case 'week':
    case 'month':
    case 'year':
      return <DateFilterField form={form} />;
    case 'timeOfDay':
    case 'hourOfDay':
    case 'dayOfWeek':
    case 'monthOfYear':
      // Should basically never get here
      return <div>TODO</div>;
    case 'duration':
    case 'temperature':
    case 'energy':
    case 'liquid':
    case 'distance':
    case 'velocity':
    case 'mass':
    case 'number':
    case 'currency':
      return <NumericFilterField form={form} field={field} />;
    case 'string':
      return <StringFilterField form={form} />;
    case 'category':
      if (!sectionId) throw new Error('Category filters not supported without a sectionId');
      return <CategoryFilterField form={form} field={field} sectionId={sectionId} />;
    default:
      assertNever(field);
      return <div>TODO</div>;
  }
}

function DataSourceFilterForm({
  initial,
  dataFields,
  onCancel,
  onDelete,
  onSubmit,
  sectionId
}: {
  initial?: DataSourceConfigFilter;
  dataFields: AbstractDataField[];
  onCancel: () => void;
  onDelete?: () => void;
  onSubmit: (values: DataSourceConfigFilter) => void;
  sectionId: string | null;
}) {
  const form = useFilterForm(initial, onSubmit);
  useEffect(() => {
    form.reset();
  }, [initial]);

  const currentDataField = useStore(
    form.store,
    state => dataFields.find(f => f.name === state.values.fieldName) ?? null
  );
  const filteredDataFields = useMemo(() => {
    return dataFields.filter(f => {
      if (f.type === 'category' && !sectionId) return false;
      return true;
    });
  }, [dataFields, sectionId]);
  return (
    <div className="flex flex-col gap-2 p-4">
      <h2 className="my-2 text-center text-lg">{initial ? 'Edit Filter' : 'Add Filter'}</h2>
      <div className="flex items-end gap-2 py-4">
        <form.Field name="fieldName">
          {field => (
            <Select
              label={null}
              className="min-w-36"
              name={field.name}
              variant="outlined"
              options={filteredDataFields}
              formatOptionLabel={o => o.label}
              value={filteredDataFields.find(f => f.name === field.state.value) ?? null}
              onChange={f => field.handleChange(f?.name ?? '')}
            />
          )}
        </form.Field>
        {currentDataField && (
          <FilterTypeField form={form} field={currentDataField} sectionId={sectionId} />
        )}
      </div>
      <div className="flex w-full justify-center gap-2">
        {initial && onDelete ? (
          <Button onClick={() => onDelete()} type="button" ghost>
            Delete
          </Button>
        ) : null}
        <Button onClick={() => onCancel()} type="button">
          Cancel
        </Button>
        <Button onClick={() => form.handleSubmit()} type="button" color="primary">
          Save
        </Button>
      </div>
    </div>
  );
}

export interface DataSourceFiltersFieldProps {
  fieldPath: Extract<WidgetFormPath, `${string}dataSource.layers[${number}].filters`>;
  dataFields: AbstractDataField[];
  sectionId: string | null; // null means you can't do categories. We can add it in later for grouping
}

export default function DataSourceFiltersField({
  fieldPath,
  dataFields,
  sectionId
}: DataSourceFiltersFieldProps) {
  const form = useWidgetForm();
  const [creatingFilter, setCreatingFilter] = useState(false);
  const [editingFilter, setEditingFilter] = useState<number | null>(null);
  const dataFieldsByName = Object.fromEntries(dataFields.map(f => [f.name, f]));
  const currentFilters = useStore(form.store, state => get(state.values, fieldPath, []));
  return (
    <div>
      <h3 className="mb-2 text-lg">Filters</h3>
      <div className="flex gap-1">
        {currentFilters.map((filterVal, idx) => {
          return (
            <DropdownMenu
              key={idx}
              open={editingFilter === idx}
              onOpenChange={open => (!open ? setEditingFilter(null) : null)}>
              <DropdownMenuContent side="right">
                <DataSourceFilterForm
                  dataFields={dataFields}
                  onCancel={() => setEditingFilter(null)}
                  initial={filterVal}
                  onSubmit={values => {
                    form.setFieldValue(
                      fieldPath,
                      currentFilters.map((f, i) => (i === idx ? values : f))
                    );
                    setEditingFilter(null);
                  }}
                  onDelete={() => {
                    setEditingFilter(null);
                    form.setFieldValue(
                      fieldPath,
                      currentFilters.filter((f, i) => i !== idx)
                    );
                  }}
                  sectionId={sectionId}
                />
              </DropdownMenuContent>
              <DropdownMenuTrigger>
                {/* TODO: Add a hover effect here to reveal the pencil edit */}
                <Badge key={idx} onClick={() => setEditingFilter(idx)}>
                  <span className="mr-2">{dataFieldsByName[filterVal.fieldName]?.label}</span>
                  <PenIcon size={16} />
                </Badge>
              </DropdownMenuTrigger>
            </DropdownMenu>
          );
        })}

        <DropdownMenu
          open={creatingFilter}
          onOpenChange={open => (!open ? setCreatingFilter(false) : null)}>
          <DropdownMenuContent side="right">
            <DataSourceFilterForm
              dataFields={dataFields}
              onCancel={() => setCreatingFilter(false)}
              onSubmit={values => {
                form.setFieldValue(fieldPath, [...currentFilters, values]);
                setCreatingFilter(false);
              }}
              sectionId={sectionId}
            />
          </DropdownMenuContent>
          <DropdownMenuTrigger>
            <Badge onClick={() => setCreatingFilter(true)}>Add New +</Badge>
          </DropdownMenuTrigger>
        </DropdownMenu>
      </div>
    </div>
  );
}
