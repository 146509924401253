import { Loading, cn } from 'axil-web-ui';
import React from 'react';

export interface ContainerSpinnerProps {
  children: React.ReactNode;
  loading: boolean;
  size?: number;
  className?: string;
}

export default function ContainerSpinner({
  children,
  className,
  loading = false,
  size = 96
}: ContainerSpinnerProps) {
  return (
    <div className={(cn('relative', loading ? 'pointer-events-none' : null), className)}>
      {loading ? (
        <div className="absolute inset-0 z-20 flex items-center justify-center backdrop-blur-sm">
          <Loading variant="spinner" size={size} color="primary" />
        </div>
      ) : null}
      {children}
    </div>
  );
}
