import {
  ThemeProvider as VisxThemeProvider,
  buildChartTheme as buildVisxChartTheme
} from '@visx/xychart';
import React, { useEffect, useMemo } from 'react';

import { isNotEmpty } from 'axil-utils';
import { useMatchMedia } from 'axil-web-ui';
import { useAtomValue } from 'jotai';
import { isPlainObject } from 'lodash';
import { currentUserAtom } from 'src/atoms/user.atoms';
import { TopLevelThemeContext } from 'src/context';
import theme, { defaultFontFamily, getThemeColor, ThemeType } from 'src/theme';

// TODO: Move this to Jotai
const preferDarkQuery = '(prefers-color-scheme: dark)';
export const useThemeMode = (): 'dark' | 'light' => {
  const darkmodeQuery = useMatchMedia(preferDarkQuery);
  const currentUser = useAtomValue(currentUserAtom);
  if (!currentUser?.preferences?.appearance || currentUser?.preferences?.appearance === 'system') {
    return darkmodeQuery ? 'dark' : 'light';
  }
  return currentUser.preferences?.appearance;
};

const getVisXTheme = (theme: ThemeType, themeMode: 'dark' | 'light') => {
  const isDark = themeMode === 'dark';
  const strokeColor = isDark
    ? getThemeColor(theme, 'neutral-300', themeMode)
    : getThemeColor(theme, 'neutral-600', themeMode);
  return buildVisxChartTheme({
    backgroundColor: (isDark
      ? getThemeColor(theme, 'neutral-900', themeMode)
      : getThemeColor(theme, 'neutral-50', themeMode)) as string,
    colors: [],
    svgLabelSmall: {
      fontSize: 14,
      fontFamily: defaultFontFamily,
      fill: (isDark
        ? getThemeColor(theme, 'neutral-50', themeMode)
        : getThemeColor(theme, 'neutral-800', themeMode)) as string
    },
    svgLabelBig: {
      fontSize: 20,
      fontFamily: defaultFontFamily,
      fontWeight: 'normal',
      fill: (isDark
        ? getThemeColor(theme, 'neutral-50', themeMode)
        : getThemeColor(theme, 'neutral-800', themeMode)) as string,
      strokeWidth: 0,
      letterSpacing: 'normal'
    },
    xAxisLineStyles: {
      stroke: strokeColor,
      strokeWidth: 2,
      strokeLinecap: 'round',
      strokeLinejoin: 'round'
    },
    yAxisLineStyles: {
      stroke: strokeColor,
      strokeWidth: 2,
      strokeLinecap: 'round',
      strokeLinejoin: 'round'
    },
    xTickLineStyles: {
      stroke: strokeColor
    },
    yTickLineStyles: {
      stroke: strokeColor
    },
    tickLength: 10,
    gridColor: themeMode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.15)',
    gridColorDark: themeMode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.15)',
    gridStyles: {
      strokeDasharray: '10 ,5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round'
    }
  });
};

interface ThemeProviderProps {
  children: React.ReactNode;
}

// Takes the full theme and spruces it up a bit
const getCurrentTheme = (
  theme: ThemeType,
  themeMode: 'dark' | 'light'
): ThemeType & { themeMode: 'dark' | 'light' } => {
  return {
    ...theme,
    themeMode
  };
};

function ThemeProvider(props: ThemeProviderProps) {
  const themeMode = useThemeMode();
  const topTheme = useMemo(() => getCurrentTheme(theme, themeMode), [themeMode]);
  const visXTheme = useMemo(() => getVisXTheme(theme, themeMode), [themeMode]);
  // Sync with root so tailwind and daisy can update accordingly
  useEffect(() => {
    document.documentElement.dataset.theme = themeMode;
  }, [themeMode]);
  return (
    <TopLevelThemeContext.Provider value={{ theme: topTheme }}>
      <VisxThemeProvider theme={visXTheme}>{props.children}</VisxThemeProvider>
    </TopLevelThemeContext.Provider>
  );
}

export default ThemeProvider;
