import { Button, Loading, Select } from 'axil-web-ui';

import { useAtomValue } from 'jotai';
import React, { useMemo, useState } from 'react';
import { dashboardAtoms } from 'src/atoms';
import log from 'src/log';

interface MoveToDashboardProps {
  dashboardFromId: string;
  widgetId: string;
  onSubmit: (dashboardFromId: string, dashboardToId: string, widgetId: string) => Promise<void>;
  onClose: () => void;
}

function MoveToDashboard({ dashboardFromId, widgetId, onSubmit, onClose }: MoveToDashboardProps) {
  const { data: dashboards, isLoading } = useAtomValue(dashboardAtoms.list);
  const [destinationDashboard, setDestinationDashboard] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const options = useMemo(() => {
    if (!dashboards) return [];
    return dashboards
      .filter(d => d.id !== dashboardFromId)
      .map(d => {
        return {
          label: d.name,
          value: d.id
        };
      });
  }, [dashboards, dashboardFromId]);

  async function handleClick() {
    if (!destinationDashboard) return;
    try {
      setSubmitting(true);
      await onSubmit(dashboardFromId, destinationDashboard, widgetId);
      setSubmitting(false);
    } catch (err) {
      log.error(err);
      setSubmitting(false);
    }
    onClose();
  }
  return (
    <div className="w-80 p-8">
      <h2 className="mb-4 text-center text-2xl">Move Widget</h2>
      {isLoading ? (
        <Loading size={48} color="primary" />
      ) : (
        <Select
          label="Destination"
          options={options}
          name="dataSource"
          value={options.find(o => o.value === destinationDashboard)}
          onChange={o => setDestinationDashboard(o?.value ?? null)}
        />
      )}
      <div className="flex flex-row justify-between gap-4">
        <Button
          color="primary"
          type="button"
          onClick={handleClick}
          disabled={!destinationDashboard || submitting}>
          {submitting ? <Loading size={48} /> : 'Submit'}
        </Button>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default MoveToDashboard;
