import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { BaseLayerConfig, LayerConfig, NumericAbstractDataField } from 'daydash-data-structures';
import { useAtomValue } from 'jotai';
import { useContext } from 'react';
import { currentUserAtom } from 'src/atoms/user.atoms';
import { DataFetchOrder } from 'src/core/dataFetching';
import { CoreClientContext } from '../context';

const useCoreClient = () => {
  const coreClient = useContext(CoreClientContext);
  if (!coreClient) throw new Error('CoreClient not found');
  return coreClient;
};

export const useDataSourceSection = (dataSourceSectionId: string) => {
  const coreClient = useCoreClient();
  return useSuspenseQuery(coreClient.getQueryOptions.getDataSourceSection(dataSourceSectionId));
};

export const useSuspenseDataSourceSections = (dataSourceId: string) => {
  const coreClient = useCoreClient();
  return useSuspenseQuery(
    coreClient.getQueryOptions.getDataSourceSectionsForDataSource(dataSourceId)
  );
};

export const useDataSourceSections = (dataSourceId: string | null) => {
  const coreClient = useCoreClient();
  return useQuery(coreClient.getQueryOptions.getDataSourceSectionsForDataSource(dataSourceId));
};

export const useCategoryValues = (sectionId: string, fieldName: string) => {
  const coreClient = useCoreClient();
  return useSuspenseQuery(coreClient.getQueryOptions.getCategoryValues(sectionId, fieldName));
};

export const useSuspenseDataFetch = (
  layers: [BaseLayerConfig, ...LayerConfig[]],
  {
    selected,
    pageIndex = 0,
    pageSize = 2500,
    order = null,
    defaultDir = 'DESC'
  }: {
    pageIndex?: number;
    pageSize?: number;
    defaultDir?: 'ASC' | 'DESC';
    selected: string[] | 'all'; // Final selection of data points
    order?: DataFetchOrder[] | null;
  }
) => {
  const currentUser = useAtomValue(currentUserAtom);
  if (!currentUser) throw new Error('User Required');
  const coreClient = useCoreClient();
  return useSuspenseQuery(
    coreClient.getQueryOptions.queryDataPoints({
      layerConfig: layers,
      unitPreferences: currentUser.preferences.units,
      selected,
      pageIndex,
      pageSize,
      defaultDir,
      order
    })
  );
};

export const useDataFetch = (
  layers: [BaseLayerConfig, ...LayerConfig[]] | null = null,
  {
    selected,
    pageIndex = 0,
    pageSize = 2500,
    order = null,
    defaultDir = 'DESC'
  }: {
    pageIndex?: number;
    pageSize?: number;
    defaultDir?: 'ASC' | 'DESC';
    selected: string[] | 'all'; // Final selection of data points
    order?: DataFetchOrder[] | null;
  }
) => {
  const currentUser = useAtomValue(currentUserAtom);
  if (!currentUser) throw new Error('User Required');
  const coreClient = useCoreClient();
  return useQuery(
    coreClient.getQueryOptions.queryDataPoints({
      layerConfig: layers,
      unitPreferences: currentUser.preferences.units,
      selected,
      pageIndex,
      pageSize,
      defaultDir,
      order
    })
  );
};

/**
 * Useful for finding bounds for data sets you are querying
 */
export function useDataBoundDataFetch(
  initialLayers: [BaseLayerConfig, ...LayerConfig[]],
  yFields: NumericAbstractDataField[]
) {
  return useSuspenseDataFetch(
    [
      ...initialLayers,
      {
        grouping: {
          type: 'all',
          aggregateFields: yFields.flatMap(yField => [
            {
              operation: 'min' as const,
              fieldName: yField.name
            },
            {
              operation: 'max' as const,
              fieldName: yField.name
            }
          ])
        }
      }
    ],
    {
      selected: 'all'
    }
  );
}
