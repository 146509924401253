import {
  AbstractDataField,
  BaseLayerConfig,
  DataSourceConfigFilter,
  DataSourceConfigGrouping,
  LayerConfig,
  UnitPreferences,
  Units,
  isNumericAbstractDataField
} from 'daydash-data-structures';
import knex from 'knex';
import { getDataPointTableName } from '../db/dataPointSchema';
import { getConvertedFields, getConvertedSQLVal } from './conversion';
import { populateFilters } from './filtering';
import { getAggName, getGroupByField, getGroupedFields, getGroupingSelects } from './grouping';

const sqlBuilder = knex({ client: 'pg' });

export type DataFetchOrder = { name: string; dir: 'ASC' | 'DESC' };

// const getFieldExtract = (field: AbstractDataField) => {
//   return `json_extract(point, '$.${field.key}')`;
// };

// Not sure why this is still needed, but here we are
const getCasted = (current: string, field: AbstractDataField) => {
  let dataType: 'REAL' | 'INT' | 'TEXT' | 'NUMERIC';
  if (field.type === 'number' && field.unit === Units.Number.int) dataType = 'INT';
  else if (isNumericAbstractDataField(field)) dataType = 'REAL';
  else dataType = 'TEXT';
  return `cast(${current} as ${dataType})`;
};

const getConvertedFieldStatement = (field: AbstractDataField, initialField: AbstractDataField) => {
  const name = `"${field.name}"`;
  const casted = getCasted(name, field); // Casts the value to the correct SQL type
  const val = getConvertedSQLVal(casted, initialField, field);
  return `${val} AS ${name}`;
};

const getBaseLayerQuery = (
  { sectionId, filters }: BaseLayerConfig,
  {
    initialFields,
    unitPreferences
  }: {
    initialFields: AbstractDataField[];
    unitPreferences: UnitPreferences;
  }
) => {
  const initialFieldsByName = Object.fromEntries(initialFields.map(f => [f.name, f]));
  const convertedFields = getConvertedFields(initialFields, unitPreferences);
  let layerQuery = sqlBuilder
    .select(
      // TODO: Try to make this less raw, or at least used parameterized queries
      sqlBuilder.raw(
        convertedFields
          .map(f => getConvertedFieldStatement(f, initialFieldsByName[f.name]))
          .join(',\n\t\t')
      )
    )
    .from(getDataPointTableName(sectionId));

  // Then, you can add filters
  if (filters) {
    layerQuery = layerQuery.where(function () {
      if (!filters) return;
      for (const filter of filters) {
        populateFilters(filter, this);
      }
    });
  }
  return {
    query: layerQuery,
    fields: convertedFields
  };
};

const getLayerQuery = (
  { filters, grouping }: LayerConfig,
  currentFields: AbstractDataField[],
  prevLayerName: string
) => {
  const { fields: nextFields, groupByField } = getGroupedFields(currentFields, grouping);
  let nextQuery = groupByField
    ? sqlBuilder
        .groupBy(groupByField.name)
        .select(getGroupingSelects(groupByField, grouping, 'all').map(s => sqlBuilder.raw(s)))
        .from(prevLayerName)
    : sqlBuilder
        .select(getGroupingSelects(groupByField, grouping, 'all').map(s => sqlBuilder.raw(s)))
        .from(prevLayerName);
  if (filters) {
    // Need an intermediate CTE to handle filtering so we can filter on the grouped field values
    // Normally we would use HAVING but then we can't use the grouped aliases for each field
    nextQuery = sqlBuilder
      .with('filtered', nextQuery)
      .where(function () {
        if (!filters) return;
        for (const filter of filters) {
          populateFilters(filter, this);
        }
      })
      .from('filtered');
  }
  return {
    query: nextQuery,
    fields: nextFields
  };
};

export interface FetchDataPointParams {
  sectionId: string;
  initialFields: AbstractDataField[];
  unitPreferences: UnitPreferences;
  selected: string[] | 'all';
  pageIndex?: number;
  pageSize?: number;
  defaultDir?: 'ASC' | 'DESC';
  order?: DataFetchOrder[] | null;
  filters?: DataSourceConfigFilter[] | null;
  grouping?: DataSourceConfigGrouping | null;
}

export const fetchDataPoints = (
  [baseLayer, ...layers]: [BaseLayerConfig, ...LayerConfig[]],
  {
    initialFields,
    unitPreferences,
    selected,
    pageIndex = 0,
    pageSize = 2500,
    order = null,
    defaultDir = 'DESC'
  }: {
    initialFields: AbstractDataField[];
    unitPreferences: UnitPreferences;
    pageIndex?: number;
    pageSize?: number;
    defaultDir?: 'ASC' | 'DESC';
    selected: string[] | 'all'; // Final selection of data points
    order?: DataFetchOrder[] | null;
  }
) => {
  /**
   * The idea here is that each layer becomes a CTE, and the final query is a select from the final layer.
   * Each layer feeds into the next
   */
  let { query: finalQuery, fields } = getBaseLayerQuery(baseLayer, {
    initialFields,
    unitPreferences
  });
  finalQuery = sqlBuilder.with('baseLayer', finalQuery);
  for (let i = 0; i < layers.length; i++) {
    const layer = layers[i];
    const layerName = `layer${i + 1}`;
    const prevLayer = i === 0 ? 'baseLayer' : `layer${i}`;
    const { query: nextQuery, fields: nextFields } = getLayerQuery(layer, fields, prevLayer);
    fields = nextFields;
    finalQuery = finalQuery.withMaterialized(layerName, nextQuery);
  }
  const finalLayerName = layers.length === 0 ? 'baseLayer' : `layer${layers.length}`;
  if (!order) {
    // TODO: Replace with meta field later to mark if the field is the primary event time field
    const defaultSortField =
      fields.find(field => field.type === 'dateTime' || field.type === 'date') ?? fields[0];
    order = [{ name: defaultSortField.name, dir: defaultDir }];
  }
  return {
    fields,
    query: finalQuery
      .select(selected === 'all' ? '*' : selected)
      .orderBy(
        order
          .filter(({ name }) => fields.find(f => f.name === name)) // Filter out invalid order by statements
          .map(({ dir, name }) => ({
            column: name,
            order: dir.toLowerCase() as 'asc' | 'desc'
          }))
      )
      .limit(pageSize)
      .offset(pageIndex * pageSize)
      .from(finalLayerName)
  };
};

/**
 * Basically just wrap the whole normal query in a CTE and do a count on it. The value returned is just "count"
 */
export const fetchDataPointTotal = (currentQuery: knex.Knex.QueryBuilder) => {
  const totalLayerName = 'result';
  const query = sqlBuilder
    .with(totalLayerName, currentQuery.clone().limit(10000000000).offset(0))
    .from(totalLayerName);
  return query.count('*', { as: 'count' }).toString();
};

export { getAggName, getGroupByField };
