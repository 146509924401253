import { Button, Tooltip, cn } from 'axil-web-ui';

import { useAtomValue, useSetAtom } from 'jotai';
import { CheckIcon, ChevronRightIcon } from 'lucide-react';
import React, { useMemo, useState } from 'react';
import { onboardingAtoms, updateDialogAtom, widgetAtoms } from 'src/atoms';
import { currentUserAtom } from 'src/atoms/user.atoms';
import { useAllDashboards } from 'src/hooks';
import PageLoader from '../common/loading/PageLoader';
import { OnboardingState } from 'src/atoms/onboarding.atoms';

function Onboarding({ onboardingState }: { onboardingState: OnboardingState }) {
  const currentUser = useAtomValue(currentUserAtom);
  const updateDialog = useSetAtom(updateDialogAtom);
  const { data: currentDashboards } = useAllDashboards();
  const addWidget = useSetAtom(widgetAtoms.startAddingWidget);
  const canCreateWidget = currentDashboards && currentDashboards.length > 0;

  const steps = useMemo(() => {
    return [
      {
        id: 'create-data-source',
        label: 'Create your First Data Source',
        completed: onboardingState.isDataSourceCreated,
        content: (
          <div className="prose">
            <p>
              {`Start maximizing your health, fitness, finance, and productivity with DayDash's intuitive data sources. Your gateway to personalized insights, each source helps you analyze and visualize your information effortlessly.`}
            </p>
            <p>
              {`Not sure where to start? You can create `}
              <Button
                size="sm"
                color="primary"
                onClick={() => {
                  updateDialog({
                    type: 'CreateDataSource',
                    payload: {
                      initialConnector: 'playground'
                    }
                  });
                }}
                className="mx-1 min-w-min">
                sample data
              </Button>
              {` by creating a Playground data source. Experiment, explore, and uncover valuable insights at your own pace. Ready to harness the power of your personal data? Let's get started!`}
            </p>
          </div>
        ),
        action: (
          <Button
            color="primary"
            size="lg"
            onClick={() => updateDialog({ type: 'CreateDataSource', payload: null })}>
            Create Data Source
          </Button>
        )
      },
      {
        id: 'create-dashboard',
        label: 'Create your First Dashboard',
        completed: onboardingState.isDashboardCreated,
        content: (
          <div className="prose">
            <p>{`Once you have data sources, you can then create a dashboard. These are your customized containers for widgets and visualizations, designed to fit your unique needs. With DayDash, you have the power to arrange and organize your data effortlessly.`}</p>
            <p>{`Easily customize your dashboard layout to your liking. Whether you prefer a simple or detailed view, DayDash gives you the flexibility to create a dashboard that works for you. You can even set it to fullscreen mode for continuous display.`}</p>
          </div>
        ),
        action: (
          <Button
            color="primary"
            size="lg"
            onClick={() => updateDialog({ type: 'CreateDashboard', payload: null })}>
            Create Dashboard
          </Button>
        )
      },
      {
        id: 'create-widget',
        label: 'Create your First Widget',
        completed: onboardingState.isDashboardWithWidgetCreated,
        content: (
          <div className="prose">
            <p>{`Widgets are where your data comes to life with classic charts like line, area, bar, or pie charts. DayDash's intuitive interface makes transforming your data into meaningful visuals effortless.`}</p>
            <p>{`Creating and editing widgets is a breeze with DayDash. Whether you're adding a new chart or updating an existing one, you're always in control of your dashboard's content.`}</p>
          </div>
        ),
        action: (
          <Tooltip
            message="You need to create a dashboard first to add a widget"
            open={canCreateWidget ? false : undefined}>
            <Button
              color="primary"
              size="lg"
              disabled={!canCreateWidget}
              onClick={() => addWidget(currentDashboards![0].id)}>
              Create Widget
            </Button>
          </Tooltip>
        )
      }
    ];
  }, [onboardingState, canCreateWidget]);

  const [currentStepId, setCurrentStepId] = useState<string>(
    steps.find(s => s.completed)?.id || steps[0].id
  );

  const currentStep = steps.find(s => s.id === currentStepId)!;

  return (
    <div className="@container p-8">
      <h1 className="mb-8 text-3xl">
        {currentUser?.firstName ? `Welcome ${currentUser.firstName}!` : 'Welcome!'}
      </h1>
      <div className="bg-base-300/50 @md:flex-row flex w-full flex-col rounded-2xl border-2 dark:border-0">
        <div className="@md:w-1/3 p-8">
          <h3 className="mb-4 text-2xl">Get started!</h3>
          <div className="@md:flex-col flex items-start gap-4">
            {steps.map((step, idx) => (
              <button
                key={step.id}
                onClick={() => setCurrentStepId(step.id)}
                className={cn(
                  '@3xl:w-full flex flex-row items-center gap-3 rounded-xl border-2 p-2',
                  currentStepId === step.id
                    ? 'border-neutral-800 dark:border-neutral-100'
                    : 'border-neutral-300 dark:border-neutral-500'
                )}>
                <div
                  className={cn(
                    'flex h-8 w-8 shrink-0 items-center justify-center rounded-full border duration-500 ease-out',
                    step.completed ? 'bg-primary' : 'bg-base-300'
                  )}>
                  {step.completed ? <CheckIcon size="24px" className="text-black" /> : idx + 1}
                </div>
                <div className="@3xl:block hidden grow text-left">{step.label}</div>
                <ChevronRightIcon size="24px" className="@lg:block hidden shrink-0" />
              </button>
            ))}
          </div>
        </div>
        <div className="bg-base-300 @md:w-2/3 grow rounded-r-2xl p-8 leading-relaxed">
          {currentStep.content}
          <div className="py-4">{currentStep.action}</div>
        </div>
      </div>
    </div>
  );
}

export default function OnboardingPage() {
  const onboardingState = useAtomValue(onboardingAtoms.onboardingState);
  if (!onboardingState) return <PageLoader />;
  return <Onboarding onboardingState={onboardingState} />;
}
