import { DeepKeys, ReactFormExtendedApi } from '@tanstack/react-form';
import type { Widget, WidgetByType } from 'daydash-data-structures';
import { createContext, useContext } from 'react';

type WidgetFormApi<T extends Widget['type']> = ReactFormExtendedApi<WidgetByType<T>>;

export type WidgetFormPath<T extends Widget['type'] = Widget['type']> = DeepKeys<WidgetByType<T>>;

export const WidgetFormContext = createContext<WidgetFormApi<Widget['type']> | null>(null);

export const useWidgetForm = <T extends Widget['type']>() => {
  const form = useContext(WidgetFormContext);
  if (!form) throw new Error('useWidgetForm must be used within a WidgetFormProvider');
  return form as unknown as WidgetFormApi<T>;
};
