import React, { useContext } from 'react';

// import { UserProfile } from './types/service';
import CoreClient from 'src/core/core.client';
import theme, { ThemeType } from 'src/theme';

export const TopLevelThemeContext = React.createContext<{
  theme: ThemeType & { themeMode: 'dark' | 'light' };
}>({
  theme: { ...theme, themeMode: 'light' }
});

export const CoreClientContext = React.createContext<CoreClient | null>(null);

export const useCoreClient = () => {
  const db = useContext(CoreClientContext);
  if (!db) throw new Error('DBClientContext not found');
  return db;
};
