import { FieldApi, useStore } from '@tanstack/react-form';
import { assertNever } from 'axil-utils';
import { Button, CheckboxGroup, FileInput, Loading, Select, TextInput, Toggle } from 'axil-web-ui';
import { DataSourceBuilder } from 'daydash-data-structures';
import { useAtomValue } from 'jotai';
import { Trash2Icon } from 'lucide-react';
import React from 'react';
import { useCreateAccessCredential } from 'src/hooks';
import { useUpsertUserFile } from 'src/hooks/service/userFIle';
import log from 'src/log';
import { getFieldDisplayErrors } from 'src/utils/form';
import ContainerSpinner from '../common/loading/ContainerSpinner';
import ColumnConfiguration from './ColumnConfiguration';
import LocationConfiguration from './LocationConfiguration';
import { DataSourceFormPath, useDataSourceForm } from './context';
import { dataSourceFormAtoms } from 'src/atoms';

function ErrorDisplay({ field }: { field: FieldApi<any, any, any, any> }) {
  const errors = getFieldDisplayErrors(field);
  if (!errors) return null;
  return (
    <div>
      {errors.map((error, idx) => {
        return (
          <div key={idx} className="text-error">
            {error}
          </div>
        );
      })}
    </div>
  );
}

const CheckListContent = ({
  content
}: {
  content: DataSourceBuilder.ConnectorContentByType<'checklist'>;
}) => {
  const form = useDataSourceForm();
  return (
    <form.Field
      name={content.fieldName as DataSourceFormPath}
      validators={{
        onChange: ({ value }) =>
          (!value || value.length === 0) && content.required && 'Please enter a value'
      }}>
      {field => (
        <CheckboxGroup
          className="w-96 max-w-full"
          value={field.state.value}
          options={content.options}
          label={content.label}
          error={getFieldDisplayErrors(field)}
          name={field.name}
          onBlur={field.handleBlur}
          onChange={newVal => field.handleChange(newVal)}
        />
      )}
    </form.Field>
  );
};

const SelectContent = ({
  content
}: {
  content: DataSourceBuilder.ConnectorContentByType<'select'>;
}) => {
  const form = useDataSourceForm();
  return (
    <form.Field
      name={content.fieldName as DataSourceFormPath}
      validators={{
        onChange: ({ value }) => !value && content.required && 'Please select an option'
      }}>
      {field => (
        <Select
          className="w-96 max-w-full"
          value={content.options.find(o => o.value === field.state.value)}
          options={content.options}
          label={content.label}
          error={getFieldDisplayErrors(field)}
          name={field.name}
          onBlur={field.handleBlur}
          onChange={o => field.handleChange(o?.value)}
        />
      )}
    </form.Field>
  );
};

const TextFieldContent = ({
  content
}: {
  content: DataSourceBuilder.ConnectorContentByType<'textField'>;
}) => {
  const form = useDataSourceForm();
  return (
    <form.Field
      name={content.fieldName as DataSourceFormPath}
      validators={{
        onChange: ({ value }) => !value && content.required && 'Please enter a value'
      }}>
      {field => (
        <TextInput
          className="w-96 max-w-full"
          value={field.state.value}
          label={content.label}
          error={getFieldDisplayErrors(field)}
          name={field.name}
          required={content.required}
          onBlur={field.handleBlur}
          onChange={e => field.handleChange(e.target.value)}
        />
      )}
    </form.Field>
  );
};

const ToggleContent = ({
  content
}: {
  content: DataSourceBuilder.ConnectorContentByType<'toggle'>;
}) => {
  const form = useDataSourceForm();
  return (
    <form.Field name={content.fieldName as DataSourceFormPath}>
      {field => (
        <Toggle
          className="w-96 max-w-full self-center"
          checked={field.state.value}
          label={content.label}
          error={getFieldDisplayErrors(field)}
          name={field.name}
          onChange={e => field.handleChange(e.target.checked)}
        />
      )}
    </form.Field>
  );
};

const FileUploadStep = ({
  content
}: {
  content: DataSourceBuilder.ConnectorContentByType<'fileUpload'>;
}) => {
  const form = useDataSourceForm();
  const { mutateAsync, isPending } = useUpsertUserFile();
  return (
    <form.Field
      name={content.fieldName as DataSourceFormPath}
      validators={{ onChange: ({ value }) => !value && 'Please upload a file' }}>
      {field => (
        <FileInput
          className="w-96 max-w-full"
          label={
            <span>
              {content.label}
              {isPending ? <Loading className="ml-2 inline-block" /> : null}
            </span>
          }
          error={getFieldDisplayErrors(field)}
          name={field.name}
          onChange={async e => {
            const file = e.target.files?.[0];
            if (!file) return;
            try {
              const resp = await mutateAsync({ id: field.state.value ?? null, file });
              console.log(resp);
              field.handleChange(resp.data.id);
            } catch (err) {
              log.error(err);
            }
          }}
          accept={content.accept}
        />
      )}
    </form.Field>
  );
};

const CreateAccessCredentials = ({
  content
}: {
  content: DataSourceBuilder.ConnectorContentByType<'accessCredentials'>;
}) => {
  const form = useDataSourceForm();
  const { mutateAsync, isPending } = useCreateAccessCredential();
  const connectorId = useStore(form.store, s => s.values.connectorId);
  return (
    <form.Field
      name="accessCredentialId"
      validators={{ onChange: ({ value }) => !value && 'Please connect to continue' }}>
      {field => (
        <div className="flex flex-col items-center gap-8 p-8">
          <p className="text-lg">{content.instructions}</p>
          <Button
            onClick={async () => {
              try {
                const resp = await mutateAsync(connectorId);
                field.handleChange(resp);
              } catch (err) {
                log.error(err);
              }
            }}
            disabled={isPending}
            size="lg"
            color="accent">
            {isPending ? <Loading /> : 'Connect'}
          </Button>
          {field.state.value ? <p>Connected! Hit Next to Continue</p> : null}
          <ErrorDisplay field={field} />
        </div>
      )}
    </form.Field>
  );
};

const TableContent = ({
  content
}: {
  content: DataSourceBuilder.ConnectorContentByType<'table'>;
}) => {
  const form = useDataSourceForm();
  return (
    <form.Field
      name={content.fieldName as DataSourceFormPath}
      defaultValue={form.getFieldValue(content.fieldName as DataSourceFormPath) ?? [{}]}>
      {field => (
        <div className="flex max-w-full flex-col gap-4">
          <label className="text-lg">{content.label}</label>
          <div
            className="grid max-w-full gap-4 overflow-x-auto"
            style={{
              gridTemplateColumns: `repeat(${content.rowInputs.length + 1}, min-content)`
            }}>
            {Array.isArray(field.state.value)
              ? field.state.value.map((row, idx) => (
                  <div key={idx} className="contents">
                    {content.rowInputs.map(child => {
                      const fieldName = `${content.fieldName}.${idx}.${child.fieldName}`;
                      if (child.type === 'toggle')
                        return <ToggleContent key={fieldName} content={{ ...child, fieldName }} />;
                      if (child.type === 'textField')
                        return (
                          <TextFieldContent key={fieldName} content={{ ...child, fieldName }} />
                        );
                      if (child.type === 'select')
                        return <SelectContent key={fieldName} content={{ ...child, fieldName }} />;
                      assertNever(child);
                    })}
                    <div className="flex h-full w-full items-center justify-center">
                      {field.state.value.length > 1 ? (
                        <Button
                          size="md"
                          square
                          onClick={() =>
                            field.handleChange(
                              Array.isArray(field.state.value)
                                ? field.state.value.filter((_, index) => index !== idx)
                                : []
                            )
                          }>
                          <Trash2Icon size={'1rem'} />
                        </Button>
                      ) : null}
                    </div>
                  </div>
                ))
              : null}
          </div>
          <div>
            <Button onClick={() => field.handleChange([...field.state.value, {}])}>
              {content.addButtonText}
            </Button>
          </div>
        </div>
      )}
    </form.Field>
  );
};

const BuildStep = ({ step }: { step: DataSourceBuilder.ConnectorStep }) => {
  const isSubmitting = useAtomValue(dataSourceFormAtoms.isSubmittingBuilder);
  if (step.pending) throw new Error('Cannot render pending steps!');
  return (
    <ContainerSpinner className="flex h-full flex-col items-center gap-4" loading={isSubmitting}>
      <h2 className="text-center text-2xl font-bold">{step.label}</h2>
      <div className="min-h-0 w-full shrink grow overflow-auto">
        <div className="flex max-w-full flex-col items-stretch justify-center gap-4 sm:items-center md:gap-8">
          {step.content.map((child, idx) => {
            if (child.type === 'description')
              return (
                <p className="prose" key={idx}>
                  {child.message}
                </p>
              );
            if (child.type === 'divider') return <hr key={idx} />;
            if (child.type === 'toggle') return <ToggleContent key={idx} content={child} />;
            if (child.type === 'textField') return <TextFieldContent key={idx} content={child} />;
            if (child.type === 'checklist') return <CheckListContent key={idx} content={child} />;
            if (child.type === 'select') return <SelectContent key={idx} content={child} />;
            if (child.type === 'accessCredentials')
              return <CreateAccessCredentials key={idx} content={child} />;
            if (child.type === 'columnConfiguration')
              return <ColumnConfiguration key={idx} content={child} />;
            if (child.type === 'dataPreview') return <div key={idx}>TODO: Data Preview</div>;
            if (child.type === 'locationInput')
              return <LocationConfiguration key={idx} content={child} />;
            if (child.type === 'table') return <TableContent key={idx} content={child} />;
            if (child.type === 'fileUpload') return <FileUploadStep key={idx} content={child} />;
            if (child.type === 'hidden') return null;
            assertNever(child);
          })}
        </div>
      </div>
    </ContainerSpinner>
  );
};

export default BuildStep;
