import { useMatch, useNavigate } from '@tanstack/react-router';
import { useBlocker } from '@tanstack/react-router';
import { Widget } from 'daydash-data-structures';
import { useAtomValue, useSetAtom } from 'jotai';
import { XIcon } from 'lucide-react';
import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { promptAtom, widgetAtoms, widgetFormAtoms } from 'src/atoms';
import { useDashboard } from 'src/hooks';
import WidgetForm from '../Widget/WidgetForm/WidgetForm';
import PageLoader from '../common/loading/PageLoader';

export default function WidgetUpsertPage() {
  const dashboardId = useMatch({
    strict: false,
    select: match => match.params.dashboardId
  });
  const widgetId = useMatch({
    strict: false,
    select: match => match.params.widgetId
  });
  if (!dashboardId) throw new Error('No dashboardId found');
  // const { dashboardId, widgetId } = params;
  const { data: dashboard, isLoading } = useDashboard(dashboardId);
  const widget = useMemo(() => {
    return widgetId ? (dashboard?.widgets?.find(w => w.id === widgetId) ?? null) : null;
  }, [dashboard?.widgets, widgetId]);
  const upsert = useSetAtom(widgetAtoms.upsert);
  const [submitted, setSubmitted] = useState(false);
  const dirty = useAtomValue(widgetFormAtoms.widgetFormDirty);
  const prompt = useSetAtom(promptAtom);
  const finishEditingWidget = useSetAtom(widgetAtoms.finishEditingWidget);
  const navigate = useNavigate();
  useBlocker({
    withResolver: false,
    shouldBlockFn: async () => {
      if (dirty && !submitted) {
        const confirmed = await prompt('Are you sure you want to leave? You have unsaved changes.');
        return !confirmed;
      }
      return false;
    }
  });

  const handleSubmit = useCallback(
    async (newWidget: Widget) => {
      setSubmitted(true);
      await upsert(newWidget, dashboardId);
      finishEditingWidget(newWidget.id);
    },
    [upsert, navigate]
  );

  if (isLoading) return <PageLoader />;
  if (!dashboard) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <h3>Dashboard not found. It may have been deleted</h3>
      </div>
    );
  }
  return (
    <div className="relative h-full p-8">
      <button
        className="text-base-content absolute left-2 top-2"
        onClick={() => finishEditingWidget(widget?.id ?? null)}>
        <XIcon size="42px" />
      </button>
      <Suspense fallback={<PageLoader />}>
        <WidgetForm initial={widget ?? null} onSubmit={handleSubmit} />
      </Suspense>
      <div className="hidden shrink-0 grow-0 flex-row justify-center gap-8 sm:flex">
        <p>
          Widget templates coming soon! Have an idea for a template? Contact us{' '}
          <a
            href="mailto:support@daydash.io?subject=Widget%20Template%20Idea"
            className="text-accent underline">
            here
          </a>
        </p>
      </div>
    </div>
  );
}
