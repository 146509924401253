import { assertNever } from 'axil-utils/dist';
import { AbstractDataField, isNumericAbstractDataField } from 'daydash-data-structures/dist';
import { getTableColumns, sql } from 'drizzle-orm';
import { numeric, pgTable, text, uuid } from 'drizzle-orm/pg-core';
import { PgliteDatabase } from 'drizzle-orm/pglite';
import { v4 as uuidV4 } from 'uuid';

const batchIdColumn = uuid('batchId')
  .notNull()
  .$default(() => uuidV4());

const getColumnTypeForField = (field: AbstractDataField) => {
  if (isNumericAbstractDataField(field)) return numeric(field.name);
  return text(field.name);
};

const getColumnsFromFields = (fields: AbstractDataField[]) => {
  const fieldColumns = fields.map(field => [field.name, getColumnTypeForField(field)]);
  return [...fieldColumns, ['batchId', batchIdColumn]] as [
    string,
    ReturnType<typeof getColumnTypeForField> | typeof batchIdColumn
  ][];
};

const getSchemaFromFields = (tableName: string, fields: AbstractDataField[]) => {
  return pgTable(tableName, Object.fromEntries(getColumnsFromFields(fields)));
};

export type DataPointSchema = ReturnType<typeof getSchemaFromFields>;

const getDataTypeFromSchemaColumnType = (column: DataPointSchema['_']['columns'][string]) => {
  if (column.columnType === 'PgUUID') return 'uuid';
  // We would use int here except that we often store full values but only parse them as an int, hence numeric
  // if (column.columnType === 'PgInteger') return 'numeric';
  if (column.columnType === 'PgNumeric') return 'numeric';
  if (column.columnType === 'PgText') return 'text';
  assertNever(column.columnType);
};

export const getDataPointTableName = (sectionId: string) =>
  'data_point_' + sectionId.replace(/-/g, '_');

export const getSectionIdFromDataPointTableName = (tableName: string) =>
  tableName.replace('data_point_', '').replace(/_/g, '-');

export class ColumnMismatchError extends Error {
  constructor() {
    super('Column mismatch');
  }
}

const areColumnsMismatched = async (
  db: PgliteDatabase,
  schema: DataPointSchema,
  sectionId: string
) => {
  const schemaColumns = Object.values(getTableColumns(schema));
  const currentTableColumns = await db.execute(
    sql.raw(`
SELECT 
  column_name, 
  data_type
FROM 
  information_schema.columns
WHERE 
  table_schema = 'public' 
  AND table_name = '${getDataPointTableName(sectionId)}';`)
  );

  const compareDateTypes = (column: DataPointSchema['columns'], data_type: string) => {
    return getDataTypeFromSchemaColumnType(column) === data_type;
  };
  for (const column of currentTableColumns.rows) {
    const { column_name, data_type } = column as { column_name: string; data_type: string };
    const schemaColumn = schemaColumns.find(c => c.name === column_name);
    // TODO: Catch column mismatch and regenerated the table and schema
    if (!schemaColumn || !compareDateTypes(schemaColumn, data_type)) {
      return true;
    }
  }
  for (const column of schemaColumns) {
    if (!currentTableColumns.rows.find(c => c.column_name === column.name)) return true;
  }
};

export const synchronizeDataPointSchema = async (
  db: PgliteDatabase<any>,
  sectionId: string,
  fields: AbstractDataField[]
) => {
  const tableName = getDataPointTableName(sectionId);
  const updatedSchema = getSchemaFromFields(tableName, fields);
  const currentTableColumns = await db.execute(
    sql.raw(`
SELECT 
    column_name, 
    data_type
FROM 
    information_schema.columns
WHERE 
    table_schema = 'public' 
    AND table_name = '${tableName}';`)
  );
  // Exists. Do a diff. If its different, update the schema
  if (currentTableColumns.rows[0]) {
    if (await areColumnsMismatched(db, updatedSchema, sectionId)) {
      throw new ColumnMismatchError();
    }
    // Else, we are good
  } else {
    // Create from scratch
    await db.execute(
      sql.raw(`
      CREATE TABLE IF NOT EXISTS "${tableName}" (
        ${Object.values(getTableColumns(updatedSchema))
          .map(column =>
            column.name === 'batchId'
              ? `"batchId" uuid NOT NULL`
              : `"${column.name}" ${getDataTypeFromSchemaColumnType(column)}`
          )
          .join(',\n')}
      );`)
    );
  }
  return updatedSchema;
};
