import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  FormControl
} from 'axil-web-ui';
import { AbsoluteDateValue, NowDateValue, RelativeDateValue } from 'daydash-data-structures';
import { ArrowRightIcon } from 'lucide-react';
import React, { useCallback, useState } from 'react';
import QuickPicker from './QuickPicker';

type DateRangeValue = NowDateValue | RelativeDateValue | AbsoluteDateValue;
interface DateFilterInputProps
  extends Omit<React.ComponentProps<typeof FormControl>, 'value' | 'onChange' | 'children'> {
  label: string | null;
  name: string;
  required?: boolean;
  value: DateRangeValue | null | undefined;
  onChange: (val: DateRangeValue | null | undefined) => void;
}

const getButtonLabelFromDateRangeValue = (
  value: NowDateValue | RelativeDateValue | AbsoluteDateValue | null | undefined
) => {
  if (!value) return null;
  if (value.type === 'now') return 'Now';
  if (value.type === 'relative')
    return `${value.amount} ${value.dateUnit} ${value.dir === 'before' ? 'ago' : 'from now'}`;
  if (value.type === 'absolute') return new Date(value.date).toLocaleDateString();
};

function DateFilterInput({
  value,
  label,
  required,
  name,
  onChange,
  ...fieldProps
}: DateFilterInputProps) {
  const [pickerOpen, setPickerOpen] = useState(false);
  const handleSelect = useCallback(
    (value: DateRangeValue | null | undefined) => {
      onChange(value);
      setPickerOpen(false);
    },
    [onChange]
  );
  return (
    <FormControl {...fieldProps} label={label} required={required} id={'date-filter-input'}>
      <DropdownMenu open={pickerOpen} onOpenChange={setPickerOpen}>
        <DropdownMenuTrigger className="self-start">
          <Button type="button" className="w-max" id={'date-filter-input'}>
            {getButtonLabelFromDateRangeValue(value) ?? 'Select Date'} <ArrowRightIcon />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="shadow-3xl" side="right">
          <QuickPicker initialValue={value} onSelect={handleSelect} />
        </DropdownMenuContent>
      </DropdownMenu>
    </FormControl>
  );
}

export default DateFilterInput;
