import { toast } from 'axil-web-ui';
import { atom, createStore } from 'jotai';
import { atomEffect } from 'jotai-effect';
import type CoreClient from 'src/core/core.client';
import { invokeTauri, isTauriEnv } from 'src/environment';
import { ThemeType } from 'src/theme';
import { _activeUserSyncAtom, _setAxiosAuthInterceptorAtom } from './auth.atoms';
import { DataSourceFormAtoms } from './dataSourceForm.atoms';
import { _syncLocationEffect } from './history.atoms';
import { _sidebarCloseOnPathChange, _sidebarEffect } from './sidebar.atoms';
import {
  _autoRefetchDBQueriesAtom,
  _syncInitializedCheckAtom,
  _syncStateBroadcastChannelAtom
} from './sync.atoms';
import { getBreakpointSize } from 'src/hooks/styles';
import { DashboardAtoms } from './dashboards.atoms';
import { WidgetAtoms } from './widgets.atoms';
import { CommandsAtoms } from './commands.atoms';
import { DataSourceAtoms } from './dataSources.atoms';
import { AdminAtoms } from './admin.atoms';
import { OnboardingAtoms } from './onboarding.atoms';
import { WidgetFormAtoms } from './widgetForm.atoms';

export * from './auth.atoms';
export * from './dialogs.atoms';
export * from './history.atoms';
export * from './sidebar.atoms';
export * from './status.atoms';
export * from './sync.atoms';

export const dashboardAtoms = new DashboardAtoms();
export const widgetAtoms = new WidgetAtoms(dashboardAtoms);
export const commandsAtoms = new CommandsAtoms(dashboardAtoms, widgetAtoms);
export const dataSourceAtoms = new DataSourceAtoms();
export const dataSourceFormAtoms = new DataSourceFormAtoms();
export const adminAtoms = new AdminAtoms();
export const onboardingAtoms = new OnboardingAtoms(dataSourceAtoms, dashboardAtoms);
export const widgetFormAtoms = new WidgetFormAtoms(dataSourceAtoms);

export const devToolsAtom = atom({
  jotai: false,
  query: false,
  electric: false
});

export const setDevToolsAtom = atom(
  null,
  (_get, set, devTool: 'jotai' | 'query' | 'electric', show: boolean) => {
    set(devToolsAtom, current => ({
      ...current,
      [devTool]: show
    }));
  }
);

export const promptStateAtom = atom<
  { prompting: false } | { prompting: true; text: string; confirm: (confirmed: boolean) => void }
>({ prompting: false });

export const promptAtom = atom(null, async (_get, set, text: string) => {
  const confirmed = await new Promise<boolean>(resolve => {
    set(promptStateAtom, { prompting: true, text, confirm: resolve });
  });
  set(promptStateAtom, { prompting: false });
  return confirmed;
});

export const coreClientAtom = atom<CoreClient | null>(null);

export const coreClientInitializedAtom = atom(async get => {
  const coreClient = get(coreClientAtom);
  return coreClient?.initialize();
});

export type StandalonePayload = {
  type: string;
  params: string[];
};

export const standaloneAtom = atom<StandalonePayload | null>(null);

export const isStandaloneAtom = atom(get => get(standaloneAtom) !== null);

export const pageTitleAtom = atom(get => {
  return (
    get(dashboardAtoms.currentDashboard)?.name ??
    get(dataSourceAtoms.currentDataSource)?.name ??
    'DayDash'
  );
});

export const _syncPageTitleAtom = atomEffect((get, set) => {
  const pageTitle = get(pageTitleAtom);
  document.title = pageTitle;
  let timeout: number | null = null;
  if (get(isStandaloneAtom) && isTauriEnv) {
    try {
      invokeTauri('update_title', { title: pageTitle });
    } catch (err) {
      // Retry
      timeout = window.setTimeout(() => {
        invokeTauri('update_title', { title: pageTitle });
      }, 1000);
    }
  }
  return () => {
    if (timeout) clearTimeout(timeout);
  };
});

// Should remain private. Its just to get a store reference for certain other atoms
export const _storeAtom = atom<ReturnType<typeof createStore> | null>(null);

export const themeAtom = atom<(ThemeType & { themeMode: 'dark' | 'light' }) | null>(null);

export const pageSizeAtom = atom<keyof ThemeType['screens']>('md');

export const isSmallScreenSizeAtom = atom(get => get(pageSizeAtom) === 'sm');

const _syncPageSizeAtom = atomEffect((get, set) => {
  const screens = get(themeAtom)?.screens;
  if (!screens) return;
  const sync = () => set(pageSizeAtom, getBreakpointSize(window.innerWidth, screens));
  sync();
  window.addEventListener('resize', sync);
  return () => window.removeEventListener('resize', sync);
});

// TODO: Try to handle all of this state in Jotai rather than in module memory in the use toast hook
export const toastAtom = atom(null, (_get, set, params: Parameters<typeof toast>[0]) => {
  return toast(params);
});

export const effectRegistrationAtom = atom(get => {
  // Kick off effects
  get(_syncLocationEffect);
  get(_sidebarEffect);
  get(_sidebarCloseOnPathChange);
  get(_autoRefetchDBQueriesAtom);
  get(_syncInitializedCheckAtom);
  get(_syncStateBroadcastChannelAtom);
  get(_syncPageTitleAtom);
  get(widgetFormAtoms._syncWidgetFormState);
  get(dataSourceFormAtoms._syncDataSourceFormState);
  get(dataSourceFormAtoms._autoRefresh);
  get(_activeUserSyncAtom);
  get(_setAxiosAuthInterceptorAtom);
  get(_syncPageSizeAtom);
});

export type Store = ReturnType<typeof createStore>;

const createRootStore = () => {
  const store = createStore();
  store.set(_storeAtom, store); // Only used by some atoms internally
  return store;
};

export default createRootStore;
