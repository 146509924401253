import { QueryClient } from '@tanstack/react-query';
import {
  ParsedLocation,
  createHashHistory,
  createLazyRoute,
  createRootRouteWithContext,
  createRoute,
  createRouter,
  redirect
} from '@tanstack/react-router';
import React from 'react';
import { Store, onboardingAtoms } from 'src/atoms';
import { authUserAtom, isAuthInitializedAtom } from 'src/atoms/auth.atoms';
import CoreClient from 'src/core/core.client';
import { sortEntities } from 'src/hooks';
import log from 'src/log';
import { hidePageLoader, showPageLoader } from 'src/utils';
import { z } from 'zod';
import MainPageLayout from './layouts/MainPageLayout';
import RootLayout, { AuthRootLayout } from './layouts/RootLayout';
import UnauthenticatedPageLayout from './layouts/UnauthenticatedPageLayout';
import DashboardPage from './pages/DashboardPage';
import DataSourcePage from './pages/DataSourcePage';
import LoginPage from './pages/LoginPage';
import NotFoundPage from './pages/NotFoundPage';
import OnboardingPage from './pages/OnboardingPage';
// import ResetPasswordPage from './pages/ResetPasswordPage';
import WidgetDetailsPage from './pages/WidgetDetailsPage';
import WidgetUpsertPage from './pages/WidgetUpsertPage';

const getCurrentAuthUser = async (context: RouterContext) => {
  const { store } = context;
  await store.get(isAuthInitializedAtom);
  return store.get(authUserAtom);
};

// Global functionality
declare module '@tanstack/react-router' {
  interface HistoryState {
    redirect?: ParsedLocation;
  }

  interface Register {
    /**
     * NOTE: This type is kind of a lie, but a useful lie.
     *
     * Basically, for standalone experiences, we will only have a subset/slice of the overall route tree.
     * Therefore, in those contexts, navigating to a route that is not in the current tree will throw an error.
     *
     * This is expected and is a bug we have to address, but Typescript will not help us here. The best
     * we could do is cast the routing type later but that will greatly hinder the dev experience.
     */
    router: ReturnType<typeof getMainRouter>;
  }
}

export type RouterContext = {
  queryClient: QueryClient;
  coreClient: CoreClient | null;
  store: Store;
};

export const rootRoute = createRootRouteWithContext<RouterContext>()({
  component: RootLayout,
  onLeave: () => showPageLoader(),
  beforeLoad: () => hidePageLoader()
});

/** Unauthed */
const unauthedRoute = createRoute({
  getParentRoute: () => rootRoute,
  id: 'unauthed',
  component: UnauthenticatedPageLayout,
  validateSearch: (search: Record<string, unknown>) =>
    z.object({ redirect: z.string().optional() }).parse(search),
  beforeLoad: async ({ search, context }) => {
    // If they are logged in, redirect them
    if (await getCurrentAuthUser(context)) {
      throw redirect({ to: search.redirect || '/' });
    }
  }
});

export const loginRoute = createRoute({
  getParentRoute: () => unauthedRoute,
  path: '/login',
  component: LoginPage
});

// export const signupRoute = createRoute({
//   getParentRoute: () => unauthedRoute,
//   path: '/signup',
//   component: SignupPage
// });

// export const resetPasswordRoute = createRoute({
//   getParentRoute: () => unauthedRoute,
//   path: '/reset-password/$token',
//   component: ResetPasswordPage
// });

/** Authed */
export const authRoute = createRoute({
  getParentRoute: () => rootRoute,
  component: AuthRootLayout,
  id: 'authed',
  // Handle DB loading here to better manage the lifecycle
  beforeLoad: async ({ location, context }) => {
    const authUser = await getCurrentAuthUser(context);
    if (!authUser) {
      throw redirect({
        to: '/login',
        search: location.href && location.href !== '/' ? { redirect: location.href } : {}
      });
    }
    if (!context.coreClient) throw new Error('DB client not set for authed route');
    // Initialize the DB client as part of the routing system to help with timing issues for nested loaders
    if (!context.coreClient.initialized) {
      await context.coreClient.initialize();
    }
  }
});

const ensureAllDashboards = ({ queryClient, coreClient: dbClient }: RouterContext) => {
  return queryClient.ensureQueryData(dbClient!.getQueryOptions.getAllDashboards());
};

const ensureAllDataSources = ({ queryClient, coreClient: dbClient }: RouterContext) => {
  return queryClient.ensureQueryData(dbClient!.getQueryOptions.getAllDataSources());
};

const ensureDashboardData = (
  { queryClient, coreClient: dbClient }: RouterContext,
  dashboardId: string
) => {
  return queryClient.ensureQueryData(dbClient!.getQueryOptions.getDashboard(dashboardId));
};

/** Full page authed layouts */
export const widgetDetailsRoute = createRoute({
  getParentRoute: () => authRoute,
  path: '/dash/$dashboardId/$widgetId',
  loader: ({ context, params }) => ensureDashboardData(context, params.dashboardId),
  component: WidgetDetailsPage
});

export const widgetEditRoute = createRoute({
  getParentRoute: () => authRoute,
  path: '/dash/$dashboardId/$widgetId/edit',
  // loader: ensureDashboardData,
  component: WidgetUpsertPage
});

export const widgetCreateRoute = createRoute({
  getParentRoute: () => authRoute,
  path: '/dash/$dashboardId/add',
  // loader: ensureDashboardData,
  component: WidgetUpsertPage
});

export const dashboardFullRoute = createRoute({
  getParentRoute: () => authRoute,
  path: '/dash/$id/full',
  loader: ({ context, params }) => ensureDashboardData(context, params.id),
  component: () => <DashboardPage fullScreen />
});

export const dashboardEditRoute = createRoute({
  getParentRoute: () => authRoute,
  path: '/dash/$id/edit',
  loader: ({ context, params }) => ensureDashboardData(context, params.id),
  component: () => <DashboardPage editing />
});

/** Main Layout */
export const authRouteMain = createRoute({
  getParentRoute: () => authRoute,
  id: 'main',
  component: MainPageLayout,
  notFoundComponent: NotFoundPage
});

export const authIndexRoute = createRoute({
  getParentRoute: () => authRouteMain,
  path: '/',
  beforeLoad: async ({ context }) => {
    const [, dashboards] = await Promise.all([
      ensureAllDataSources(context),
      ensureAllDashboards(context)
    ]);
    const onboardedState = context.store.get(onboardingAtoms.onboardingState);
    if (onboardedState && !onboardedState.isFullyOnboarded) {
      throw redirect({ to: '/onboarding' });
    }
    const sortedDashboards = sortEntities(dashboards);
    if (sortedDashboards.length > 0) {
      throw redirect({ to: '/dash/' + sortedDashboards[0].id });
    }
    // Otherwise, just go to onboard
    log.error('No dashboards found, redirecting to onboarding');
    throw redirect({ to: '/onboarding' });
  }
});

export const onboardingRoute = createRoute({
  getParentRoute: () => authRouteMain,
  path: '/onboarding',
  component: OnboardingPage
});

export const dashboardRoute = createRoute({
  getParentRoute: () => authRouteMain,
  path: '/dash/$id',
  loader: ({ context, params }) => ensureDashboardData(context, params.id),
  component: DashboardPage
});

export const dataSourceRoute = createRoute({
  getParentRoute: () => authRouteMain,
  loader: ({ context: { queryClient, coreClient: dbClient }, params }) =>
    queryClient.ensureQueryData(dbClient!.getQueryOptions.getDataSource(params.id)),
  path: '/data-source/$id',
  component: DataSourcePage
});

export const adminRoute = createRoute({
  getParentRoute: () => authRouteMain,
  path: '/admin',
  beforeLoad: async ({ context }) => {
    const authUser = await getCurrentAuthUser(context);
    // TODO: Clerk - Add check to see if admin
    if (!authUser) {
      throw redirect({ to: '/' });
    }
  }
}).lazy(() =>
  import('./pages/AdminPage').then(p =>
    createLazyRoute('/authed/main/admin')({ component: p.default })
  )
);

export const getMainRouter = (
  queryClient: QueryClient,
  coreClient: CoreClient | null,
  store: Store
) => {
  const routeTree = rootRoute.addChildren([
    unauthedRoute.addChildren([loginRoute]),
    // Full screen root
    authRoute.addChildren([
      widgetDetailsRoute,
      widgetEditRoute,
      widgetCreateRoute,
      dashboardFullRoute,
      dashboardEditRoute,
      // Default layout
      authRouteMain.addChildren([
        authIndexRoute,
        onboardingRoute,
        dashboardRoute,
        adminRoute,
        dataSourceRoute
      ])
    ])
  ]);
  return createRouter({ routeTree, context: { queryClient, coreClient, store } });
};

export const getStandaloneDashboardRouter = (
  dashboardId: string,
  queryClient: QueryClient,
  coreClient: CoreClient | null,
  store: Store
) => {
  const routeTree = rootRoute.addChildren([
    authRoute.addChildren([
      widgetDetailsRoute,
      widgetEditRoute,
      widgetCreateRoute,
      dashboardFullRoute,
      dashboardEditRoute
    ])
  ]);
  const history = createHashHistory();
  history.replace(`/dash/${dashboardId}/full`);
  return createRouter({
    routeTree,
    context: { queryClient, coreClient, store },
    history
  });
};
