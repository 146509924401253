import { CurrencyCode, currencyCodes, currencyLabels, customCurrencyCodes } from './currency';
import * as Units from './units';
export { currencyCodes, customCurrencyCodes, currencyLabels, CurrencyCode, Units };
export * as DataSourceBuilder from './dataSourceBuilder';
export * from './widgets/index';
export * from './user';
export const dataToUnits = {
    dateTime: Object.values(Units.DateTime),
    date: Object.values(Units.Date),
    hour: Object.values(Units.Hour),
    week: Object.values(Units.Week),
    month: Object.values(Units.Month),
    year: Object.values(Units.Year),
    timeOfDay: Object.values(Units.TimeOfDay),
    hourOfDay: Object.values(Units.HourOfDay),
    dayOfWeek: Object.values(Units.DayOfWeek),
    monthOfYear: Object.values(Units.MonthOfYear),
    duration: Object.values(Units.Duration),
    temperature: Object.values(Units.Temperature),
    energy: Object.values(Units.Energy),
    liquid: Object.values(Units.Liquid),
    distance: Object.values(Units.Distance),
    mass: Object.values(Units.Mass),
    velocity: Object.values(Units.Velocity),
    number: Object.values(Units.Number),
    currency: Object.values(Units.CurrencyCode),
    string: null,
    category: null
};
export const isValidDataType = (type) => {
    return Object.keys(dataToUnits).includes(type);
};
export const isValidUnitForDataType = (type, unit) => {
    const unitsForType = dataToUnits[type];
    if (unitsForType == null)
        return unit == null;
    return unitsForType?.includes(unit);
};
export const nonNumericTypes = ['string', 'category'];
// Some fields are close enough to categorical that we can use them here
export const categoricalTypes = [
    'category',
    'timeOfDay',
    'hourOfDay',
    'dayOfWeek',
    'monthOfYear'
];
/**
 * Note: This doesn't just include category types, but fields that can be used as a category type
 */
export const isCategoricalDataType = (type) => {
    return categoricalTypes.includes(type);
};
export const isNumericDataType = (type) => {
    return !nonNumericTypes.includes(type);
};
export const isNumericAbstractDataField = (field) => {
    return isNumericDataType(field.type);
};
export const dateTypes = ['dateTime', 'date', 'hour', 'week', 'month', 'year'];
export const isDateDataType = (type) => {
    return dateTypes.includes(type);
};
