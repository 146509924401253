import { Loading, useResizeObserver } from 'axil-web-ui';
import { Widget, widgetSchema } from 'daydash-data-structures';
import { debounce } from 'lodash';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import DashboardContext from '../../Dashboard/context';
import StandardTitle from '../WidgetTypes/common/StandardTitle';
import typeToWidgetMap, { NullComponents } from '../WidgetTypes/config';
import { ViewWidgetProps } from '../types';
import { useWidgetForm } from './context';
import { useStore } from '@tanstack/react-store';

const Noop = () => true;

export default function FinalizeWidget() {
  const form = useWidgetForm();
  const selectedType = useStore(form.store, s => s.values.type);
  const currentParsedWidget = useStore(form.store, s => {
    const parsed = widgetSchema.safeParse(s.values);
    return parsed.success ? parsed.data : null;
  });
  const WidgetEditComponent = (selectedType && typeToWidgetMap[selectedType]?.Edit) ?? null;
  const WidgetViewComponent: React.ComponentType<ViewWidgetProps<any>> =
    (selectedType && typeToWidgetMap[selectedType]?.View) ?? NullComponents.View;
  const previewWrapper = useRef<HTMLDivElement>(null);
  const previewWrapperRect = useResizeObserver(previewWrapper);
  const title = useStore(form.store, s =>
    s.values.type !== 'Note' && s.values.type !== 'SingleValue' ? s.values.title : null
  );
  return (
    <div className="relative flex h-full flex-col overflow-auto">
      <h2 className="text-center text-2xl">Finalize</h2>
      <div className="overflow-auto">
        <div className="flex w-full max-w-full shrink grow flex-col gap-6 p-4 sm:p-12 md:flex-row-reverse">
          {WidgetEditComponent ? (
            <div className="max-w-full basis-20">
              <Suspense
                fallback={
                  <div className="items-centers flex flex-row justify-center p-4">
                    <Loading size={36} color="primary"></Loading>
                  </div>
                }>
                <WidgetEditComponent />
              </Suspense>
            </div>
          ) : null}
          <div className="sticky top-6 flex h-[480px] flex-grow-[2] flex-col gap-2 rounded-xl bg-white shadow-xl sm:p-8 md:min-w-[480px] dark:bg-neutral-700">
            {title ? <StandardTitle title={title} /> : null}
            <div ref={previewWrapper} className="h-full min-h-0 w-full">
              <Suspense
                fallback={
                  <div className="flex h-80 flex-col items-center justify-center p-4">
                    <Loading size={48} color="primary"></Loading>
                  </div>
                }>
                {/* Need the fake context to set proper widths */}
                {currentParsedWidget && previewWrapperRect ? (
                  <DashboardContext.Provider
                    value={{
                      containerRect: previewWrapperRect,
                      inspectingWidget: true,
                      isEditing: true,
                      rowHeight: 320,
                      gridGap: 0,
                      containerPadding: 0,
                      colCount: 1
                    }}>
                    <WidgetViewComponent
                      widget={currentParsedWidget}
                      onSetCustomMenuItems={Noop}
                      onSave={updated => form.baseStore.setState(s => ({ ...s, values: updated }))}
                      preview
                      containerRect={previewWrapperRect}
                    />
                  </DashboardContext.Provider>
                ) : null}
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
