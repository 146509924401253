import { keepPreviousData, queryOptions } from '@tanstack/react-query';
import { assertNever } from 'axil-utils';
import { isEqualWith } from 'lodash';
import { Store, authSessionAtom } from 'src/atoms';
import CommonRpc, { RPCCancelError } from './commonRpc';
import { CoreClientRPCCalls, CoreRPCCalls, DBQueries } from './types';
import { BaseLayerConfig, LayerConfig, UnitPreferences } from 'daydash-data-structures';
import { DataFetchOrder } from './dataFetching';

type DBFetchCall = keyof DBQueries;

export default class CoreClient implements CoreClientRPCCalls {
  public initialized = false;

  constructor(
    private store: Store,
    private commType: 'shared-worker' | 'broadcast-channel' | 'worker-with-election'
  ) {}

  _coreRpc: CommonRpc<CoreRPCCalls, CoreClientRPCCalls> | null = null;

  set coreRpc(rpc: CommonRpc<CoreRPCCalls, CoreClientRPCCalls>) {
    this._coreRpc = rpc;
  }

  get coreRpc() {
    const rpc = this._coreRpc;
    if (!rpc) {
      throw new Error('CoreRpc not initialized');
    }
    return rpc;
  }

  initializeSharedWorkerRPC = () => {
    // TODO: Figure out a better way to ensure the shared worker is cleaned up when necessary
    const worker = new SharedWorker(new URL('./core.worker.shared.ts', import.meta.url), {
      name: 'core',
      type: 'module'
    });
    worker.port.start();
    this.coreRpc = CommonRpc.createFromMessagePort<CoreRPCCalls, CoreClientRPCCalls>(
      worker.port,
      this,
      () => {
        worker.port.close();
        this.reinitialize();
      }
    );
  };

  initializeBroadcastChannelRPC = () => {
    const broadcastChannel = new BroadcastChannel('core');
    this.coreRpc = CommonRpc.createFromBroadcastChannel<CoreRPCCalls, CoreClientRPCCalls>(
      broadcastChannel,
      this,
      () => {
        broadcastChannel.close();
        this.reinitialize();
      }
    );
  };

  initializeWorkerWithElectionRPC = () => {
    const worker = new Worker(new URL('./core.worker.election.ts', import.meta.url), {
      type: 'module'
    });
    this.coreRpc = CommonRpc.createFromWorker<CoreRPCCalls, CoreClientRPCCalls>(
      worker,
      this,
      () => {
        worker.terminate();
        this.reinitialize();
      }
    );
  };

  initialize = async () => {
    if (this.initialized) {
      console.warn('Already initialized. Returning');
      return;
    }
    if (this.commType === 'shared-worker') {
      this.initializeSharedWorkerRPC();
    } else if (this.commType === 'broadcast-channel') {
      this.initializeBroadcastChannelRPC();
    } else if (this.commType === 'worker-with-election') {
      this.initializeWorkerWithElectionRPC();
    } else {
      assertNever(this.commType);
    }
    this.initialized = true;
  };

  close = async () => {
    if (!this.initialized) {
      console.warn('Not initialized. Returning');
      return;
    }
    this.initialized = false;
  };

  reinitialize = async () => {
    await this.close();
    this.initialize();
  };

  getCapabilities = async () => {
    return this.coreRpc?.call('getCapabilities') as Promise<string[]>;
  };

  openStandaloneDashboard = async (id: string) => {
    return this.coreRpc?.call('openStandaloneDashboard', id);
  };

  private generateDBQuery = <C extends DBFetchCall, R = ReturnType<DBQueries[C]>>(
    dbCall: C
  ): DBQueries[C] => {
    return (async (...params: Parameters<DBQueries[C]>): Promise<Awaited<R>> => {
      if (!this.initialized) {
        throw new Error('Coreclient not initialized');
      }
      return this.coreRpc?.call('query', dbCall, params);
    }) as any; // TODO: Figure out how to type this properly
  };

  queries: DBQueries = {
    getDashboard: this.generateDBQuery('getDashboard'),
    getAllDashboards: this.generateDBQuery('getAllDashboards'),
    getDataSource: this.generateDBQuery('getDataSource'),
    getUserFile: this.generateDBQuery('getUserFile'),
    getAllDataSources: this.generateDBQuery('getAllDataSources'),
    getInitialSectionFields: this.generateDBQuery('getInitialSectionFields'),
    getDataSourceSection: this.generateDBQuery('getDataSourceSection'),
    getCategoryValues: this.generateDBQuery('getCategoryValues'),
    getDataSourceSectionsForDataSource: this.generateDBQuery('getDataSourceSectionsForDataSource'),
    queryDataPoints: this.generateDBQuery('queryDataPoints')
  };

  baseOptions = {
    retry: (failureCount: number, error: Error) => error instanceof RPCCancelError,
    networkMode: 'always' // So we always hit up workers, even when offline
  } as const;

  getQueryOptions = {
    getDashboard: (dashboardId: string | null) => {
      return queryOptions({
        ...this.baseOptions,
        queryKey: ['db', 'getDashboard', dashboardId],
        queryFn: () => {
          if (!dashboardId) throw new Error('Dashboard ID required');
          return this.queries['getDashboard'](dashboardId);
        },
        enabled: Boolean(dashboardId)
      });
    },
    getAllDashboards: () =>
      queryOptions({
        ...this.baseOptions,
        queryKey: ['db', 'getAllDashboards'],
        queryFn: () => this.queries['getAllDashboards']()
      }),
    getDataSource: (dataSourceId: string) => {
      return queryOptions({
        ...this.baseOptions,
        queryKey: ['db', 'getDataSource', dataSourceId],
        queryFn: () => {
          if (!dataSourceId) throw new Error('DataSource ID required');
          return this.queries['getDataSource'](dataSourceId);
        },
        enabled: Boolean(dataSourceId)
      });
    },
    getUserFile: (id: string) => {
      return queryOptions({
        ...this.baseOptions,
        queryKey: ['db', 'getUserFile', id],
        queryFn: () => {
          if (!id) throw new Error('UserFile ID required');
          return this.queries['getUserFile'](id);
        },
        enabled: Boolean(id)
      });
    },
    getAllDataSources: () =>
      queryOptions({
        ...this.baseOptions,
        queryKey: ['db', 'getAllDataSources'],
        queryFn: () => this.queries['getAllDataSources']()
      }),

    getCategoryValues: (sectionId: string | null, fieldName: string | null) => {
      return queryOptions({
        ...this.baseOptions,
        queryKey: ['db', 'getCategoryValues', sectionId, fieldName],
        queryFn: () => {
          if (!sectionId || !fieldName) throw new Error('Section ID and Field Name required');
          return this.queries['getCategoryValues'](sectionId, fieldName);
        },
        enabled: Boolean(sectionId && fieldName)
      });
    },
    getInitialSectionFields: (sectionId: string) => {
      return queryOptions({
        ...this.baseOptions,
        queryKey: ['db', 'getInitialSectionFields', sectionId],
        queryFn: () => {
          if (!sectionId) throw new Error('Section ID required');
          return this.queries['getInitialSectionFields'](sectionId);
        },
        enabled: Boolean(sectionId)
      });
    },
    getDataSourceSection: (dataSourceSectionId: string | null) => {
      return queryOptions({
        ...this.baseOptions,
        queryKey: ['db', 'getDataSourceSection', dataSourceSectionId],
        queryFn: () => {
          if (!dataSourceSectionId) throw new Error('DataSourceSection ID required');
          return this.queries['getDataSourceSection'](dataSourceSectionId);
        },
        enabled: Boolean(dataSourceSectionId)
      });
    },
    getDataSourceSectionsForDataSource: (dataSourceId: string | null) => {
      return queryOptions({
        ...this.baseOptions,
        queryKey: ['db', 'getDataSourceSectionsForDataSource', dataSourceId],
        queryFn: () => {
          if (!dataSourceId) throw new Error('DataSource ID required');
          return this.queries['getDataSourceSectionsForDataSource'](dataSourceId);
        },
        enabled: Boolean(dataSourceId)
      });
    },
    queryDataPoints: ({
      layerConfig,
      unitPreferences,
      pageIndex = 0,
      pageSize = 2500,
      defaultDir = 'DESC',
      selected,
      order
    }: {
      layerConfig: [BaseLayerConfig, ...LayerConfig[]] | null;
      unitPreferences: UnitPreferences;
      pageIndex?: number;
      pageSize?: number;
      defaultDir?: 'ASC' | 'DESC';
      selected: string[] | 'all';
      order: DataFetchOrder[] | null;
    }) => {
      return queryOptions({
        ...this.baseOptions,
        placeholderData(prevData, prevQuery) {
          /**
           * Keep previous data for queryDataPoints if the layer config is the same
           * but just pagination or sorting has changed. This helps with re-renders and weird flashing
           */
          const prevLayerConfig = (prevQuery?.queryKey[2] as any)?.layerConfig;
          // Custom equal check for layer configs that ignores fields that don't matter as much to the underlying data
          const isBasicallyEqual = isEqualWith(layerConfig, prevLayerConfig, (a, b, prop) => {
            if (prop === 'aggregateFields') return true; // Don't worry about aggregate fields changing
            return undefined;
          });
          return isBasicallyEqual ? keepPreviousData(prevData) : undefined;
        },
        enabled: Boolean(layerConfig),
        queryKey: [
          'db',
          'queryDataPoints',
          layerConfig,
          unitPreferences,
          pageIndex,
          pageSize,
          defaultDir,
          selected,
          order
        ],
        queryFn: () => {
          if (!layerConfig) throw new Error('LayerConfig required');
          return this.queries['queryDataPoints']({
            layerConfig,
            unitPreferences,
            pageIndex,
            pageSize,
            defaultDir,
            selected,
            order
          });
        }
      });
    }
  };

  /**
   * Calls needed to implement
   */
  getAuthToken = async () => {
    const session = this.store.get(authSessionAtom);
    return session?.getToken() ?? null;
  };
}
