import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from 'axil-web-ui';

import { useAtomValue, useSetAtom } from 'jotai';
import { MaximizeIcon, MoreVerticalIcon, PenIcon, Trash } from 'lucide-react';
import React from 'react';
import { isSmallScreenSizeAtom, updateDialogAtom, dashboardAtoms, widgetAtoms } from 'src/atoms';
import { Dashboard } from 'src/types/entities';

interface TitleBarProps {
  dashboard: Dashboard;
  allowEdits?: boolean;
}

function TitleBar({ dashboard, allowEdits }: TitleBarProps) {
  const updateDialog = useSetAtom(updateDialogAtom);
  const deleteDashboard = useSetAtom(dashboardAtoms.deleteDashboard);
  const editLayout = useSetAtom(dashboardAtoms.editLayout);
  const goFullScreen = useSetAtom(dashboardAtoms.goFullScreen);
  const addWidget = useSetAtom(widgetAtoms.startAddingWidget);
  const canOpenStandalone = useAtomValue(dashboardAtoms.canOpenStandaloneDashboard);
  const openStandaloneDashboard = useSetAtom(dashboardAtoms.openStandaloneDashboard);
  const mobileMode = useAtomValue(isSmallScreenSizeAtom);
  return (
    <div className="dark:bg-base-300 dark:shadow-3xl flex shrink-0 flex-col border-b-2 p-4 shadow-md dark:border-b-0">
      <div className="flex flex-row items-center justify-between gap-2 sm:gap-8">
        <h3 className="group flex min-w-0 shrink items-center gap-2 text-2xl font-bold">
          <span className="inline-block shrink overflow-hidden text-ellipsis whitespace-nowrap">
            {dashboard.name}
          </span>
          {!mobileMode ? (
            <Button
              ghost
              square
              size="sm"
              className="ml-1 opacity-0 transition-opacity duration-200 ease-in-out group-hover:opacity-100"
              onClick={() =>
                updateDialog({
                  type: 'UpdateDashboard',
                  payload: dashboard
                })
              }>
              <PenIcon size={'1rem'} />
            </Button>
          ) : null}
        </h3>
        <div className="flex shrink-0 grow-0 flex-row items-center justify-between gap-4">
          <Button
            onClick={() => addWidget(dashboard.id)}
            color="primary"
            size={mobileMode ? 'sm' : 'md'}>
            Add Widget
          </Button>
          {!mobileMode ? (
            <>
              {allowEdits ? (
                <Button onClick={() => editLayout()} color="accent">
                  Edit Layout
                </Button>
              ) : null}
              <Button onClick={() => goFullScreen()} square>
                <MaximizeIcon />
              </Button>
            </>
          ) : null}
          <DropdownMenu>
            <DropdownMenuContent align="end">
              {mobileMode ? (
                <DropdownMenuItem
                  onClick={() => updateDialog({ type: 'UpdateDashboard', payload: dashboard })}>
                  <div className="flex flex-row items-center gap-2">
                    <PenIcon />
                    <div>Edit Name</div>
                  </div>
                </DropdownMenuItem>
              ) : null}
              {allowEdits && mobileMode ? (
                <DropdownMenuItem onClick={() => editLayout()}>
                  <div className="flex flex-row items-center gap-2">
                    <PenIcon />
                    <div>Edit Layout</div>
                  </div>
                </DropdownMenuItem>
              ) : null}
              {mobileMode ? (
                <DropdownMenuItem onClick={() => goFullScreen()}>
                  <div className="flex flex-row items-center gap-2">
                    <MaximizeIcon />
                    <div>Full Screen</div>
                  </div>
                </DropdownMenuItem>
              ) : null}
              {canOpenStandalone ? (
                <DropdownMenuItem onClick={() => openStandaloneDashboard(dashboard.id)}>
                  <div className="flex flex-row items-center gap-2">
                    <MaximizeIcon />
                    <div>Open in New Window</div>
                  </div>
                </DropdownMenuItem>
              ) : null}

              <DropdownMenuItem onClick={() => deleteDashboard(dashboard.id)}>
                <div className="flex flex-row items-center gap-2">
                  <Trash />
                  <div>Delete</div>
                </div>
              </DropdownMenuItem>
            </DropdownMenuContent>

            <DropdownMenuTrigger>
              <div>
                <MoreVerticalIcon />
              </div>
            </DropdownMenuTrigger>
          </DropdownMenu>
        </div>
      </div>
    </div>
  );
}

export default TitleBar;
