import { useForm } from '@tanstack/react-form';
import { Button, TextInput } from 'axil-web-ui';
import React from 'react';
import { Dashboard } from 'src/types/entities';
import { getFieldDisplayErrors } from 'src/utils/form';
import { v4 as uuid } from 'uuid';
import { z } from 'zod';
import SubmitButton from '../common/forms/SubmitButton';
import { useSetAtom } from 'jotai';
import { dashboardAtoms } from 'src/atoms';

interface DashboardFormProps {
  update?: boolean;
  initialValues?: Dashboard;
  onCancel?: () => void;
  onSuccess?: () => void;
}
const schema = z.object({
  name: z.string().min(1, 'Required'),
  widgets: z.array(z.any()).nullable().default([]),
  sortWeight: z.string().nullish()
});

function DashboardForm({
  onCancel,
  onSuccess,
  initialValues,
  update: isUpdate
}: DashboardFormProps) {
  const create = useSetAtom(dashboardAtoms.createDashboard);
  const update = useSetAtom(dashboardAtoms.updateDashboard);
  const form = useForm({
    defaultValues: initialValues,
    validators: { onChange: schema },
    async onSubmit({ value }) {
      const mutation = isUpdate ? update : create;
      const id = initialValues?.id ?? uuid();
      await mutation({
        ...value,
        widgets: value.widgets ?? [],
        sortWeight: value.sortWeight ?? null,
        id
      });
      onSuccess?.();
    }
  });
  return (
    <div className="p-8">
      <h3 className="mb-4 text-2xl">
        {isUpdate ? `Update ${initialValues?.name || 'Dashboard'}` : 'Create a Dashboard'}
      </h3>
      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
        noValidate>
        <form.Field name="name">
          {field => (
            <TextInput
              label="Name"
              name={field.name}
              required
              error={getFieldDisplayErrors(field)}
              value={field.state.value ?? ''}
              onChange={e => field.handleChange(e.target.value)}
              onBlur={field.handleBlur}
            />
          )}
        </form.Field>
        <div className="flex flex-row justify-between gap-4">
          <SubmitButton form={form} label={isUpdate ? 'Update' : 'Submit'} />
          {onCancel ? (
            <Button color="secondary" onClick={onCancel}>
              Cancel
            </Button>
          ) : null}
        </div>
        {/* {formik.status?.type === 'SubmissionError' ? (
          <div className="flex flex-row">
            <span className="text-status-error font-bold">{formik.status?.message}</span>
          </div>
        ) : null} */}
      </form>
    </div>
  );
}

export default DashboardForm;
