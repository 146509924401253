import * as Sentry from '@sentry/react';
import { useLocation } from '@tanstack/react-router';
import React, { useEffect, useRef } from 'react';

function ErrorBoundary({ children }: { children?: React.ReactNode }) {
  const errorBoundaryRef = useRef<Sentry.ErrorBoundary>(null);
  const location = useLocation();
  // If the path changes, attempt to reset the error boundary so you aren't stuck on a busted screen
  useEffect(() => {
    errorBoundaryRef.current?.resetErrorBoundary();
  }, [location]);
  return (
    <Sentry.ErrorBoundary
      ref={errorBoundaryRef}
      fallback={
        <div className="mx-auto w-full max-w-max self-center p-16">
          <h1>Something went wrong</h1>
          <p>We have been notified and will fix it as soon as possible</p>
        </div>
      }>
      {children}
    </Sentry.ErrorBoundary>
  );
}

export default ErrorBoundary;

// Old error boundary code here in case we decide to not go with Sentry

// class ErrorBoundary extends Component<Record<string, unknown>, { hasError: boolean }> {
//   state = {
//     hasError: false
//   };

//   componentDidCatch(error: Error, info: any) {
//     // Display fallback UI
//     this.setState({ hasError: true });
//     console.error({ info }, 'Error Boundary Error');
//     console.error(error);
//   }

//   render() {
//     if (this.state.hasError) {
//       return (

//       );
//     }
//     return this.props.children;
//   }
// }

// export default ErrorBoundary;
