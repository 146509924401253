import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import WebFont from 'webfontloader';

import ThemeProvider from './ThemeProvider';

import { Provider as JotaiProvider, useAtom, useSetAtom, useStore } from 'jotai';
import { queryClientAtom } from 'jotai-tanstack-query';
import { useHydrateAtoms } from 'jotai/react/utils';

import { QueryClient } from '@tanstack/react-query';
import { Router, RouterProvider } from '@tanstack/react-router';
import createRootStore, {
  coreClientAtom,
  effectRegistrationAtom,
  pageSizeAtom,
  routingAtom,
  standaloneAtom,
  StandalonePayload,
  Store,
  themeAtom
} from 'src/atoms';
import { CoreClientContext, useCoreClient } from 'src/context';
import CoreClient from 'src/core/core.client';
import { isSharedWorkerSupported, isTauriEnv } from 'src/environment';
import { useTopLevelTheme } from 'src/hooks';
import { queryClient } from 'src/queryClient';
import persister from '../queryPersister';
import {
  getMainRouter as defaultGetRouter,
  getMainRouter,
  getStandaloneDashboardRouter
} from './routes';

export const useLoadWebFonts = () => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Open+Sans:ital,wght@0,300..800;1,300..800']
      },
      classes: false,
      active: () => setLoaded(true)
    });
  }, []);
  return loaded;
};

// Use a component to prevent overall re-renders
const SyncAtoms = () => {
  const theme = useTopLevelTheme();
  const coreClient = useCoreClient();
  useHydrateAtoms([
    [queryClientAtom, queryClient],
    [coreClientAtom, coreClient],
    [themeAtom, null]
  ]);
  const setTheme = useSetAtom(themeAtom);
  useLayoutEffect(() => setTheme(theme), [theme]);

  // Kicks off effects for jotai
  useAtom(effectRegistrationAtom);

  return null;
};

// Common Root used for the primary Root, testing, and the standalone root
function MainRootProvider({ children }: { children: React.ReactNode }) {
  const fontsLoaded = useLoadWebFonts();
  const [persistenceSuccess, setPersistenceSuccess] = useState(false);
  const store = useMemo(() => createRootStore(), []);
  // Will be automatically initialized and closed as part of the routing tree
  const coreClient = useMemo(() => {
    return new CoreClient(
      store,
      isTauriEnv
        ? 'broadcast-channel'
        : isSharedWorkerSupported()
          ? 'shared-worker'
          : 'worker-with-election'
    );
  }, [store]);
  const initialized = fontsLoaded && persistenceSuccess;
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{
        persister,
        dehydrateOptions: {
          shouldDehydrateQuery(query) {
            if (!query.options.queryKey) return false;
            // Helps to prevent issues with authSync (i.e. '/user/me' queries)
            if (query.options.gcTime === 0) return false;
            // Don't persist DB queries since they persist to the local DB instead
            if (query.options.queryKey?.[0] === 'db') return false;
            if (query.state.status !== 'success') return false;
            return true;
          }
        }
      }}
      onSuccess={() => setPersistenceSuccess(true)}>
      <JotaiProvider store={store}>
        <CoreClientContext.Provider value={coreClient}>
          <ThemeProvider>
            {initialized ? children : null}
            <SyncAtoms />
          </ThemeProvider>
        </CoreClientContext.Provider>
      </JotaiProvider>
    </PersistQueryClientProvider>
  );
}

function RouterRoot({
  getRouter = defaultGetRouter,
  standalonePayload = null
}: {
  getRouter?: (
    queryClient: QueryClient,
    coreClient: CoreClient | null,
    store: Store
  ) => Router<any, any, any>;
  standalonePayload?: StandalonePayload | null;
}) {
  const coreClient = useCoreClient();
  const store = useStore();
  const router = useMemo(
    () => getRouter(queryClient, coreClient, store),
    [queryClient, coreClient, store]
  );
  useHydrateAtoms([
    [routingAtom, router],
    [standaloneAtom, standalonePayload]
  ]);
  return <RouterProvider router={router} context={{ queryClient, coreClient, store }} />;
}

// Allow custom routers for testing
function Root(): JSX.Element {
  return (
    <MainRootProvider>
      <RouterRoot getRouter={getMainRouter} />
    </MainRootProvider>
  );
}

export const StandaloneRoot = ({ payload }: { payload: StandalonePayload }) => {
  return (
    <MainRootProvider>
      <RouterRoot
        standalonePayload={payload}
        getRouter={(...args) => {
          if (payload.type === 'dashboard')
            return getStandaloneDashboardRouter(payload.params[0], ...args);

          throw new Error('Unknown standalone type');
        }}
      />
    </MainRootProvider>
  );
};

export default Root;
