import { useForm, useStore } from '@tanstack/react-form';
import { Select, TextInput } from 'axil-web-ui';

import {
  AbsoluteDateValue,
  NowDateValue,
  RelativeDateValue,
  relativeDataFilterSchema as schema
} from 'daydash-data-structures';
import React, { useMemo } from 'react';
import SubmitButton from 'src/components/common/forms/SubmitButton';
import { getFieldDisplayErrors } from 'src/utils/form';
import { z } from 'zod';

type DateRangeValue = NowDateValue | RelativeDateValue | AbsoluteDateValue;

interface QuickPickerProps {
  initialValue: DateRangeValue | null | undefined;
  onSelect: (val: DateRangeValue) => void | Promise<any>;
}

function QuickPicker({ initialValue, onSelect }: QuickPickerProps) {
  const parsed = schema.safeParse(initialValue);
  const form = useForm({
    defaultValues: parsed.success ? parsed.data : ({ type: 'relative', dir: 'before' } as any),
    validators: { onChange: schema },
    onSubmit: ({ value }) => onSelect(schema.parse(value))
  });
  const dir = useStore(form.store, s => s.values.dir);
  const amount = useStore(form.store, s => s.values.amount);
  const options = useMemo(() => {
    const dirLabel = dir === 'before' ? 'ago' : 'from now';
    return [
      { unit: 'year', labelPlural: `Years ${dirLabel}`, labelSingular: `Year ${dirLabel}` },
      { unit: 'month', labelPlural: `Months ${dirLabel}`, labelSingular: `Month ${dirLabel}` },
      { unit: 'week', labelPlural: `Weeks ${dirLabel}`, labelSingular: `Week ${dirLabel}` },
      { unit: 'day', labelPlural: `Days ${dirLabel}`, labelSingular: `Day ${dirLabel}` },
      { unit: 'hour', labelPlural: `Hours ${dirLabel}`, labelSingular: `Hour ${dirLabel}` },
      { unit: 'minute', labelPlural: `Minutes ${dirLabel}`, labelSingular: `Minute ${dirLabel}` }
    ];
  }, [dir]);
  return (
    <div className="shrink-0">
      <h3 className="mb-4 text-lg">Quick Select</h3>
      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
        noValidate>
        <div className="flex flex-row gap-2">
          <form.Field name="amount">
            {field => (
              <TextInput
                label={null}
                required
                name={field.name}
                type="number"
                error={getFieldDisplayErrors(field)}
                value={field.state.value}
                onChange={e => field.handleChange(e.target.valueAsNumber)}
                onBlur={field.handleBlur}
              />
            )}
          </form.Field>
          <form.Field name="dateUnit">
            {field => (
              <Select
                className="min-w-max"
                label={null}
                options={options}
                name={field.name}
                value={options.find(o => o.unit === field.state.value) ?? null}
                getOptionLabel={o => (amount === 1 ? o.labelSingular : o.labelPlural)}
                onChange={o => field.handleChange(o?.unit as any)}
              />
            )}
          </form.Field>
          <SubmitButton form={form} label="Submit" />
        </div>
      </form>
    </div>
  );
}

export default QuickPicker;
