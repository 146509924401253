import React from 'react';
import StandardTitle from './StandardTitle';

export type FeedbackMessageType =
  | 'no-data-found'
  | 'data-source-broken'
  | 'widget-not-valid'
  | 'server-error'
  | 'offline';
interface WidgetFeedbackMessagingProps {
  title: string | null;
  type: FeedbackMessageType;
}

const typeToMessage: Record<FeedbackMessageType, JSX.Element> = {
  'no-data-found': <p>No data found</p>,
  'data-source-broken': <p>Data Source broken</p>,
  'widget-not-valid': <p>Widget is currently busted. Edit to fix</p>,
  'server-error': <p>Something went wrong. Please try again later</p>,
  offline: <p>You are currently offline</p>
};

export default function WidgetMessagingOverride({ title, type }: WidgetFeedbackMessagingProps) {
  return (
    <div className="flex h-full flex-col overflow-auto">
      <div className="mx-auto p-16">{typeToMessage[type]}</div>
    </div>
  );
}
