import { DeepKeys, ReactFormExtendedApi } from '@tanstack/react-form';
import { createContext, useContext } from 'react';
import { DataSourceFormValues } from 'src/atoms/dataSourceForm.atoms';

export type DataSourceFormPath = DeepKeys<DataSourceFormValues>;

export const DataSourceFormContext =
  createContext<ReactFormExtendedApi<DataSourceFormValues> | null>(null);

export const useDataSourceForm = () => {
  const form = useContext(DataSourceFormContext);
  if (!form) throw new Error('useWidgetForm must be used within a WidgetFormProvider');
  return form;
};
