import { Button, Loading } from 'axil-web-ui';
import React from 'react';
import { useStore } from '@tanstack/react-store';
import { FormApi } from '@tanstack/react-form';

export interface SubmitButtonProps {
  form: FormApi<any>;
  label: string;
}

export default function SubmitButton({ form, label }: SubmitButtonProps) {
  // Allow the first submit attempt to go through even if the form is invalid so the user can see the errors
  const submitEnabled = useStore(form.store, s => s.submissionAttempts === 0 || s.canSubmit);
  const isSubmitting = useStore(form.store, s => s.isSubmitting);
  return (
    <Button color="primary" type="submit" disabled={!submitEnabled || isSubmitting}>
      {isSubmitting ? <Loading /> : label}
    </Button>
  );
}
