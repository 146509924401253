import { ToggleGroup } from 'axil-web-ui';
import { fieldTypeToDescs } from 'daydash-unit-descs';

import { useForm } from '@tanstack/react-form';
import { Units } from 'daydash-data-structures';
import React, { useEffect, useMemo } from 'react';
import { z } from 'zod';

import { useAtomValue, useSetAtom } from 'jotai';
import { currentUserAtom, updateUserAtom } from 'src/atoms/user.atoms';
import SubmitButton from '../common/forms/SubmitButton';
import { userUpdateSchema } from './common';

function UserPreferences() {
  const currentUser = useAtomValue(currentUserAtom);
  const updateUser = useSetAtom(updateUserAtom);
  const defaultValues = useMemo(() => userUpdateSchema.parse(currentUser), [currentUser]);
  const form = useForm({
    defaultValues: defaultValues,
    validators: { onChange: userUpdateSchema },
    onSubmit: ({ value }) => updateUser(userUpdateSchema.parse(value))
  });
  useEffect(() => {
    form.reset();
  }, [currentUser]);
  return (
    <div className="mx-auto w-full max-w-2xl py-4">
      <h2 className="mb-8 text-2xl">Unit Preferences</h2>
      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
        noValidate>
        <div className="mb-8">
          <form.Field name="preferences.units.mass">
            {field => (
              <ToggleGroup
                optionContainerClassName="w-48"
                name={field.name}
                label="Mass"
                value={field.state.value ?? null}
                options={[
                  { value: Units.Mass.lb, label: fieldTypeToDescs['mass'].unitLabels.lb },
                  { value: Units.Mass.kg, label: fieldTypeToDescs['mass'].unitLabels.kg }
                ]}
                onSelect={val => field.handleChange(val as Units.Mass)}
              />
            )}
          </form.Field>
          <form.Field name="preferences.units.distance">
            {field => (
              <ToggleGroup
                optionContainerClassName="w-48"
                name={field.name}
                label="Distance"
                value={field.state.value ?? null}
                options={[
                  { value: Units.Distance.mi, label: fieldTypeToDescs['distance'].unitLabels.mi },
                  { value: Units.Distance.km, label: fieldTypeToDescs['distance'].unitLabels.km }
                ]}
                onSelect={val => field.handleChange(val as Units.Distance)}
              />
            )}
          </form.Field>
          <form.Field name="preferences.units.temperature">
            {field => (
              <ToggleGroup
                optionContainerClassName="w-48"
                name={field.name}
                label="Temperature"
                value={field.state.value ?? null}
                options={[
                  {
                    value: Units.Temperature.F,
                    label: fieldTypeToDescs['temperature'].unitLabels.F
                  },
                  {
                    value: Units.Temperature.C,
                    label: fieldTypeToDescs['temperature'].unitLabels.C
                  }
                ]}
                onSelect={val => field.handleChange(val as Units.Temperature)}
              />
            )}
          </form.Field>
          <form.Field name="preferences.units.energy">
            {field => (
              <ToggleGroup
                optionContainerClassName="w-48"
                name={field.name}
                label="Energy"
                value={field.state.value ?? null}
                options={[
                  { value: Units.Energy.kcal, label: fieldTypeToDescs['energy'].unitLabels.kcal },
                  { value: Units.Energy.kJ, label: fieldTypeToDescs['energy'].unitLabels.kJ }
                ]}
                onSelect={val => field.handleChange(val as Units.Energy)}
              />
            )}
          </form.Field>

          <form.Field name="preferences.units.liquid">
            {field => (
              <ToggleGroup
                optionContainerClassName="w-48"
                name={field.name}
                label="Liquid"
                value={field.state.value ?? null}
                options={[
                  { value: Units.Liquid.floz, label: fieldTypeToDescs['liquid'].unitLabels.floz },
                  { value: Units.Liquid.L, label: fieldTypeToDescs['liquid'].unitLabels.L }
                ]}
                onSelect={val => field.handleChange(val as Units.Liquid)}
              />
            )}
          </form.Field>
        </div>
        <SubmitButton form={form} label="Save" />
      </form>
    </div>
  );
}

export default UserPreferences;
