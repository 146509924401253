import { useAtomValue } from 'jotai';
import { CloudOff } from 'lucide-react';
import React from 'react';
import { onlineStatusAtom } from 'src/atoms';

function OfflineIndicator() {
  const online = useAtomValue(onlineStatusAtom);
  if (online) return null;
  return (
    <div className="pointer-events-auto fixed left-0 right-0 top-0 z-10 flex flex-row justify-center">
      <div className="bg-warning flex flex-row items-center rounded p-4 text-white">
        <div className="mr-4">
          <CloudOff size={24} />
        </div>
        <p className="m-0 max-w-full grow text-base">
          You are currently offline. Please check your connection
        </p>
      </div>
    </div>
  );
}

export default OfflineIndicator;
