import { atom } from 'jotai';
import { DashboardAtoms } from './dashboards.atoms';
import { DataSourceAtoms } from './dataSources.atoms';

export interface OnboardingState {
  isDataSourceCreated: boolean;
  isDashboardCreated: boolean;
  isDashboardWithWidgetCreated: boolean;
  isFullyOnboarded: boolean;
}

export class OnboardingAtoms {
  constructor(
    private dataSourceAtoms: DataSourceAtoms,
    private dashboardAtoms: DashboardAtoms
  ) {}

  private areDataSourcesLoading = atom(get => get(this.dataSourceAtoms.list).isLoading);

  private areDataSourcesPending = atom(get => get(this.dataSourceAtoms.list).isPending);

  private areDashboardsLoading = atom(get => get(this.dashboardAtoms.list).isLoading);

  private areDashboardsPending = atom(get => get(this.dashboardAtoms.list).isPending);

  private isDataSourceCreated = atom(get => {
    if (get(this.areDataSourcesLoading)) return null;
    const dataSourceList = get(this.dataSourceAtoms.list);
    return Boolean(dataSourceList.data && dataSourceList.data.length > 0);
  });

  private isDashboardCreated = atom(get => {
    if (get(this.areDashboardsLoading)) return null;
    const dashboardsList = get(this.dashboardAtoms.list);
    return Boolean(dashboardsList.data && dashboardsList.data.length > 0);
  });

  private isDashboardWithWidgetCreated = atom(get => {
    if (get(this.areDashboardsLoading)) return null;
    const dashboardsList = get(this.dashboardAtoms.list);
    return Boolean(dashboardsList.data && dashboardsList.data.find(d => d.widgets.length > 0));
  });

  isFullyOnboarded = atom(get => {
    if (get(this.areDataSourcesPending) || get(this.areDashboardsPending)) return null;
    return (
      get(this.isDataSourceCreated) &&
      get(this.isDashboardCreated) &&
      get(this.isDashboardWithWidgetCreated)
    );
  });

  onboardingState = atom(get => {
    const isDataSourceCreated = get(this.isDataSourceCreated);
    const isDashboardCreated = get(this.isDashboardCreated);
    const isDashboardWithWidgetCreated = get(this.isDashboardWithWidgetCreated);
    const isFullyOnboarded = get(this.isFullyOnboarded);
    if (
      isDataSourceCreated == null ||
      isDashboardCreated == null ||
      isDashboardWithWidgetCreated == null ||
      isFullyOnboarded == null
    ) {
      return null;
    }
    return {
      isDataSourceCreated,
      isDashboardCreated,
      isDashboardWithWidgetCreated,
      isFullyOnboarded
    };
  });

  percentComplete = atom(get => {
    const onboardingState = get(this.onboardingState);
    if (!onboardingState) return null;
    let completeCount = 0;
    if (onboardingState?.isDataSourceCreated) completeCount++;
    if (onboardingState?.isDashboardCreated) completeCount++;
    if (onboardingState?.isDashboardWithWidgetCreated) completeCount++;
    return completeCount / 3;
  });
}
