import {
  AddressAutofillFeatureSuggestion,
  AddressAutofillSuggestion
} from '@mapbox/search-js-core';
import { AddressMinimap, config, useAddressAutofillCore } from '@mapbox/search-js-react';
import { AddressMinimapProps } from '@mapbox/search-js-react/dist/components/AddressMinimap';
import { AsyncSelect } from 'axil-web-ui';
import { DataSourceBuilder } from 'daydash-data-structures';
import React, { useId, useRef, useState } from 'react';
import { getFieldDisplayErrors } from 'src/utils/form';
import { DataSourceFormPath, useDataSourceForm } from './context';

export interface LocationConfigurationProps {}

const accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

// Wrap it so we can fix React 17 type issue
const Minimap = (props: AddressMinimapProps) => React.createElement(AddressMinimap as any, props);

config.accessToken = accessToken;

const defaultMapStyle: [string, string] = ['axil-admin', 'clurwl3b001w901pb3s4bdnwf'];

type Option = AddressAutofillSuggestion | { full_address: string };
const isFullSuggestion = (suggestion: Option): suggestion is AddressAutofillSuggestion => {
  return 'match_code' in suggestion;
};

type LocationInputValue = NonNullable<
  DataSourceBuilder.ConnectorContentByType<'locationInput'>['default']
>;
export default function LocationConfiguration({
  content
}: {
  content: DataSourceBuilder.ConnectorContentByType<'locationInput'>;
}) {
  const form = useDataSourceForm();
  const [currentFeature, setCurrentFeature] = useState<AddressAutofillFeatureSuggestion | null>(
    null
  );
  const id = useId();
  const autofill = useAddressAutofillCore({
    accessToken
  });
  const sessionToken = `session-${id}`;
  const currentChangeAbortController = useRef<AbortController | null>(null);
  return (
    <div className="flex grow flex-col">
      <form.Field
        name={content.fieldName as DataSourceFormPath}
        validators={{
          onChange: ({ value }) => {
            if (!value && content.required) return 'Please select a location';
          }
        }}>
        {field => (
          <AsyncSelect<Option>
            cacheOptions
            className="w-96 max-w-full"
            loadOptions={async inputValue => {
              if (!inputValue) return [];
              return (await autofill.suggest(inputValue, { sessionToken })).suggestions;
            }}
            getOptionLabel={o => o.full_address ?? ''}
            getOptionValue={o => o.full_address ?? ''}
            label={content.label}
            error={getFieldDisplayErrors(field)}
            name={field.name}
            onBlur={field.handleBlur}
            isClearable
            onChange={async (selected: Option | null) => {
              if (currentChangeAbortController.current)
                currentChangeAbortController.current.abort();
              const address = selected?.full_address;
              if (!selected || !isFullSuggestion(selected) || !address) {
                field.handleChange(null);
                return;
              }
              const abortController = new AbortController();
              currentChangeAbortController.current = abortController;
              const fullAddress = await autofill.retrieve(selected, {
                sessionToken,
                signal: abortController.signal
              });
              const feature = fullAddress.features[0];
              field.handleChange(
                feature
                  ? ({
                      address,
                      latitude: feature.geometry.coordinates[1],
                      longitude: feature.geometry.coordinates[0]
                    } satisfies LocationInputValue)
                  : null
              );
              setCurrentFeature(feature);
            }}
            defaultValue={content.default ? { full_address: content.default?.address } : null}
          />
        )}
      </form.Field>
      <div className="min-h-48 w-full shrink grow">
        <Minimap
          satelliteToggle
          feature={currentFeature ?? undefined}
          show={Boolean(currentFeature)}
          defaultMapStyle={defaultMapStyle}
        />
      </div>
    </div>
  );
}
