import { atom } from 'jotai';
import { Dashboard } from 'src/types/entities';
import { routingAtom } from './history.atoms';

type GlobalDialogType =
  | 'CreateDashboard'
  | 'UpdateDashboard'
  | 'CreateDataSource'
  | 'AccountPreferences';

type GlobalDialogPayload = {
  CreateDashboard: null;
  UpdateDashboard: Dashboard;
  CreateDataSource: {
    initialConnector: string;
  } | null;
  AccountPreferences: null;
};

type GlobalDialogState<T extends GlobalDialogType> = T extends keyof GlobalDialogPayload
  ? GlobalDialogPayload[T] extends null | undefined
    ? { type: T; payload?: null }
    : { type: T; payload: GlobalDialogPayload[T] }
  : never;

export const dialogAtom = atom<GlobalDialogState<GlobalDialogType> | null>(null);

export const updateDialogAtom = atom(
  null,
  (get, set, newDialog: GlobalDialogState<GlobalDialogType>) => set(dialogAtom, newDialog)
);

export const closeDialogAtom = atom(null, (get, set) => set(dialogAtom, null));
