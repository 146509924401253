import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useMaintainPointerCaptures, useStorageNumber } from 'src/hooks';

/**
 * This uses a bi-directional sync where if the user is dragging,
 * the current css var takes precedence. If the user is not dragging,
 * the updated dim takes precedence
 */
const setCurrentDim = ({
  cssVarName,
  dim,
  minDim,
  maxDim
}: {
  cssVarName: string;
  dim: number;
  minDim?: number;
  maxDim?: number;
}) => {
  dim = Math.round(Math.min(Math.max(0, dim), window.innerWidth));
  if (minDim) dim = Math.max(minDim, dim);
  if (maxDim) dim = Math.min(maxDim, dim);
  document.documentElement.style.setProperty(cssVarName, `${dim}px`);
};

const cleanupCurrentDim = (cssVarName: string) => {
  document.documentElement.style.removeProperty(cssVarName);
};

const getCurrentDim = (cssVarName: string) => {
  const currentCSSValue = document.documentElement.style.getPropertyValue(cssVarName);
  const currentDim = parseInt(currentCSSValue, 10);
  return currentDim || null; // In case of NaN
};

// TODO: Add support for vertical resizing
const useCurrentDim = (
  defaultDim: number,
  cssVarName: string,
  persist: boolean
): [number, React.Dispatch<number>] => {
  const [transient, setTransient] = useState(defaultDim);
  const [persisted, setPersisted] = useStorageNumber(
    `resizeHandle:${cssVarName}`,
    defaultDim,
    persist
  );
  // Retrieve on mount and persist when dragging stops. Use useLayoutEffect to set the width before rendering
  if (persist) {
    return [persisted, setPersisted];
  }
  return [transient, setTransient];
};
export default function ResizeHandle({
  cssVarName,
  defaultDim,
  persist = true,
  minDim,
  maxDim
}: {
  cssVarName: string;
  persist?: boolean;
  defaultDim: number;
  minDim?: number;
  maxDim?: number;
}) {
  const [dim, setDim] = useCurrentDim(defaultDim, cssVarName, persist);
  // Whenever the dim changes, set it on the CSS var
  useLayoutEffect(() => {
    setCurrentDim({ dim, minDim, maxDim, cssVarName });
  }, [dim, cssVarName, minDim, maxDim]);

  const [dragging, setDragging] = useState(false);
  // Sync the cursor style to the body since we can't rely on hover of the resize handle if the mouse is too far away
  useEffect(() => {
    if (!dragging) return () => true;
    document.body.style.setProperty('cursor', 'col-resize');
    return () => {
      document.body.style.removeProperty('cursor');
    };
  }, [dragging]);

  const handlePointerDown = useCallback<React.PointerEventHandler<HTMLDivElement>>(evt => {
    setDragging(true);
  }, []);
  // Once dragging finishes, sync the current dim set during dragging with the actual state value
  useEffect(() => {
    if (!dragging) {
      const updatedDim = getCurrentDim(cssVarName);
      if (updatedDim) setDim(updatedDim);
    }
  }, [dragging, cssVarName]);
  // Don't let the css property setting persist after unmounting
  useLayoutEffect(() => {
    return () => {
      cleanupCurrentDim(cssVarName);
    };
  }, [cssVarName]);
  useEffect(() => {
    if (!dragging) return;
    let frame: number | null = null;
    let initialClientX: number | null = null;
    let currentClientX: number | null = null;
    const currentDim = getCurrentDim(cssVarName) || defaultDim;
    function handlePointerMove(evt: PointerEvent) {
      if (initialClientX == null) initialClientX = evt.clientX;
      currentClientX = evt.clientX;
      if (frame) cancelAnimationFrame(frame);
      frame = requestAnimationFrame(() => {
        if (initialClientX && currentClientX) {
          setCurrentDim({
            cssVarName,
            minDim,
            maxDim,
            dim: currentDim + currentClientX - initialClientX
          });
        }
        frame = null;
      });
    }
    function handleEnd(evt: PointerEvent) {
      setDragging(false);
    }
    document.addEventListener('pointermove', handlePointerMove);
    document.addEventListener('pointerup', handleEnd);
    document.addEventListener('pointercancel', handleEnd);
    return () => {
      if (frame) cancelAnimationFrame(frame);
      document.removeEventListener('pointermove', handlePointerMove);
      document.removeEventListener('pointerup', handleEnd);
      document.removeEventListener('pointercancel', handleEnd);
    };
  }, [dragging]);
  const elementRef = useRef<HTMLDivElement>(null);
  useMaintainPointerCaptures(elementRef);
  const dragHandleSize = 4;
  return (
    <div
      className="bg-primary absolute bottom-0 top-0 z-20 cursor-col-resize opacity-0 transition-opacity hover:opacity-100"
      ref={elementRef}
      style={{
        right: (-1 * dragHandleSize) / 2,
        width: dragHandleSize,
        opacity: dragging ? 1 : undefined // Override opacity while dragging
      }}
      onPointerDown={handlePointerDown}
      onMouseDown={evt => evt.preventDefault()}
    />
  );
}
