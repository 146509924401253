import { assertNever } from 'axil-utils';
import { Duration, add, sub } from 'date-fns';
import {
  DataSourceConfigFilter,
  DateRangeDataSourceFilter,
  DateUnit
} from 'daydash-data-structures';
import { Knex, QueryBuilder } from 'knex';

const getDateFnsDurationKeyFromRelativeDateUnit = (dateUnit: DateUnit): keyof Duration => {
  switch (dateUnit) {
    case 'year':
      return 'years';
    case 'month':
      return 'months';
    case 'week':
      return 'weeks';
    case 'day':
      return 'days';
    case 'hour':
      return 'hours';
    case 'minute':
      return 'minutes';
  }
};
const resolveDataRangeValue = (value: DateRangeDataSourceFilter['value']) => {
  if (value.type === 'now') return Date.now();
  if (value.type === 'absolute') return new Date(value.date).getTime();
  if (value.type === 'relative') {
    const durationUnit = getDateFnsDurationKeyFromRelativeDateUnit(value.dateUnit);
    if (value.dir === 'before') {
      return sub(new Date(), {
        [durationUnit]: value.amount
      }).getTime();
    }
    if (value.dir === 'after') {
      return add(new Date(), {
        [durationUnit]: value.amount
      }).getTime();
    }
    assertNever(value.dir);
  }
  assertNever(value);
  throw new Error('O no!');
};

export const populateFilters = (
  filter: DataSourceConfigFilter,
  builder: Knex.QueryBuilder
): QueryBuilder => {
  if (filter.type === 'dateRange') {
    if (filter.operation === 'after')
      return builder.where(filter.fieldName, '>=', resolveDataRangeValue(filter.value));
    if (filter.operation === 'before')
      return builder.where(filter.fieldName, '<=', resolveDataRangeValue(filter.value));
    assertNever(filter.operation);
  }
  if (filter.type === 'numeric') {
    // You can just pass these right on in
    return builder.where(filter.fieldName, filter.operation, filter.value);
  }
  if (filter.type === 'string') {
    if (filter.operation === 'starts-with')
      return builder.where(filter.fieldName, 'like', `${filter.value}%`);
    if (filter.operation === 'not-starts-with')
      return builder.where(filter.fieldName, 'not like', `${filter.value}%`);
    if (filter.operation === 'ends-with')
      return builder.where(filter.fieldName, 'like', `%${filter.value}`);
    if (filter.operation === 'not-ends-with')
      return builder.where(filter.fieldName, 'not like', `%${filter.value}`);
    if (filter.operation === 'contains')
      return builder.where(filter.fieldName, 'like', `%${filter.value}%`);
    if (filter.operation === 'not-contains')
      return builder.where(filter.fieldName, 'not like', `%${filter.value}%`);
    if (filter.operation === '=') return builder.where(filter.fieldName, '=', filter.value);
    if (filter.operation === '<>') return builder.where(filter.fieldName, '<>', filter.value);
    assertNever(filter.operation);
  }
  if (filter.type === 'category') {
    if (filter.operation === 'in') return builder.whereIn(filter.fieldName, filter.value);
    if (filter.operation === 'not-in') return builder.whereNotIn(filter.fieldName, filter.value);
    assertNever(filter.operation);
  }
  if (filter.type === 'empty') {
    if (filter.operation === 'empty') return builder.whereNull(filter.fieldName);
    if (filter.operation === 'not-empty') return builder.whereNotNull(filter.fieldName);
    assertNever(filter.operation);
  }
  assertNever(filter);
  throw new Error('O no!');
};
