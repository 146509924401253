import { Button, Dialog, Loading } from 'axil-web-ui';
import { useAtomValue } from 'jotai';
import get from 'lodash/get';
import React, { Suspense, useEffect, useState } from 'react';
import { useWidgetForm } from 'src/components/Widget/WidgetForm/context';
import BaseLayerField from './BaseLayerField';
import { GroupingLayerField } from './GroupingLayerField';
import LayerVisualizer from './LayerVisualizer';
import { PreviewData } from './PreviewData';
import { useStore } from '@tanstack/react-store';
import { widgetFormAtoms } from 'src/atoms';

function DataSourceField() {
  const fieldPath = useAtomValue(widgetFormAtoms.dataSourceFieldPath);
  if (!fieldPath) throw new Error('No field path found');
  const form = useWidgetForm();
  const currentLayers = useStore(form.store, s => get(s.values, `${fieldPath}.layers`));
  useEffect(() => {
    if (!currentLayers) {
      form.setFieldValue(`${fieldPath}.layers`, []);
    }
  }, [fieldPath]);

  const [selectedLayer, setSelectedLayer] = useState(
    currentLayers && currentLayers.length > 0 ? currentLayers.length - 1 : 0
  );
  const [previewingData, setPreviewingData] = useState(false);
  const handleAddLayer = () => {
    form.pushFieldValue(`${fieldPath}.layers` as any, {});
    setSelectedLayer(currentLayers?.length ?? 1);
  };
  const removeLayer = () => {
    // Remove all downstream layers as well. Maybe add a prompt
    form.setFieldValue(`${fieldPath}.layers`, currentLayers?.slice(0, selectedLayer));
    setSelectedLayer(selectedLayer - 1);
  };
  useEffect(() => {
    if (currentLayers && selectedLayer != null && currentLayers.length <= selectedLayer)
      setSelectedLayer(Math.max(currentLayers.length - 1, 0));
  }, [currentLayers, selectedLayer]);

  return (
    <div className="h-full">
      <h2 className="text-center text-2xl">Data Configuration</h2>
      <div className="flex h-full w-full max-w-full grow flex-col gap-6 overflow-y-auto py-4 sm:px-12 sm:py-12 lg:flex-row lg:items-stretch">
        <div className="bg-base-100 relative flex flex-grow basis-1/2 flex-col justify-between gap-8 overflow-auto rounded-lg p-4 sm:p-8 lg:flex-grow lg:basis-96">
          <Suspense fallback={<Loading size={48} />}>
            {selectedLayer === 0 ? (
              <BaseLayerField dsFieldPath={fieldPath} />
            ) : (
              <GroupingLayerField
                key={selectedLayer}
                dsFieldPath={fieldPath}
                layerIdx={selectedLayer}
              />
            )}
          </Suspense>
          <div className="flex max-w-full flex-wrap gap-2">
            <Button onClick={handleAddLayer} color="secondary">
              Add Grouping Layer
            </Button>
            <Button onClick={() => setPreviewingData(true)} color="accent">
              Preview Data
            </Button>
            {selectedLayer > 0 && currentLayers ? (
              <Button
                onClick={() => removeLayer()}
                color="error"
                ghost
                disabled={selectedLayer !== currentLayers.length - 1}>
                Remove Layer
              </Button>
            ) : null}
          </div>
        </div>
        <div className="bg-base-100 flex-grow-[3] basis-1/2 overflow-y-auto rounded-lg p-4 lg:basis-96">
          <LayerVisualizer
            layers={currentLayers || null}
            selectedLayer={selectedLayer || 0}
            onSelectLayer={setSelectedLayer}
          />
        </div>
      </div>
      <Dialog
        isOpen={previewingData}
        onClose={() => setPreviewingData(false)}
        title="Data Preview"
        description="This is a preview of the data that will be used in the widget."
        contentClassName="max-h-fit">
        <Suspense
          fallback={
            <div className="flex w-full h-full items-center justify-center">
              <Loading size={48} />
            </div>
          }>
          {currentLayers ? <PreviewData layers={currentLayers} /> : null}
        </Suspense>
      </Dialog>
    </div>
  );
}

export default DataSourceField;
