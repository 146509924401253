import {
  Button,
  DataTable,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Select,
  TextInput
} from 'axil-web-ui';
import {
  AbstractDataField,
  DataSourceBuilder,
  FieldType,
  isNumericDataType
} from 'daydash-data-structures';
import { fieldTypeToDescs } from 'daydash-unit-descs';
import React, { useContext, useMemo, useState } from 'react';
import { useAbstractDataFieldTableColumns } from 'src/hooks/tables';
import { dataTypeConfig } from '../common/dataTypeConfig';
import { DataSourceFormPath, useDataSourceForm } from './context';
import { useStore } from '@tanstack/react-store';
import { get } from 'lodash';

export interface ColumnConfigurationProps {
  content: DataSourceBuilder.ConnectorContentByType<'columnConfiguration'>;
}

const fieldTypeOptions = Object.entries(dataTypeConfig).map(([type, cfg]) => {
  return {
    value: type as FieldType,
    label: cfg.label
  };
});

function ColumnConfigField({
  initialCfg,
  fieldName,
  onUpdated,
  lockedUnits
}: {
  initialCfg: DataSourceBuilder.ColumnConfigurationValue;
  fieldName: string;
  onUpdated?: () => void;
  lockedUnits?: DataSourceBuilder.ConnectorContentByType<'columnConfiguration'>['lockedUnits'];
}) {
  const form = useDataSourceForm();
  const value = useStore(
    form.store,
    s => get(s.values, fieldName) as DataSourceBuilder.ColumnConfigurationValue
  );
  const { columnKey } = value;
  const [label, setLabel] = useState(value.label);
  const [type, setType] = useState(value.type);
  const [unit, setUnit] = useState(value.unit);

  const unitOptions = type && !lockedUnits?.[type] ? dataTypeConfig[type].unitOptions : null;
  const handleApply = () => {
    form.setFieldValue(fieldName as DataSourceFormPath, {
      ...value,
      label,
      type: type as any, // Need casting here because we lose the thread on types with units
      unit: unit as any // Need casting here because we lose the thread on types with units
    });
    onUpdated?.();
  };

  return (
    <form
      className="flex shrink-0 flex-col"
      onSubmit={evt => {
        evt.preventDefault();
        handleApply();
      }}>
      <h4 className="m-0 whitespace-nowrap">{`Column: ${value.columnKey}`}</h4>
      <TextInput
        label="Label"
        name={`${columnKey}-label`}
        value={label}
        inputProps={{ autoFocus: true }}
        onChange={evt => setLabel(evt.target.value)}
      />
      <Select
        label="Data Type"
        name={`${columnKey}-type`}
        options={fieldTypeOptions}
        value={fieldTypeOptions.find(o => o.value === type)}
        onChange={selected => {
          const newType = selected?.value;
          let newUnit: DataSourceBuilder.ColumnConfigurationValue['unit'] | null = null;
          if (initialCfg?.type === newType && initialCfg?.unit) {
            newUnit = initialCfg?.unit; // Attempt to preserve the initial unit if switching to the initial type
          } else if (newType && lockedUnits && lockedUnits[newType]) {
            newUnit = lockedUnits[newType] ?? null;
          } else if (newType && isNumericDataType(newType)) {
            newUnit = fieldTypeToDescs[newType].getUnitLabelOptions()?.[0]?.value;
          }
          if (newType) {
            setType(newType);
            setUnit(newUnit);
          }
        }}
      />
      {unitOptions ? (
        <Select
          label="Unit Type"
          name={`${columnKey}-unit`}
          options={unitOptions}
          isDisabled={unitOptions.length === 1}
          isSearchable={unitOptions.length > 6}
          value={unitOptions.find(o => o.value === unit)}
          onChange={selected => setUnit(selected?.value ?? null)}
        />
      ) : null}
      <Button onClick={handleApply} type="submit" color="primary">
        Apply
      </Button>
    </form>
  );
}

const ColumnConfigurationContext = React.createContext<{
  content: DataSourceBuilder.ConnectorContentByType<'columnConfiguration'> | null;
}>({ content: null });

function ColumnDropDown({
  children,
  field: dataField
}: {
  children: JSX.Element;
  field: AbstractDataField;
}) {
  const { content } = useContext(ColumnConfigurationContext);
  if (!content) throw new Error('No content found in ColumnConfigurationContext');
  const { fieldName, default: defaultConfigVal, lockedUnits } = content;
  const form = useDataSourceForm();
  const [open, setOpen] = useState<boolean>(false);
  const initialCfg = defaultConfigVal.find(c => c.columnKey === dataField.name);
  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger>
        <Button color="accent" className="no-animation">
          {children}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {initialCfg ? (
          <form.Field name={fieldName as DataSourceFormPath}>
            {field => {
              const value = field.state.value as DataSourceBuilder.ColumnConfigurationValue[];
              const cfgIndex = value.findIndex(cfg => cfg.columnKey === dataField.name);
              return (
                <ColumnConfigField
                  fieldName={`${field.name}[${cfgIndex}]`}
                  initialCfg={initialCfg}
                  lockedUnits={lockedUnits}
                  onUpdated={() => setOpen(false)}
                />
              );
            }}
          </form.Field>
        ) : null}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default function ColumnConfiguration({ content }: ColumnConfigurationProps) {
  const { fieldName, preview } = content;
  const form = useDataSourceForm();
  const value = useStore(
    form.store,
    s => get(s.values, fieldName) as DataSourceBuilder.ColumnConfigurationValue[]
  );
  const abstractDataFieldsFromValue = useMemo<AbstractDataField[]>(() => {
    return value.map((cfg, idx) => ({
      name: String(cfg.columnKey),
      key: `${idx}`,
      label: cfg.label,
      type: cfg.type,
      meta: cfg.meta as any,
      unit: cfg.unit as any // Needed since we lose the thread here on types with units
    }));
  }, [value]);
  const columns = useAbstractDataFieldTableColumns(
    abstractDataFieldsFromValue ?? null,
    ColumnDropDown
  );
  return (
    <ColumnConfigurationContext.Provider value={{ content }}>
      <div className="flex min-h-0 w-full min-w-0 max-w-full shrink flex-col items-center gap-8">
        <p className="max-w-prose">
          Click column headers below to configure each column if needed. You can change the label,
          data type, unit type, and hide columns as necessary
        </p>
        <div className="relative min-h-0 w-full shrink grow overflow-auto">
          {columns ? (
            <DataTable columns={columns} data={preview ?? null} type="infinite-scroll" />
          ) : null}
        </div>
      </div>
    </ColumnConfigurationContext.Provider>
  );
}
