import { UseMutationResult, useQuery } from '@tanstack/react-query';
import isEqualWith from 'lodash/isEqualWith';
import sortBy from 'lodash/sortBy';
import { useEffect, useMemo } from 'react';
import { useCoreClient } from '../../context';
import { Dashboard } from '../../types/entities';
import { DashboardUpdatePayload } from '../../types/service';
import useUndo from '../use-undo';

export const useAllDashboards = () => {
  const coreClient = useCoreClient();
  return useQuery(coreClient.getQueryOptions.getAllDashboards());
};

export const useSortedDashboards = (unsorted: Dashboard[] = []) => {
  return useMemo(() => {
    return sortBy(
      unsorted,
      d => d.sortWeight,
      d => new Date(d.createdAt)
    );
  }, [unsorted]);
};

export const useDashboard = (id: string) => {
  const coreClient = useCoreClient();
  return useQuery(coreClient.getQueryOptions.getDashboard(id));
};

const dashboardContentEqual = (
  d1: Dashboard | null | undefined,
  d2: Dashboard | null | undefined
) => {
  return isEqualWith(d1, d2, (d1Val, d2Val, key) => {
    // Don't flag them as unequal if only the updated dates are different
    if (key === 'updatedAt' || key === 'localUpdatedAt') return true;
    return undefined;
  });
};

// TODO: Migrate undo/redo to Jotai
export function useDashboardUndo(
  current: Dashboard | null | undefined,
  onUpdateDashboard: (dashboard: Dashboard) => void
) {
  const [undoDashboardState, { set: setUndo, undo, redo, canUndo, canRedo, reset }] =
    useUndo(current);

  // Reset whenever the actual dashboard changes
  useEffect(() => {
    reset(current);
  }, [current?.id]);

  // Whenever the server state changes, set the undoDashboard state
  useEffect(() => {
    if (
      undoDashboardState.present?.id === current?.id &&
      !dashboardContentEqual(undoDashboardState.present, current)
    ) {
      setUndo(current);
    }
  }, [current]);
  // Whenever the undo dashboard state changes, update the current
  useEffect(() => {
    if (
      undoDashboardState.present &&
      undoDashboardState.present?.id === current?.id &&
      !dashboardContentEqual(undoDashboardState.present, current)
    ) {
      onUpdateDashboard(undoDashboardState.present);
    }
  }, [undoDashboardState.present]);
  return {
    undo,
    redo,
    canUndo,
    canRedo
  };
}
