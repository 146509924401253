import { RegisteredRouter, RouteIds } from '@tanstack/react-router';
import { isNotEmpty } from 'axil-utils';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { atom } from 'jotai';
import { atomWithMutation, atomWithQuery, queryClientAtom } from 'jotai-tanstack-query';
import { Dashboard } from 'src/types/entities';
import {
  _storeAtom,
  coreClientAtom,
  isStandaloneAtom,
  promptAtom,
  routeMatchesAtom,
  routingAtom,
  toastAtom,
  userIdAtom
} from '.';
import type { Command, CommandGroup } from './commands.atoms';
import { updateDialogAtom } from './dialogs.atoms';
import CoreClient from 'src/core/core.client';
import { QueryClient } from '@tanstack/react-query';
import { atomFamily } from 'jotai/utils';
import { withUndo } from 'jotai-history';
import { DashboardCreatePayload, DashboardUpdatePayload } from 'src/types/service';
import { Layout } from 'react-grid-layout';
import { isEqual } from 'lodash';
import { Widget } from 'daydash-data-structures';

type MutationContext = {
  previousDashboard: Dashboard | undefined;
  previousDashboards: Dashboard[] | undefined;
};
type SortWeights = Record<string, string | null>;

// TODO: Use zod to make this better
const isValidWidget = (widget: Widget) => {
  return Boolean(widget.id);
};

export class DashboardAtoms {
  private optimisticallyUpdateAllDashboards = async (
    updatedDashboards: (Partial<Dashboard> & { id: string })[],
    coreClient: CoreClient,
    queryClient: QueryClient
  ) => {
    const queryKey = coreClient.getQueryOptions.getAllDashboards().queryKey;
    // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries({ queryKey });
    // Snapshot the previous values
    const previousDashboards = queryClient.getQueryData<Dashboard[] | undefined>(queryKey);
    // Optimistically update to the new value
    const byId = new Map(updatedDashboards?.map(d => [d.id, d]) ?? []);
    queryClient.setQueryData<Dashboard[] | undefined>(queryKey, old => {
      if (!old) return;
      return old.map(d => {
        const updated = byId.get(d.id);
        if (updated) return { ...d, ...updated };
        return d;
      });
    });
    return previousDashboards;
  };

  private optimisticallyUpdateDashboard = async (
    updatedDashboard: Partial<Dashboard> & { id: string },
    userId: string,
    coreClient: CoreClient,
    queryClient: QueryClient
  ) => {
    const queryKey = coreClient.getQueryOptions.getDashboard(updatedDashboard.id).queryKey;
    // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries({ queryKey });
    // Snapshot the previous values
    const previousDashboard = queryClient.getQueryData<Dashboard | undefined>(queryKey);

    // Optimistically update to the new value
    queryClient.setQueryData<Dashboard | null>(queryKey, old => {
      if (!old) {
        if (updatedDashboard.name && updatedDashboard.widgets) {
          return {
            ownerId: userId,
            createdAt: new Date(),
            updatedAt: new Date(),
            localUpdatedAt: null,
            ...updatedDashboard
          } as Dashboard;
        }
        return null;
      }
      return { ...old, ...updatedDashboard };
    });
    return previousDashboard;
  };

  /**
   * Creates
   */
  private createDashboardMutation = atomWithMutation<
    AxiosResponse<Dashboard>,
    DashboardCreatePayload,
    AxiosError,
    MutationContext
  >(get => {
    const userId = get(userIdAtom);
    const coreClient = get(coreClientAtom);
    const queryClient = get(queryClientAtom);
    return {
      mutationFn: payload => {
        if (!coreClient) {
          throw new Error('Core client not initialized');
        }
        return axios.post('/dashboard', {
          id: payload.id,
          name: payload.name,
          sortWeight: payload.sortWeight,
          widgets: payload.widgets
        });
      },
      onMutate: async newDashboard => {
        if (!coreClient) {
          throw new Error('Core client not initialized');
        }
        return {
          previousDashboard: await this.optimisticallyUpdateDashboard(
            newDashboard,
            userId,
            coreClient,
            queryClient
          ),
          previousDashboards: await this.optimisticallyUpdateAllDashboards(
            [newDashboard],
            coreClient,
            queryClient
          )
        };
      },
      onError: (error, variables, context) => {
        if (!context || !coreClient) return;
        queryClient.setQueryData(
          coreClient.getQueryOptions.getAllDashboards().queryKey,
          context.previousDashboards
        );
        queryClient.setQueryData(
          coreClient.getQueryOptions.getDashboard(variables.id).queryKey,
          context.previousDashboard
        );
      }
    };
  });

  createDashboard = atom(null, async (get, set, dashboard: DashboardCreatePayload) => {
    const { data: newDashboard } = await get(this.createDashboardMutation).mutateAsync(dashboard);
    await get(routingAtom)?.navigate({ to: '/dash/' + newDashboard.id });
    set(toastAtom, { title: 'Dashboard created' });
    return newDashboard;
  });

  /**
   * Updates
   */

  private updateDashboardMutation = atomWithMutation<
    AxiosResponse<Dashboard>,
    DashboardUpdatePayload,
    AxiosError,
    MutationContext
  >(get => {
    const userId = get(userIdAtom);
    const coreClient = get(coreClientAtom);
    const queryClient = get(queryClientAtom);
    return {
      mutationFn: payload => {
        if (!coreClient) {
          throw new Error('Core client not initialized');
        }
        return axios.put(`/dashboard/${payload.id}`, {
          name: payload.name,
          sortWeight: payload.sortWeight,
          widgets: payload.widgets
        });
      },
      onMutate: async newDashboard => {
        if (!coreClient) {
          throw new Error('Core client not initialized');
        }
        return {
          previousDashboard: await this.optimisticallyUpdateDashboard(
            newDashboard,
            userId,
            coreClient,
            queryClient
          ),
          previousDashboards: await this.optimisticallyUpdateAllDashboards(
            [newDashboard],
            coreClient,
            queryClient
          )
        };
      },
      onError: (error, variables, context) => {
        if (!context || !coreClient) return;
        queryClient.setQueryData(
          coreClient.getQueryOptions.getAllDashboards().queryKey,
          context.previousDashboards
        );
        queryClient.setQueryData(
          coreClient.getQueryOptions.getDashboard(variables.id).queryKey,
          context.previousDashboard
        );
      }
    };
  });

  updateDashboard = atom(
    null,
    async (get, set, dashboard: DashboardUpdatePayload, notify = true) => {
      const { data: newDashboard } = await get(this.updateDashboardMutation).mutateAsync(dashboard);
      if (notify) set(toastAtom, { title: 'Dashboard updated' });
      return newDashboard;
    }
  );

  /**
   * Sort weights
   */
  private updateSortWeightsMutation = atomWithMutation<
    AxiosResponse<Dashboard>[],
    SortWeights,
    SortWeights,
    Dashboard[]
  >(get => {
    const isNewWeight = (updated: [string, string | null]): updated is [string, string] => {
      return Boolean(updated[1]);
    };
    const coreClient = get(coreClientAtom);
    const queryClient = get(queryClientAtom);
    return {
      mutationFn: weights => {
        return Promise.all(
          Object.entries(weights)
            .filter(isNewWeight)
            .map(async ([id, newWeight]) =>
              axios.patch(`/dashboard/${id}`, { sortWeight: newWeight })
            )
        );
      },
      onMutate: async newWeights => {
        if (!coreClient) {
          throw new Error('Core client not initialized');
        }
        return this.optimisticallyUpdateAllDashboards(
          Object.entries(newWeights).map(([id, sortWeight]) => ({ id, sortWeight })),
          coreClient,
          queryClient
        );
      },
      onError: (error, newWeights, previousDashboards) => {
        if (!previousDashboards || !coreClient) return;
        queryClient.setQueryData(
          coreClient.getQueryOptions.getAllDashboards().queryKey,
          previousDashboards
        );
      }
    };
  });

  updateSortWeights = atom(null, async (get, set, newWeights: SortWeights) => {
    await get(this.updateSortWeightsMutation).mutateAsync(newWeights);
  });

  updateLayout = atom(
    null,
    async (get, set, allLayouts: ReactGridLayout.Layouts, dashboardId: string) => {
      const current = get(this.dashboardFamily(dashboardId));
      if (!current) throw new Error('Dashboard not found');
      const newLayout = allLayouts.sm;
      const layoutsById = newLayout.reduce<Record<string, Layout>>((byId, widgetLayout) => {
        byId[widgetLayout.i] = widgetLayout;
        return byId;
      }, {});

      let changed = false;
      const newWidgets = current.widgets.filter(isValidWidget).map((widget, idx) => {
        const newWidgetLayout = layoutsById[widget.id];
        const newStoredWidgetLayout: Widget['layout'] = {
          w: newWidgetLayout.w,
          h: newWidgetLayout.h,
          x: newWidgetLayout.x,
          y: newWidgetLayout.y
        };
        if (!isEqual(widget.layout, newStoredWidgetLayout)) {
          changed = true;
          return {
            ...widget,
            layout: newStoredWidgetLayout
          };
        }
        return widget;
      });
      if (!changed) return;
      return set(
        this.updateDashboard,
        {
          ...current,
          widgets: newWidgets
        },
        false
      );
    }
  );

  /**
   * Deletes
   */
  private deleteDashboardMutation = atomWithMutation<unknown, string, AxiosError>(get => {
    const coreClient = get(coreClientAtom);
    if (!coreClient) throw new Error('Core client not initialized');
    const queryClient = get(queryClientAtom);
    return {
      mutationFn: id => axios.delete(`/dashboard/${id}`),
      async onSuccess(_, id) {
        const dashboardsQueryKey = coreClient.getQueryOptions.getAllDashboards().queryKey;
        // Optimistically update to the new value while we wait for the sync
        queryClient.setQueryData<Dashboard[] | undefined>(dashboardsQueryKey, old => {
          if (!old) return;
          return old.filter(d => d.id !== id);
        });
        const dashboardQueryKey = coreClient.getQueryOptions.getDashboard(id!).queryKey;
        queryClient.setQueryData<Dashboard | null>(dashboardQueryKey, null);
      }
    };
  });

  deleteDashboard = atom(null, async (get, set, id: string) => {
    const dashboard = get(this.dashboardFamily(id));
    if (!dashboard) throw new Error('Dashboard not found');
    const confirmed = await set(promptAtom, `Are you sure you want to delete ${dashboard?.name}?`);
    if (!confirmed) return;
    await get(this.deleteDashboardMutation).mutateAsync(id);
    // Navigate away if on the dashboard page
    if (get(this.dashboardPageMatch)?.params.id === id) {
      const router = get(routingAtom);
      router?.navigate({ to: '/' });
    }
    set(toastAtom, { title: 'Dashboard deleted' });
  });

  /**
   * General actions
   */
  canOpenStandaloneDashboard = atom(async get => {
    const coreClient = get(coreClientAtom);
    if (!coreClient) return false;
    const capabilities = await coreClient.getCapabilities();
    return capabilities.includes('open-standalone-dashboard');
  });

  openStandaloneDashboard = atom(null, async (get, set, id: string) => {
    const coreClient = get(coreClientAtom);
    if (!coreClient) throw new Error('Core client not initialized');
    await coreClient.openStandaloneDashboard(id);
  });

  list = atomWithQuery(get => {
    const dbClient = get(coreClientAtom);
    return dbClient!.getQueryOptions.getAllDashboards();
  });

  private dashboardRouteIds: RouteIds<RegisteredRouter['routeTree']>[] = [
    '/authed/main/dash/$id',
    '/authed/dash/$id/edit',
    '/authed/dash/$dashboardId/add',
    '/authed/dash/$dashboardId/$widgetId/edit',
    '/authed/dash/$id/full'
  ];
  private dashboardPageMatch = atom(
    get =>
      get(routeMatchesAtom)?.find(match => this.dashboardRouteIds.includes(match.routeId)) ?? null
  );

  dashboardFamily = atomFamily((id: string) =>
    atom(get => {
      const dashboardList = get(this.list);
      return dashboardList?.data?.find(d => d.id === id) ?? null;
    })
  );

  currentDashboardId = atom<string | null>(get => {
    const match = get(this.dashboardPageMatch);
    return match?.params?.dashboardId ?? match?.params.id ?? null;
  });

  currentDashboard = atom<Dashboard | null>(get => {
    const currentDashId = get(this.currentDashboardId);
    return currentDashId ? (get(this.dashboardFamily(currentDashId)) ?? null) : null;
  });

  editLayout = atom(null, (get, set, id: string | undefined = get(this.currentDashboard)?.id) => {
    if (!id) throw new Error('Dashboard id required');
    const router = get(routingAtom);
    if (!router) throw new Error('Router required');
    router.navigate({ to: '/dash/$id/edit', params: { id } });
  });

  goFullScreen = atom(null, (get, set, id: string | undefined = get(this.currentDashboard)?.id) => {
    if (!id) throw new Error('Dashboard id required');
    const router = get(routingAtom);
    if (!router) throw new Error('Router required');
    router.navigate({ to: '/dash/$id/full', params: { id } });
  });

  returnToDefaultDashboardView = atom(null, (get, set) => {
    const router = get(routingAtom);
    const currentId = get(this.currentDashboard)?.id;
    if (!currentId) throw new Error('Current dashboard id required');
    if (!router) throw new Error('Router required');
    // Main use case, but not always there while in standalone
    const isStandalone = get(isStandaloneAtom);
    if (isStandalone) return router.navigate({ to: '/dash/$id/full', params: { id: currentId } });
    router.navigate({ to: '/dash/$id', params: { id: currentId } });
  });

  private finishEditingLayoutAtom = atom(null, (get, set) => {
    return set(this.returnToDefaultDashboardView);
  });

  commands = atom<CommandGroup | null>(get => {
    const store = get(_storeAtom);
    if (!store) return null;
    const router = get(routingAtom);
    const dashboardList = get(this.list);
    const currentDashboard = get(this.currentDashboard);
    const routeMatch = get(this.dashboardPageMatch);
    let currentDashboardCommands: Command[] = [];
    const mainPageRoute = routeMatch?.routeId === '/authed/main/dash/$id';
    const editPageRoute = routeMatch?.routeId === '/authed/dash/$id/edit';
    const fullScreenRoute = routeMatch?.routeId === '/authed/dash/$id/full';
    if ((mainPageRoute || editPageRoute) && currentDashboard) {
      currentDashboardCommands = [
        mainPageRoute
          ? {
              label: 'Edit Layout',
              id: 'edit-layout',
              handler: () => store.set(this.editLayout)
            }
          : null,
        mainPageRoute
          ? {
              label: 'Go Fullscreen',
              id: 'go-fullscreen',
              handler: () => store.set(this.goFullScreen)
            }
          : null,
        editPageRoute
          ? {
              label: 'Finish Editing',
              id: 'finish-editing',
              handler: () => store.set(this.finishEditingLayoutAtom)
            }
          : null,
        {
          label: 'Edit Dashboard Name',
          id: 'edit-dashboard',
          handler: () =>
            store.set(updateDialogAtom, { type: 'UpdateDashboard', payload: currentDashboard })
        },
        {
          label: 'Delete Current Dashboard',
          id: 'delete-dashboard',
          handler: () => store.set(this.deleteDashboard, currentDashboard.id)
        }
      ].filter(isNotEmpty);
    } else if (fullScreenRoute && currentDashboard) {
      currentDashboardCommands = [
        {
          label: 'Exit Fullscreen',
          id: 'exit-fullscreen',
          handler: () => router?.navigate({ to: '/dash/$id', params: { id: currentDashboard.id } })
        }
      ];
    }
    return {
      label: 'Dashboard',
      id: 'dashboard',
      commands: [
        ...currentDashboardCommands,
        ...(dashboardList?.data?.map(dashboard => [
          {
            label: `Go to ${dashboard.name}`,
            id: `go-to-dashboard-${dashboard.id}`,
            handler: () => router?.navigate({ to: '/dash/$id', params: { id: dashboard.id } })
          },
          {
            label: `Delete ${dashboard.name}`,
            id: `delete-dashboard-${dashboard.id}`,
            handler: () => store.set(this.deleteDashboard, dashboard.id)
          }
        ]) ?? [])
      ]
        .flat()
        .filter(isNotEmpty)
    };
  });

  // TODO: Migrate undo/redo to Jotai
  // undoableDashboard = atomFamily((id: string) => {
  //   return atom(
  //     get => get(this.dashboardFamily(id)),
  //     (get, set, newDashboard: Dashboard) => {
  //       set(this.updateDashboard, newDashboard);
  //       return newDashboard;
  //     }
  //   );
  // });

  // dashboardUndo = atomFamily((id: string) => {
  //   return withUndo(this.undoableDashboard(id), 40);
  // });
}
