import { UserPreferences, userPreferenceSchema } from 'daydash-data-structures';
import { atom } from 'jotai';
import log from 'src/log';
import { z } from 'zod';
import { toastAtom } from '.';
import { authUserAtom } from './auth.atoms';

export const currentUserAtom = atom(get => {
  const authUser = get(authUserAtom);
  if (!authUser) return null;
  const preferences = userPreferenceSchema.parse(authUser.unsafeMetadata.preferences || {});
  const isAdmin = z.boolean().optional().parse(authUser.publicMetadata.isAdmin);
  return {
    id: authUser.id,
    emailAddresses: authUser.emailAddresses.map(email => email.emailAddress),
    firstName: authUser.firstName,
    lastName: authUser.lastName,
    createdAt: authUser.createdAt ? new Date(authUser.createdAt) : null,
    updatedAt: authUser.updatedAt ? new Date(authUser.updatedAt) : null,
    isAdmin: isAdmin,
    preferences: preferences
  };
});

export const userUpdatingAtom = atom(false);

export const updateUserAtom = atom(
  null,
  async (
    get,
    set,
    payload: { firstName: string | null; lastName: string | null; preferences: UserPreferences }
  ) => {
    set(userUpdatingAtom, true);
    try {
      const authUser = get(authUserAtom);
      if (!authUser) {
        throw new Error('No user found');
      }
      await authUser.update({
        firstName: payload.firstName ?? undefined,
        lastName: payload.lastName ?? undefined,
        unsafeMetadata: {
          preferences: payload.preferences
        }
      });
      set(toastAtom, { title: 'User updated' });
    } catch (err) {
      console.error(err);
      set(toastAtom, { title: 'Error updating user', description: (err as Error)?.message });
    }
    set(userUpdatingAtom, false);
  }
);

export const updateProfileImageAtom = atom(null, async (get, set, file: Blob | File | string) => {
  set(userUpdatingAtom, true);
  try {
    const authUser = get(authUserAtom);
    if (!authUser) {
      throw new Error('No user found');
    }
    await authUser.setProfileImage({ file });
    set(toastAtom, { title: 'Profile Image updated' });
  } catch (err) {
    log.error(err);
    set(toastAtom, { title: 'Error updating profile image', description: (err as Error)?.message });
  }
  set(userUpdatingAtom, false);
});

export const removeProfileImageAtom = atom(null, async (get, set) => {
  set(userUpdatingAtom, true);
  try {
    const authUser = get(authUserAtom);
    if (!authUser) {
      throw new Error('No user found');
    }
    await authUser.setProfileImage({ file: null });
    set(toastAtom, { title: 'Profile Image removed' });
  } catch (err) {
    log.error(err);
    set(toastAtom, { title: 'Error removing profile image', description: (err as Error)?.message });
  }
  set(userUpdatingAtom, false);
});

export const userProfileImageAtom = atom(get => {
  const authUser = get(authUserAtom);
  if (!authUser) {
    return null;
  }
  return authUser.imageUrl;
});
