import { isNotEmpty } from 'axil-utils';
import { Button, Dialog, cn, useResizeObserver } from 'axil-web-ui';
import { Widget } from 'daydash-data-structures';
import { useAtomValue, useSetAtom } from 'jotai';
import { PlusIcon, XIcon } from 'lucide-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Layout, Responsive as ResponsiveGridLayout } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import { useIdleTimer } from 'react-idle-timer';
import 'react-resizable/css/styles.css';
import dashboardEmptyStateImage from 'src/assets/dashboard-empty-state.svg';
import { dashboardAtoms, isSmallScreenSizeAtom, isStandaloneAtom, widgetAtoms } from 'src/atoms';
import { Dashboard } from 'src/types/entities';
import WidgetComponent from '../Widget/index';
import { getWidgetGridPosition } from '../Widget/positioning';
import Prompt from '../common/Prompt';
import EditBar from './EditBar';
import MoveToDashboard from './MoveToDashboard';
import TitleBar from './TitleBar';
import DashboardContext from './context';
import './editing.css';

interface DashboardComponentProps {
  dashboard: Dashboard;
  fullScreen?: boolean;
  editing?: boolean;
}

function useEscapeClose(open: boolean, onClose: () => void, enabled = true) {
  useEffect(() => {
    if (!open || !enabled) return;
    function handleKeydown(evt: KeyboardEvent) {
      if (evt.key === 'Escape') onClose();
    }
    document.addEventListener('keydown', handleKeydown);
    return () => document.removeEventListener('keydown', handleKeydown);
  }, [open, enabled]);
}

function useScrollToTopOnDashboardChange(
  current: Dashboard | null,
  container: React.RefObject<HTMLDivElement | null>
) {
  useEffect(() => {
    let frame: number | null = null;
    const attemptScrollIntoView = () => {
      if (container.current && container.current.scrollTop !== 0) {
        container.current?.scrollTo(0, 0); // Scroll to top when switching between dashboards
        frame = requestAnimationFrame(() => {
          attemptScrollIntoView();
        });
      }
    };
    attemptScrollIntoView();
    return () => {
      frame && cancelAnimationFrame(frame);
    };
  }, [current?.id]);
}

const GRID_COL_SIZE = 4;

const DEFAULT_GRID_COL_COUNT = 8;

const DashboardComponent = React.memo(function DashboardComponent(props: DashboardComponentProps) {
  const { dashboard: current, fullScreen = false, editing = false } = props;
  const isStandAlone = useAtomValue(isStandaloneAtom);
  const { id } = current;
  const [movingWidget, setMovingWidget] = useState<string | null>(null);
  const container = useRef<HTMLDivElement>(null);
  const containerRect = useResizeObserver(container, {
    type: 'debounce',
    wait: 50,
    options: { trailing: true }
  });
  const updateLayout = useSetAtom(dashboardAtoms.updateLayout);
  const moveWidget = useSetAtom(widgetAtoms.moveWidget);
  const addWidget = useSetAtom(widgetAtoms.startAddingWidget);
  const startEditingWidget = useSetAtom(widgetAtoms.startEditingWidget);
  const upsertWidget = useSetAtom(widgetAtoms.upsert);
  const returnToDefaultDashboardView = useSetAtom(dashboardAtoms.returnToDefaultDashboardView);
  const inspectWidget = useSetAtom(widgetAtoms.inspectWidget);
  const deleteWidget = useSetAtom(widgetAtoms.delete);
  const [layoutInitialized, setLayoutInitialized] = useState(false);
  const [fullScreenIdle, setFullScreenIdle] = useState(false);
  useIdleTimer({
    onPresenceChange(presence) {
      setFullScreenIdle(presence.type === 'idle');
    },
    timeout: 2500
  });

  const handleWidgetUpdate = useCallback(
    (newWidget: Widget) => upsertWidget(newWidget, current.id),
    [upsertWidget, current.id]
  );

  // useImperativeHandle(
  //   ref,
  //   () => ({
  //     scrollWidgetIntoView(widgetId: string) {
  //       const attemptScrollIntoView = () => {
  //         const widgetWrapper = container.current?.querySelector(`[data-widget-id="${widgetId}"]`);
  //         if (!widgetWrapper) return;
  //         widgetWrapper.scrollIntoView({ block: 'start' });
  //       };
  //       // Wait a little bit for the animation to go through
  //       setTimeout(() => {
  //         attemptScrollIntoView();
  //       }, 300);
  //     }
  //   }),
  //   [container]
  // );
  const handleLayoutChange = useCallback(
    async (_: Layout[], allLayouts: ReactGridLayout.Layouts) => {
      if (!current?.widgets) return;
      updateLayout(allLayouts, current.id);
    },
    [updateLayout, current.id]
  );

  useScrollToTopOnDashboardChange(current, container);

  const handleDeleteWidget = useCallback(
    (widgetId: string) => {
      deleteWidget(widgetId);
    },
    [deleteWidget]
  );
  const handleMoveWidget = useCallback((widgetId: string) => {
    setMovingWidget(widgetId);
  }, []);
  const widgetChildren = useMemo(() => {
    if (!current?.widgets) return null;
    return current?.widgets
      .map(widget => {
        if (!widget.id) {
          console.error('Invalid widget found!', widget);
          return null;
        }
        return (
          <div key={widget.id} data-widget-id={widget.id}>
            <WidgetComponent
              showDragHandle={editing}
              isEditing={editing}
              widget={widget}
              onDelete={handleDeleteWidget}
              onUpdate={handleWidgetUpdate}
              onInspect={inspectWidget}
              onEdit={startEditingWidget}
              onMove={handleMoveWidget}
            />
          </div>
        );
      })
      .filter(isNotEmpty);
  }, [current?.widgets, editing, handleDeleteWidget, upsertWidget]);

  const layoutsFromWidgets = useMemo<ReactGridLayout.Layouts | null>(() => {
    if (!current?.widgets) return null;
    const sorted = [...current.widgets]
      .filter(w => w.id && w.type) // Just in case
      .sort((a, b) => {
        const { x: ax, y: ay } = a.layout || { x: 0, y: 0 };
        const { x: bx, y: by } = b.layout || { x: 0, y: 0 };
        if (ay !== by) return ay - by;
        if (ax !== bx) return ax - bx;
        return 0;
      });
    let currentBottomY = 0;
    // Track the latest bottomY as well so we can use that as a default. Doing it this way for perf reasons
    const mediumLayout = sorted.map(widget => {
      const position = getWidgetGridPosition(widget, currentBottomY, GRID_COL_SIZE);
      const widgetBottomY = position.y + position.h;
      if (widgetBottomY > currentBottomY) currentBottomY = widgetBottomY;
      return position;
    });
    const smallLayout = sorted.map(widget => {
      const position = getWidgetGridPosition(widget, currentBottomY, 1, true);
      const widgetBottomY = position.y + position.h;
      if (widgetBottomY > currentBottomY) currentBottomY = widgetBottomY;
      return position;
    });
    return {
      sm: mediumLayout,
      xs: smallLayout
    };
  }, [current?.widgets]);
  // Collapse if a dashboard on fullscreen. Expanded widget escape takes precedence
  useEscapeClose(fullScreen, returnToDefaultDashboardView, !isStandAlone);

  // Grid config
  const isSmallScreen = useAtomValue(isSmallScreenSizeAtom);
  const gridGap = isSmallScreen ? 8 : 16;
  const containerPadding = gridGap * 2;
  const rowHeight = 160;
  const smallBreakPoint = 700; // TODO: Get from tailwind theme somehow
  const colCount =
    !containerRect || containerRect.width > smallBreakPoint ? DEFAULT_GRID_COL_COUNT : 1;
  const allowDashboardEdits = colCount === DEFAULT_GRID_COL_COUNT;
  useEffect(() => {
    if (editing && !allowDashboardEdits) {
      returnToDefaultDashboardView();
    }
  }, [allowDashboardEdits, editing]);
  return (
    <>
      <div className={cn('flex max-h-[auto] min-h-full max-w-full flex-col')}>
        {fullScreen && !isStandAlone ? (
          <div
            className={cn(
              'fixed bottom-0 left-0 z-10 transition-transform duration-300',
              fullScreenIdle ? 'translate-y-80' : 'translate-y-0'
            )}>
            <div className="m-2">
              <Button color="primary" square size="sm" onClick={returnToDefaultDashboardView}>
                <XIcon size="14px" color="white" />
              </Button>
            </div>
          </div>
        ) : null}
        {editing ? (
          <EditBar
            onAddWidget={() => addWidget(current.id)}
            onFinishEditing={returnToDefaultDashboardView}
            dashboard={current}
          />
        ) : null}
        {(!fullScreen && !editing) || isStandAlone ? (
          <TitleBar dashboard={current} allowEdits={allowDashboardEdits} />
        ) : (
          <h1 className="mx-auto my-4 text-center text-3xl font-bold">{current.name}</h1>
        )}
        <div
          className="flex-shrink flex-grow overflow-y-auto"
          style={{ scrollbarGutter: 'stable' }}>
          {current?.widgets && current.widgets.length === 0 ? (
            <div className="flex h-full w-full flex-col items-center justify-center gap-4">
              <img src={dashboardEmptyStateImage} alt="Dashboard Empty State" />
              <p className="text-center text-lg font-bold">This dashboard is empty</p>
              <Button size="lg" onClick={() => addWidget(current.id)}>
                <PlusIcon size="24px" />
                Create Widget
              </Button>
            </div>
          ) : (
            <DashboardContext.Provider
              value={{
                containerRect,
                gridGap,
                containerPadding,
                inspectingWidget: false,
                isEditing: editing,
                colCount,
                rowHeight
              }}>
              <div className="shrink-0 grow" ref={container}>
                {containerRect ? (
                  <ResponsiveGridLayout
                    isResizable={editing}
                    draggableHandle=".drag-handle"
                    width={containerRect!.width}
                    margin={[gridGap, gridGap]}
                    containerPadding={[containerPadding, containerPadding]}
                    breakpoints={{ sm: smallBreakPoint, xs: 0 }}
                    cols={{ sm: DEFAULT_GRID_COL_COUNT, xs: 1 }}
                    layouts={layoutsFromWidgets!}
                    useCSSTransforms
                    rowHeight={rowHeight}
                    className={cn('layout', !layoutInitialized ? 'layout-initializing' : null)}
                    onLayoutChange={handleLayoutChange}
                    onWidthChange={() => setLayoutInitialized(true)}>
                    {widgetChildren}
                  </ResponsiveGridLayout>
                ) : null}
              </div>
            </DashboardContext.Provider>
          )}
        </div>
      </div>
      <Dialog isOpen={Boolean(movingWidget)} onClose={() => setMovingWidget(null)}>
        {!movingWidget ? (
          <div className="h-96 w-96"></div>
        ) : (
          <MoveToDashboard
            dashboardFromId={id}
            widgetId={movingWidget}
            onSubmit={(dashboardFromId: string, dashboardToId: string, widgetId: string) =>
              moveWidget({ dashboardFromId, dashboardToId, widgetId })
            }
            onClose={() => setMovingWidget(null)}
          />
        )}
      </Dialog>
    </>
  );
});

export default DashboardComponent;
