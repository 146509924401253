import { Outlet } from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import React from 'react';
import PageLoadingIndicator from '../PageLoadingIndicator';

export default function UnauthenticatedPageLayout() {
  return (
    <>
      <PageLoadingIndicator />
      <div className="flex h-dvh w-full">
        <div className="bg-base-200 hidden shrink grow md:block"></div>
        <main className="w-full overflow-y-auto md:w-[480px] lg:w-[600px]">
          <Outlet />
        </main>
      </div>
      {/* <TanStackRouterDevtools /> */}
    </>
  );
}
